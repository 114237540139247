import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CustomSpinner from "components/display/CustomSpinner";
import CommonConstants from "constants/CommonConstants";
import { createApi } from "util/ApiCreator";
import { AuthController } from "api/auth/AuthController";

export default function MagicLoginHandler() {
  const navigate = useNavigate();
  const authAPI = useRef(createApi("auth") as AuthController);
  const [message, setMessage] = useState<string>("Validating uuid and email");
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const uuid = searchParams.get("uuid");

  useEffect(() => {
    handleMagicLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleMagicLogin() {
    try {
      await authAPI.current.loginWithMagicLink({
        email: email?.toLocaleLowerCase() ?? "N/A",
        uuid: uuid ?? "N/A",
      });
      navigate(CommonConstants.loggedInHomepage);
    } catch (e: any) {
      setMessage(e.response.data);
      navigate(CommonConstants.loginPathName);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomSpinner size={"large"} label={message} noPadding />
    </div>
  );
}
