import {
  DeleteDialogProps,
  EditDialogProps,
  CreateDialogProps,
} from "@recodin/fe-components";
import BankAccountEditForm from "pages/bankaccount/BankAccountEditForm";
import { BankAccountResponse } from "api/main/data-contracts";

export const editDialogProps = (
  handleCreateUpdate: (bankAccount: BankAccountResponse) => void,
  row?: BankAccountResponse,
): EditDialogProps => {
  return {
    headerText: row
      ? `"${row.creditorIban}" redagavimas`
      : "Naujos sąskaitos pridėjimas",
    contentTextFor: "sąskaitą",
    EditForm: (
      <BankAccountEditForm bankAccount={row} handleEdit={handleCreateUpdate} />
    ),
  };
};

export const createProps = (
  handleCreateUpdate: (bankAccount: BankAccountResponse) => void,
  row?: BankAccountResponse,
): CreateDialogProps => ({
  ...editDialogProps(handleCreateUpdate, row),
  createButtonLabel: "Nauja sąskaita",
});

export const deleteDialogProps = (
  row: BankAccountResponse,
): DeleteDialogProps => ({
  labels: {
    title: "Banko sąskaitos ištrynimas",
    question: `Ar tikrai norite ištrinti banko sąskaitą, kurios numeris "${row?.creditorIban}"?`,
    dialogBoldedNameToDelete: `Banko sąskaita, kurios numeris "${row?.creditorIban}"`,
    dialogText: " bus visam laikui ištrinta. Ją atkurti galimybių nebus!",
  },
});
