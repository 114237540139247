import AppTheme from "themes/AppTheme";
import DraftsIcon from "@mui/icons-material/Drafts";
import TranslationItem from "models/TranslationItem";

enum EmailTemplateType {
  MAGIC_LOGIN = "MAGIC_LOGIN",
}

const allEmailTemplateTypes: TranslationItem[] = [
  {
    value: EmailTemplateType.MAGIC_LOGIN,
    label: "Prisijungimo kodas",
    icon: <DraftsIcon sx={{ color: AppTheme.palette.primary.main }} />,
  },
];

function findEmailTemplateType(value?: string) {
  return allEmailTemplateTypes?.find(
    (status) => value && status?.value === value,
  );
}

export { allEmailTemplateTypes, findEmailTemplateType };
