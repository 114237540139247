import { Formik } from "formik";
import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import React, { useContext } from "react";
import * as yup from "yup";
import FileUploadButton from "components/button/FileUploadButton";

export interface FileUploadFormProps {
  handleSave: (selectedFile: File) => void;
}

export default function FileUploadForm({ handleSave }: FileUploadFormProps) {
  const { formikFormRef } = useContext(FormContext);

  function handleSubmit(formValues: any) {
    const values = formValues as FileUploadFormValues;
    if (values.selectedFile) {
      handleSave(values.selectedFile);
    }
  }

  return (
    <div>
      <Formik
        initialValues={getInitialFormValues()}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
        innerRef={formikFormRef}
      >
        <>
          <FormContextRefreshConduit />
          <FileUploadButton name={"selectedFile"} title={"Failas*"} />
        </>
      </Formik>
    </div>
  );
}

function getValidationSchema() {
  return yup.object({
    selectedFile: yup.mixed().required("Būtina įkelti failą."),
  });
}
function getInitialFormValues() {
  return {
    selectedFile: null,
  };
}
type FileUploadFormValues = ReturnType<typeof getInitialFormValues>;
