/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AccountResponse, RegistrationRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AccountController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Finalise account registration
   *
   * @tags account-controller
   * @name FinaliseRegistration
   * @request PUT:/api/admin/v1/account/finalise-registration
   */
  finaliseRegistration = (data: RegistrationRequest, params: RequestParams = {}) =>
    this.request<AccountResponse, any>({
      path: `/api/admin/v1/account/finalise-registration`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Fetches all accounts
   *
   * @tags account-controller
   * @name FindAll
   * @request GET:/api/admin/v1/account
   */
  findAll = (params: RequestParams = {}) =>
    this.request<AccountResponse[], any>({
      path: `/api/admin/v1/account`,
      method: "GET",
      ...params,
    });
  /**
   * @description Get my information
   *
   * @tags account-controller
   * @name GetMe
   * @request GET:/api/admin/v1/account/me
   */
  getMe = (params: RequestParams = {}) =>
    this.request<AccountResponse, any>({
      path: `/api/admin/v1/account/me`,
      method: "GET",
      ...params,
    });
}
