enum RoleNames {
  ADMIN = "ADMIN",
  USER = "USER",
}

function translateRoleByName(roleName?: string): string {
  switch (roleName) {
    case RoleNames.ADMIN:
      return "Administratorius";
    case RoleNames.USER:
      return "Vartotojas";
    default:
      return "N/A";
  }
}

export { RoleNames, translateRoleByName };
