import { useRef, useState } from "react";
import {
  ProcessExecutionResponse,
  StepExecutionResponse,
} from "api/main/data-contracts";
import SignExecution from "./SignExecution";
import DataInputExecution from "./DataInputExecution";
import IdentificationExecution from "./IdentificationExecution";
import PaymentExecution from "./PaymentExecution";
import { createApi } from "util/ApiCreator";
import { ProcessExecutionPublicController } from "api/main/ProcessExecutionPublicController";
import Box from "@mui/material/Box/Box";
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { InfoComponent } from "../InfoComponent";
import { RoleNames } from "models/auth/RoleNames";
import { hasRole } from "util/UtilityFunctions";
import { useAppSelector } from "redux/hooks";

interface Props {
  step: StepExecutionResponse;
  process?: ProcessExecutionResponse;
  refresh: () => Promise<void>;
  next: () => void;
  previous: () => void;
  canGoNext: boolean;
  canGoBack: boolean;
}

const StepExecution = ({ step, process, refresh, next, previous, canGoBack, canGoNext }: Props) => {
  const [backdropOpen, setBackdropOpen] = useState<boolean>(false);
  const executionApi = useRef(createApi("processexecution") as ProcessExecutionPublicController as ProcessExecutionPublicController);

  const readOnly = step.status === "FINISHED" || !process?.myTurn;
  const confirmationInfo = JSON.parse(process?.stepExecutions?.find(execution => execution.step?.type === "DATA_INPUT" && execution.step.executors?.includes("ADMIN"))?.stepInfo || "{}");

  const me = useAppSelector((s) => s.me.info);

  const execute = async (obj: object) => {
    if (!process?.process?.id || !step?.id || !obj) return;

    setBackdropOpen(true);
    await executionApi.current.execute(
      step?.id,
      JSON.stringify(obj),
    );
    await refresh();
    setBackdropOpen(false);
    next();
  };

  function couldBeShown(currentStep: StepExecutionResponse) {
    // jeigu adminas ir mano žingsnis
    if (currentStep?.step?.executors?.includes("ADMIN") && hasRole(RoleNames.ADMIN, me?.roles)) {
      return true;
    }

    // jeigu useris ir mano žingsnis
    if (currentStep?.step?.executors?.includes("USER") && (hasRole(RoleNames.USER, me?.roles))) {
      return true;
    }

    if (currentStep?.step?.executors?.includes("USER") && (hasRole(RoleNames.ADMIN, me?.roles))) {
      return true;
    }

    return false;
  }

  function canExecute(currentStep: StepExecutionResponse) {

    if(currentStep?.step?.executors?.includes("ADMIN") && hasRole(RoleNames.ADMIN, me?.roles)) {
      return true;
    }

    if(currentStep?.step?.executors?.includes("USER") && hasRole(RoleNames.USER, me?.roles)) {
      return true;
    }

    return false;
  }

  return <>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdropOpen}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    {process && couldBeShown(step) && <>
      {step.type === "SIGN" && (
        <SignExecution
          process={process}
          step={step}
          refresh={refresh}
          next={next}
          previous={previous}
          execute={execute}
          canGoBack={canGoBack}
          canGoNext={canGoNext}
          canExecute={canExecute(step)}
        />
      )}
      {step.type === "DATA_INPUT" && (
        <DataInputExecution
          process={process}
          step={step}
          next={next}
          previous={previous}
          execute={execute}
          readOnly={readOnly}
          canGoBack={canGoBack}
          canGoNext={canGoNext}
          canExecute={canExecute(step)}
        />
      )}
      {step.type === "CONFIRMATION" && <>
        <Typography variant="h4">Vartotojų aljanso pateikti siūlymai, pastabos: <b>{confirmationInfo.group0field0}</b></Typography>
        <Typography variant="h4">Vartotojų aljanso pateikta pasiūlymo vertė (eurais) <b>{confirmationInfo.group0field1}</b></Typography>
        {step.status === "ACTIVE" && <InfoComponent
          waiting={true}
          IconComponent={AccessTimeIcon}
          title="Prašome patvirtinti reikalavimo dydį"
          text="Tai padaryti galite paspaudę mygtuką apačioje dešinėje"
        />}

        {step.status === "NOT_ACTIVE" && <InfoComponent
          IconComponent={AccessTimeIcon}
          waiting={true}
          title="Prašome atlikti veiksmus esančius prieš tai"
          text="Atlikę praeitus žingsnius, galėsite patvirtinti reikalavimo dydį ir tęsti toliau"
        />}

        {step.status === "FINISHED" && <InfoComponent
          waiting={true}
          IconComponent={DoneAllIcon}
          title="Sėkmingai patvirtinote reikalavimo dydį"
          text=""
        />}

        <Box style={{ display: "flex", marginTop: "auto", gap: "1rem" }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            style={{ padding: "0.5rem 1rem" }}
            onClick={() => previous()}
          >
            Ankstesnis
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size="large"
            style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
            onClick={() => next()}
          >
            Kitas
          </Button>
          {step.status === "ACTIVE" && canExecute(step)  && <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => execute({})}
            
            style={{ padding: "0.5rem 1rem" }}
          >
            Pasiūlymą tvirtinu
          </Button>}
        </Box>
      </>}

      {step.type === "DATA_OUTPUT" && <>not implemented</>}
      {step.type === "NOTIFICATION" && <>not implemented</>}
      {step.type === "IDENTIFICATION" && (
        <IdentificationExecution
          process={process}
          step={step}
          refresh={refresh}
          next={next}
          previous={previous}
          execute={execute}
          canGoBack={canGoBack}
          canGoNext={canGoNext}
          canExecute={canExecute(step)}
        />
      )}
      {step.type === "PAYMENT" && (
        <PaymentExecution
          process={process}
          step={step}
          refresh={refresh}
          next={next}
          previous={previous}
          execute={execute}
          canGoBack={canGoBack}
          canGoNext={canGoNext}
        />
      )}
    </>}
    {!couldBeShown(step) && <>
      <InfoComponent
        title={"Jūs neturite prieigos"}
        text={'Šis žingsnis yra atliekamas ne Jūsų, prašome palaukti, kol kita pusė įvykdys šį žingsnį'}
      />
      <Box style={{ display: "flex", marginTop: "auto", gap: "1rem" }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{ padding: "0.5rem 1rem" }}
          onClick={() => previous()}
          disabled={!canGoBack}
        >
          Ankstesnis
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
          onClick={() => next()}
          disabled={!canGoNext}
        >
          Kitas
        </Button>
      </Box>
    </>
    }
  </>

};

export default StepExecution;
