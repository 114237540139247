import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import { BankAccountResponse } from "api/main/data-contracts";
import PaTextField from "components/field/PaTextField";
import PaSelect from "components/field/PaSelect";
import PaSwitch from "components/field/PaSwitch";
import { IBAN } from "ibankit";
import CustomSpinner from "components/display/CustomSpinner";
import { createApi } from "util/ApiCreator";
import { BankAccountController } from "api/main/BankAccountController";

interface Props {
  bankAccount?: BankAccountResponse;
  handleEdit: (bankAccount: BankAccountResponse) => void;
}

export default function BankAccountEditForm(props: Props) {
  const { bankAccount = getDefaultValues(), handleEdit } = props;

  const api = useRef(createApi("bankaccount") as BankAccountController)
  const { formikFormRef } = useContext(FormContext);
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCurrencies = async () => {
    setLoading(true);
    const fetchedCurrencies = await api.current.findAllCurrencies();
    setCurrencies(fetchedCurrencies);
    setLoading(false);
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const handleFormikSubmit = (values: any) => {
    handleEdit(values as BankAccountResponse);
  };

  if (loading) {
    return <CustomSpinner label={"Prašome palaukti. Kraunama..."} />;
  }
  return (
    <Formik
      initialValues={bankAccount}
      validationSchema={validationSchema(currencies)}
      onSubmit={handleFormikSubmit}
      innerRef={formikFormRef} // used for submit outside Formik component
    >
      {(formik: FormikProps<any>) => (
        <>
          {/* Updates formContext, which is used in outside dialog */}
          <FormContextRefreshConduit />

          <PaTextField
            label={"Sąskaitos numeris"}
            name={"creditorIban"}
            required
          />
          <PaTextField
            label={"Gavėjo pavadinimas"}
            name={"creditorName"}
            required
          />
          <PaSelect
            name={`currency`}
            label={"Valiuta"}
            tooltip={"Pasirinkite valiutą."}
            options={
              currencies?.map((currency) => ({
                value: currency,
                label: currency,
              })) ?? []
            }
          />
          <PaSwitch name={"approved"} label={"Patvirtinti sąskaitą?"} />
        </>
      )}
    </Formik>
  );
}

const getDefaultValues = () => ({
  id: undefined,
  creditorIban: "",
  creditorName: "",
  currency: "",
  approved: false,
});

const validationSchema = (currencies: string[]) =>
  yup.object({
    creditorIban: yup
      .string()
      .required("Privaloma nurodyti sąskaitos numerį.")
      .test(
        "isIBANValid",
        "IBAN turi būti egzistuojantis",
        (value?: string) => !!value && IBAN.isValid(value),
      ),
    creditorName: yup.string().required("Privaloma nurodyti savininką"),
    currency: yup.string().required("Privaloma pasirinkti valiutą"),
  });
