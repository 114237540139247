import { useEffect, useRef, useState } from "react";
import { CustomTable } from "@recodin/fe-components";
import {
  BankAccountTableHeadRow,
  BankAccountTableRow,
} from "./BankAccountTableRow";
import { createProps } from "pages/bankaccount/BankAccountDialogProps";
import {
  BankAccountCreateRequest,
  BankAccountResponse,
  BankAccountUpdateRequest,
} from "api/main/data-contracts";
import { createApi } from "util/ApiCreator";
import { BankAccountController } from "api/main/BankAccountController";

export default function BankAccountPage() {
  const api = useRef(createApi("bankaccount") as BankAccountController);
  const [bankAccounts, setBankAccounts] = useState<BankAccountResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    handleDataFetch();
  }, []);

  async function handleDataFetch() {
    setLoading(true);
    setBankAccounts(await api.current.findAll());
    setLoading(false);
  }
  async function handleCreateUpdate(data: BankAccountResponse) {
    if (data?.id) {
      await api.current.update(data as BankAccountUpdateRequest);
    } else {
      await api.current.create(data as BankAccountCreateRequest);
    }
    await handleDataFetch();
  }
  async function handleDelete(data: BankAccountResponse) {
    if (data?.id) {
      await api.current.delete(data?.id);
      await handleDataFetch();
    }
  }
  return (
    <CustomTable
      data={bankAccounts}
      isLoading={loading}
      headerRow={BankAccountTableHeadRow}
      contentRow={(props: { row: BankAccountResponse }) => (
        <BankAccountTableRow
          row={props.row}
          handleDelete={handleDelete}
          handleCreateUpdate={handleCreateUpdate}
        />
      )}
      createProps={createProps(handleCreateUpdate)}
      disableFiltering
    />
  );
}
