import { FastField, Field } from "formik";
import { InputLabel } from "@mui/material";

interface Props {
  name: string;
  text: string;
  label?: string;
  fast?: boolean;
}

const PaCheckBox = ({ name, text, fast = false, label }: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <label style={{ marginLeft: "-5px" }}>
        {fast && <FastField type={"checkbox"} name={name} />}
        {!fast && <Field type={"checkbox"} name={name} />}
        {text}
      </label>
    </div>
  );
};

export default PaCheckBox;
