import { useRef } from "react";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { Box, Button, Typography } from "@mui/material";
import getValidationSchema from "./DataInputValidation";
import PaSelect from "components/field/PaSelect";
import PaTextField from "components/field/PaTextField";
import PaDateTimePicker from "components/field/PaDateTimePicker";
import FileUploadButton from "components/button/FileUploadButton";
import { findDateValue, findValue } from "util/UtilityFunctions";
import { InputContent, ProcessExecutionResponse, StepExecutionResponse } from "api/main/data-contracts";
import { createApi } from "util/ApiCreator";
import { FilePublicController } from "api/main/FilePublicController";
import { InfoComponent } from "../InfoComponent";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FormContextRefreshConduit } from "@recodin/fe-components";

interface Props {
  step: StepExecutionResponse;
  process: ProcessExecutionResponse;
  execute: (obj: object) => Promise<void>;
  next: () => void;
  previous: () => void;
  readOnly: boolean;
  canGoNext: boolean;
  canGoBack: boolean;
  canExecute: boolean;
  
}

const MAX_BUNDLE_FILE_SIZE = 1024 * 1024 * 1024;
const MAX_BUNDLE_FILE_SIZE_MB = 1024;
const MAX_FILE_SIZE = 50 * 1024 * 1024;
const MAX_FILE_SIZE_MB = 50;

export default function DataInputExecution(props: Props) {
  const { step, execute, next, readOnly, previous, canExecute } = props;

  const fileApi = useRef(createApi("filestoragepublic") as FilePublicController);

  const inputGroups: InputContent[][] | undefined = step.step?.stepInformation?.inputContent;  // TODO: sutvarkyti

  function getInitialValueForInput(inputName: string, info?: StepExecutionResponse) {
    switch (inputName) {
      case "firstName":
        return info?.executionInfo?.identificationInfo?.firstName
      case "lastName":
        return info?.executionInfo?.identificationInfo?.lastName
      case "personCode":
        return info?.executionInfo?.identificationInfo?.personCode
      default:
        return "";
    }
  }

  function getInitialValues(inputs: any[], stepInfo?: string) {
    let identificationInfo = props?.process?.stepExecutions?.find(ex => ex.type === "IDENTIFICATION");
    if (stepInfo) {
      return JSON.parse(stepInfo);
    }

    let ret = inputs.map(group => group.reduce(
      (schema: any, input: any) => ({
        ...schema,
        [input.name]: getInitialValueForInput(input.i18nkey, identificationInfo),
      }),
      {},
    ));
    let retArray: any = {};
    retArray = Object.assign(ret[0], ret[1]);
    // TODO:!!!!!!!!

    return retArray;
  }

  function onlySelect(input: any) {
    return input?.validations?.find(
      (validation: any) => validation?.type === "SELECT",
    )?.value;
  }

  async function uploadFiles(files: File[], type: string): Promise<any[]> {
    const ret: any[] = [];
    for (const file of files) {
      const fileResponse = await fileApi.current.save(
        { uploadedFile: file },
      );
      ret.push(
        {
          id: fileResponse?.id ?? 0,
          name: file?.name,
          type,
        });
    }
    return ret;
  }

  async function handleFileUpload(
    files: File[],
    input: any,
    formik: FormikProps<any>,
    type: string,
  ) {

    const total = files
      .map((file) => file.size)
      .reduce((total, current) => total + current);

    const invalidBundleSize = total >= MAX_BUNDLE_FILE_SIZE;
    const invalidFileSize = files.some((file) => file.size >= MAX_FILE_SIZE);
    if (invalidBundleSize) {
      formik.setFieldError(
        input.name,
        `Invalid file bundle size. Max size: ${MAX_BUNDLE_FILE_SIZE_MB}MB`,
      );
    }
    if (invalidFileSize) {
      formik.setFieldError(
        input.name,
        `Invalid file size. Max size: ${MAX_FILE_SIZE_MB}MB`,
      );
    }


    let fileObj = await uploadFiles(files, type);
    let ret = [...formik.values[input.name], ...fileObj]
    formik.setFieldValue(input.name, ret, true);
  }

  if (step.type !== "DATA_INPUT") {
    return null;
  }

  if (step.status === "NOT_ACTIVE") return <>
    <InfoComponent
      IconComponent={AccessTimeIcon}
      waiting={true}
      title={"Prašome atlikti veiksmus esančius prieš tai"}
      text={"Dokumentas bus sugeneruotas, kai atliksite visus prašymo veiksmus"} />
  </>

  return <Formik
    initialValues={
      inputGroups &&
      getInitialValues(
        [].concat.apply(inputGroups),
        step.stepInfo,
      )}
    validationSchema={getValidationSchema(inputGroups)}
    validateOnChange={false}
    onSubmit={async (values: FormikValues) => {
      await execute(values);
      next();
    }}
    validateOnMount
  >
    {(formik: FormikProps<any>) => {
      return <>
        <FormContextRefreshConduit />
        <Form>

          {inputGroups && inputGroups.map((inputs: any, i: number) => (
            <Box
              key={i}
              style={{
                backgroundColor: "#f9fbfb",
                padding: "1.2rem 1.5rem",
                borderRadius: ".5rem",
                marginBottom: "2rem",
              }}
            >
              <div key={i}>
                <Box style={{ marginBottom: "1rem" }}>
                  {step.name?.includes("Informacijos") && <>
                    {i === 0 && (
                      <Typography variant={"h3"}>
                        Įveskite savo asmeninę informaciją
                      </Typography>
                    )}
                    {i === 1 && (
                      <Typography variant={"h3"}>
                        Įveskite informaciją apie vairavimo mokyklą
                      </Typography>
                    )}
                  </>}

                  {!step.name?.includes("Informacijos") && <>
                    {i === 0 && (
                      <Typography variant={"h3"}>
                        Įveskite informaciją
                      </Typography>
                    )}
                  </>}

                </Box>

                {inputs.map((input: any, i: number) => (
                  <div key={i} style={{}}>
                    {onlySelect(input) && input.type !== "FILE" && <>
                      <PaSelect
                        name={input.name}
                        label={input.label}
                        disabled={readOnly}
                        options={
                          input.selectOptions?.map((inputType: any) => ({
                            value: inputType,
                            label: inputType,
                          })) ?? []
                        }
                      />
                    </>}
                    {input.type === "TEXT" && !onlySelect(input) && (
                      <PaTextField
                        name={input.name}
                        label={input.label}
                        disabled={['Vardas', 'Pavardė', 'Asmens kodas'].includes(input.label) || readOnly}
                      />
                    )}
                    {input.type === "NUMBER" && !onlySelect(input) && (
                      <PaTextField
                        name={input.name}
                        label={input.label}
                        disabled={readOnly}
                      />
                    )}
                    {input.type === "DATE" && !onlySelect(input) && (
                      <PaDateTimePicker
                        name={input.name}
                        label={input.label}
                        helperText={input.helperText}
                        minDateTime={findDateValue(input, "MIN_DATE")}
                        maxDateTime={findDateValue(input, "MAX_DATE")}
                        disabled={readOnly}
                      />
                    )}
                    {input.type === "FILE" && !onlySelect(input) && <>
                      <FileUploadButton
                        name={input.name}
                        title={input.label}
                        disabled={readOnly}
                        multiple={JSON.parse(findValue(input, "MULTIPLE"))}
                        onUpload={async (files: File[], type) =>
                          await handleFileUpload(files, input, formik, type)
                        }
                      />
                    </>}
                  </div>
                ))}
              </div>
            </Box>
          ))}
        </Form>
        <Box style={{ display: "flex", marginTop: "auto", gap: "1rem" }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            style={{ padding: "0.5rem 1rem" }}
            disabled={!props.canGoBack}
            onClick={() => previous()}
          >
            Ankstesnis
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size="large"
            style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
            onClick={() => next()}
            disabled={!props.canGoNext}
          >
            Kitas
          </Button>
          {step.status === "ACTIVE" && canExecute && <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
            style={{ padding: "0.5rem 1rem" }}
          >

            Tvirtinu, kad duomenys teisingi
          </Button>}
        </Box>
      </>
    }}
  </Formik>
}
