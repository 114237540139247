import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
interface Props {
  text: string;
  success: boolean;
}

export default function LabelComponent({ text, success }: Props) {
  return (
    <Box
      style={{
        background: success ? "rgba(76,175,80,.1)" : "rgb(171, 189, 210)",
        border: success ? "1px solid #4caf50" : "1px solid #5a6891",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        padding: "0.75rem 1rem",
        minWidth: "120px",
        alignSelf: "center",
        color: success ? "" : "white",
        gap: "0.75rem",
        fontWeight: "600",
        
      }}
    >
      <Box>
        {success && <CheckCircleIcon style={{ color: "green" }} />}
        {!success && <HourglassEmptyIcon />}
      </Box>
      <Box>{text}</Box>
    </Box>
  );
}
