import { Autocomplete, TextField } from "@mui/material";

const PaAutocompleteInput = (props: any) => {
  return (
    <Autocomplete
      multiple
      options={props.options ?? []}
      value={props.value}
      freeSolo
      autoSelect
      onChange={(_, value) => props.form.setFieldValue(props.field.name, value)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            id={props.field.name}
            name={props.field.name}
            label={props.label}
            placeholder={props.label}
          />
        );
      }}
    />
  );
};

export default PaAutocompleteInput;
