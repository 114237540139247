import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ProcessExecutionResponse,
  StepExecutionResponse,
} from "api/main/data-contracts";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import StepExecution from "pages/processexecution/execution/StepExecution";
import { useAppSelector } from "redux/hooks";
import { hasRole } from "util/UtilityFunctions";
import { RoleNames } from "models/auth/RoleNames";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Publish";
import GenerateDocumentIcon from "@mui/icons-material/Description";
import ConfirmationIcon from "@mui/icons-material/CheckCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import NotificationIcon from "@mui/icons-material/Notifications";
import SignIcon from "@mui/icons-material/Create";
import IdentificationIcon from "@mui/icons-material/Badge";
import LabelComponent from "components/LabelComponent";
import { createApi } from "util/ApiCreator";
import { InfoComponent } from "./InfoComponent";
import { ProcessExecutionPublicController } from "api/main/ProcessExecutionPublicController";
import { ProcessExecutionAdminController } from "api/main/ProcessExecutionAdminController";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
const ProcessExecutionPage = () => {
  const [backdropOpen, setBackdropOpen] = useState<boolean>(false);

  const { id, stepIndex } = useParams();
  const [currentStepId, setCurrentStepId] = useState<number>(
    stepIndex ? +stepIndex : 0,
  );
  const common = useAppSelector((state) => state.common);

  const me = useAppSelector((s) => s.me.info);
  const navigate = useNavigate();
  const location = useLocation();
  const api = useRef(createApi("processexecution") as ProcessExecutionPublicController);
  const adminApi = useRef(createApi("processexecutionadmin") as ProcessExecutionAdminController);

  const [processExecution, setProcess] = useState<ProcessExecutionResponse>();
  const [currentStep, setCurrentStep] = useState<StepExecutionResponse>();

  const [adminTurnOffer, setAdminTurnOffer] = useState<boolean>(false);
  const [adminTurnSign, setAdminTurnSign] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const [canGoBack, setCanGoBack] = useState<boolean>(false);
  const [canGoNext, setCanGoNext] = useState<boolean>(false);
  const [showDialogOnce, setShowDialogOnce] = useState<boolean>(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex, location.pathname]);

  const getData = async () => {
    setBackdropOpen(true);

    if (id && stepIndex) {
      let res;
      if (hasRole(RoleNames.ADMIN, me?.roles)) {
        res = await adminApi.current.findById(+id);
      } else {
        res = await api.current.findById(+id);
      }

      setProcess(res);

      setCanGoBack(+stepIndex !== 0);

      if (res?.stepExecutions && res.stepExecutions.length >= +stepIndex) {
        setCurrentStep(res.stepExecutions[+stepIndex]);
        setCanGoNext(+stepIndex + 1 < res?.stepExecutions?.length);
      }
      setBackdropOpen(false);

      let active = res?.stepExecutions?.find(step => step.status === 'ACTIVE');
      if (!active)
        return;

      let isAdminStep = active?.step?.executors?.includes("ADMIN");
      if (!isAdminStep)
        return;

      if (active.step?.type === "DATA_INPUT") {
        setAdminTurnOffer(true);
      }

      if (active.step?.type === "SIGN") {
        setAdminTurnSign(true);
      }
    }
  };

  useEffect(() => {
    if (showDialogOnce || hasRole(RoleNames.ADMIN, me?.roles)) {
      return
    }
    setOpen(adminTurnOffer || adminTurnSign || processExecution?.status === "FINISHED");
  }, [adminTurnOffer, adminTurnSign, processExecution])

  const next = (goToId?: number) => {
    let nextStepId = Number.isInteger(goToId)
      ? Number(goToId)
      : currentStepId + 1;

    if (
      processExecution?.stepExecutions &&
      processExecution?.stepExecutions?.length >= nextStepId
    ) {
      navigate(`/process/execution/${id}/${nextStepId}`);
      setCurrentStepId(nextStepId);
    } else {
      getData();
    }
  };

  const previous = () => {
    let nextStepId = currentStepId - 1;

    if (
      processExecution?.stepExecutions &&
      processExecution?.stepExecutions?.length >= nextStepId &&
      nextStepId >= 0
    ) {
      navigate(`/process/execution/${id}/${nextStepId}`);
      setCurrentStepId(nextStepId);
    } else {
      getData();
    }
  };

  function getCurrentTitle(currentStep: StepExecutionResponse) {
    if (currentStep.step?.description) {
      return currentStep.step.description;
    }

    if (currentStep.type === "SIGN") {
      if (currentStep.step?.stepInformation?.signContent?.signType === "QUALIFIED_SIGNATURE") {
        return "Atsižvelgiant į jūsų užpildytą informaciją sistema sugeneruoja pareiškimą būti grupės nariu ir atstovavimo susitarimą. Jūs turite susipažinti ir pasirašyti šiuos dokumentus.";
      }
      return "Norėdami pasirašyti Pareiškimą dėl sutikimo būti grupės nariu bei Atstovavimo susitarimą ir pareikšti teismui grupės ieškinį, Jūs privalote susipažinti su Atstovavimo paslaugų teikimo taisyklėmis bei patvirtinti, jog su šiuo dokumentu sutinkate.";
    }

    if (currentStep.type === "DATA_INPUT") {
      if (currentStep.name === "Ačiū. Laukite atsakymo.")
        return "Ačiū už pateiktus dokumentus. Dabar sulaukite atsakymo. Vartotojų aljansas, peržiūrėjęs visus jūsų pateiktus duomenis, apskaičiuos jūsų galimai patirtą žalą. Jeigu jūsų pateiktos informacijos neužteks pilnai įvertinti Jūsų situacijos, Vartotojų aljanso atstovas susisieks su jumis ir paprašys patikslinti ar pateikti papildomą informaciją. "
      return "Šiame lange jūs turite užpildyti informaciją apie savo lankytą vairavimo mokyklą, kada ir kokius kursus lankėte bei įkelti mokėjimo ar kitą dokumentą, kuris įrodo sumokėtą sumą už vairavimo kursų lankymą. "
    }

    if (currentStep.type === "PAYMENT") {
      if (currentStep.status === "FINISHED") return "Jūs jau sumokėjote";

      if (currentStep.status === "NOT_ACTIVE")
        return "Prašome atlikti mokėjimą";

      if (currentStep.status === "ACTIVE") return "Prašome atlikti mokėjimą";
    }

    if (currentStep.type === "IDENTIFICATION") {
      return "Vartotojų aljansas prašo patvirtinti jūsų tapatybę siekiant identifikuoti asmenį, su kuriuo yra bendraujama, bei tinkamai parengti dokumentus."
    }

    if (currentStep.type === "CONFIRMATION")
      return "Jūsų galimai patirta žala sudaro čia nurodytą sumą, kuri buvo apskaičiuota Vartotojų aljanso, vadovaujantis Konkurencijos tarybos sprendime nurodytais ir pripažintais faktais. "
        + "Atkreipiame Jūsų dėmesį, kad Jūsų patvirtinta reikalavimo dydžio suma gali kisti, priklausomai nuo naujai paaiškėjusių aplinkybių ir naujų dokumentų gavimo.";
  }

  function getIcon(type: any) {
    switch (type) {
      case "DATA_INPUT":
        return <InputIcon />;
      case "DATA_OUTPUT":
        return <OutputIcon />;
      case "GENERATE_DOCUMENT":
        return <GenerateDocumentIcon />;
      case "CONFIRMATION":
        return <ConfirmationIcon />;
      case "PAYMENT":
        return <PaymentIcon />;
      case "NOTIFICATION":
        return <NotificationIcon />;
      case "SIGN":
        return <SignIcon />;
      case "IDENTIFICATION":
        return <IdentificationIcon />;
      default:
        throw new Error(`Unsupported type: ${type}`);
    }
  }

  function getByLabelText(currentStep?: StepExecutionResponse): string {
    if (currentStep?.type === "DATA_INPUT") {
      return currentStep?.status === "FINISHED" ? "Pateikta" : "Laukiama";
    }
    if (currentStep?.type === "SIGN") {
      if (currentStep.step?.stepInformation?.signContent?.signType === "QUALIFIED_SIGNATURE") {
        return currentStep?.status === "FINISHED" ? "Pasirašyta" : "Laukiama";
      }
      return currentStep?.status === "FINISHED" ? "Susipažinta" : "Laukiama";

    }
    if (currentStep?.type === "PAYMENT") {
      return currentStep?.status === "FINISHED" ? "Apmokėta" : "Laukiama";
    }

    if (currentStep?.type === "IDENTIFICATION") {
      return currentStep?.status === "FINISHED" ? "Identifikacija atlikta" : "Laukiama";
    }

    if (currentStep?.type === "CONFIRMATION") {
      return currentStep?.status === "FINISHED" ? "Patvirtinta" : "Laukiama";
    }

    return currentStep?.status === "FINISHED" ? "TODO" : "TODO";
  }

  function handleClose() {
    setShowDialogOnce(true);
    setOpen(false);
  }
  function getElement(step: StepExecutionResponse) {
    let isUser = step?.step?.executors?.includes("USER");
    if (step.status === "ACTIVE") {
      return (
        <span style={{ color: "#1976d2", fontWeight: "700" }}>
          Aktyvus žingsnis {isUser ? "(Jūsų)" : "(Vartotojų aljanso)"}
        </span>
      );
    }
    if (step.status === "NOT_ACTIVE") {
      return (
        <span style={{ color: "grey", fontWeight: "700" }}>
          Reikės atlikti {isUser ? "(Jums)" : "(Vartotojų aljansui)"}
        </span>
      );
    }
    if (step.status === "FINISHED") {
      return (
        <span style={{ color: "green", fontWeight: "700" }}>
          Atliktas {isUser ? "(Jūsų)" : "(Vartotojų aljanso)"}
        </span>
      );
    }
  }

  return <>
    {currentStep && (
      <Box
        key={currentStep.id}
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant={"h1"}>{currentStep.name}</Typography>
          <Hidden mdDown>
            <Box display={"flex"} flexWrap={"wrap"} gap={2} alignItems={"center"} style={{ margin: "1rem 0" }}>
              <Typography variant={"body"} flex={"1 0 300px"}>
                {getCurrentTitle(currentStep)}
              </Typography>

              <Box flex={"0 0 100px"}>
                <LabelComponent
                  success={currentStep.status === "FINISHED"}
                  text={getByLabelText(currentStep)}
                />
              </Box>
            </Box>
          </Hidden>
        </Box>

        <StepExecution
          step={currentStep}
          process={processExecution}
          refresh={getData}
          next={next}
          previous={previous}
          canGoBack={canGoBack}
          canGoNext={canGoNext}
        />

      </Box>
    )}

    {common.sideMenuIsPermanent && (
      <Drawer
        variant="permanent"
        anchor="right"
        sx={{
          // width: "300px",
          "& .MuiDrawer-paper": {
            width: "300px",
            zIndex: "0",
            height: "calc(100% - 80px)",
            marginTop: "80px",
          },
        }}
      >
        <List>
          {processExecution?.stepExecutions?.map(
            (step: StepExecutionResponse, listIndex: number) => (
              <Box
                key={listIndex}
                style={{
                  boxShadow:
                    "0 8px 24px -4px #18274b14,0 6px 12px -6px #18274b1f",
                  border: "2.5px solid #abbdd2",
                  background:
                    currentStepId === listIndex ? "#dde4ed" : "white",
                  borderRadius: "16px",
                  margin: "1rem",
                  cursor: "pointer",
                }}
              >
                <ListItem
                  key={listIndex}
                  onClick={() => next(listIndex)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {getIcon(step.type)}
                  <ListItemText
                    primary={`${step.name}`}
                    secondary={<>{getElement(step)}</>}
                    style={{
                      color: currentStepId === listIndex ? "black" : "gray",
                      marginLeft: "0.5rem",
                    }}
                  />
                </ListItem>
              </Box>
            ),
          )}
        </List>
      </Drawer>
    )}

    <Dialog open={open} onClose={handleClose} maxWidth={"lg"} fullWidth>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}>

        {adminTurnOffer && <InfoComponent
          title={"Laukite kol Vartotojų aljansas jums pasiūlys galimai patirtos žalos sumą"}
          waiting={true}
          IconComponent={ThumbUpAltIcon}
        >
          Ačiū už pateiktus dokumentus. Dabar sulaukite atsakymo. Vartotojų aljansas, peržiūrėjęs visus jūsų pateiktus duomenis, apskaičiuos jūsų galimai patirtą žalą. Jeigu jūsų pateiktos informacijos neužteks pilnai įvertinti Jūsų situacijos, Vartotojų aljanso atstovas susisieks su jumis ir paprašys patikslinti ar pateikti papildomą informaciją.
        </InfoComponent>}

        {adminTurnSign && <InfoComponent
          title={"Jūs baigėte pildyti visus dokumentus"}
          waiting={true}
          IconComponent={ThumbUpAltIcon}
        >
          Vartotojų aljansas turi pasirašyti dokumentus, kad būtumėte
          įtrauktas į ieškinio grupę. Apie Vartotojų aljanso pasirašytus dokumentus informuosime atskirai.
          Kylant neaiškumams prašome susisiekti el.paštu: <a href="mailto: vairavimokartelis@vartotojualjansas.lt">vairavimokartelis@vartotojualjansas.lt</a>.
        </InfoComponent>}

        {processExecution?.status === "FINISHED" && <InfoComponent
          title={"Visi dokumentai yra pasirašyti."}
          waiting={true}
          IconComponent={ThumbUpAltIcon}
        >
          Jūs esate įtrauktas į grupės ieškinį. Apie kitas grupės ieškinio
          naujienas informuosime atskirai.  Kylant neaiškumams prašome susisiekti el.paštu: <a href="mailto: vairavimokartelis@vartotojualjansas.lt">vairavimokartelis@vartotojualjansas.lt</a>.
        </InfoComponent>}

        <Button size={"large"} style={{ marginTop: "1rem" }} variant={"contained"} onClick={handleClose}>Tęsti toliau</Button>
      </DialogContent>
    </Dialog>
    {backdropOpen && <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdropOpen}
    >
      <CircularProgress color="inherit" />
    </Backdrop>}
  </>
};

export default ProcessExecutionPage;


