import React, { useState, useEffect } from 'react';

function TimerComponent() {
    const [seconds, setSeconds] = useState(120);

    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000); // This will increase the seconds count every 1000ms (or 1 second).

        // Cleanup timer when component is unmounted
        return () => clearInterval(timer);
    }, []); // The empty dependency array means this effect will only run once, similar to componentDidMount.

    useEffect(() => {
        if (seconds < 0) {
            // You can take any action here when 120 seconds have passed, like alerting the user or updating some state.
        }
    }, [seconds]); // This effect will run every time the seconds state changes.

    return <>{seconds}</>

}

export default TimerComponent;