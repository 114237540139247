import { Button, Typography } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaSelect from "components/field/PaSelect";
import PaTextField from "components/field/PaTextField";
import PaSwitch from "components/field/PaSwitch";
import DataInputDefinition from "./definition/DataInputDefinition";
import SignDefinition from "./definition/SignDefinition";
import CustomSpinner from "components/display/CustomSpinner";
import PaymentDefinition from "./definition/PaymentDefinition";
import { createApi } from "util/ApiCreator";
import { ValidationTypeResponse, FileResponse, EmailTemplateResponse, BankAccountResponse, ProcessResponse, StepCreateRequest, ProcessCreateRequest, StepResponse, SignDefinitionInfo } from "api/main/data-contracts";
import { BankAccountController } from "api/main/BankAccountController";
import { EmailTemplateAdminController } from "api/main/EmailTemplateAdminController";

export interface MetaInformation {
  stepTypes?: string[];
  inputTypes?: string[];
  validatorTypes?: ValidationTypeResponse[];
  signTypes?: string[];
  stepExecutors?: string[];
  notificationTypes?: string[];
  files?: FileResponse[];
  emailTemplateTypes?: EmailTemplateResponse[];
  bankAccounts?: BankAccountResponse[];
}

export default function ProcessPage() {
  const api = useRef(createApi("process"))
  const fileApi = useRef(createApi("filestorage"))

  const emailTemplateAPI = useRef(createApi("emailtemplate") as EmailTemplateAdminController)
  const bankAccountApi = useRef(createApi("bankaccount") as BankAccountController)

  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [metaInfo, setMetaInfo] = useState<MetaInformation>();
  const [process, setProcess] = useState<ProcessResponse>();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setLoading(true);

    const stepTypes = await api.current.findAllStepTypes();
    const inputTypes = await api.current.findAllInputTypes();
    const validatorTypes = await api.current.findAllValidatorTypes();
    const signTypes = await api.current.findAllSignTypes();
    const executors = await api.current.findAllStepExecutors();
    const notificationTypes = await api.current.findAllNotificationTypes();
    const files = await fileApi.current.findAll();
    const emailTemplates = await emailTemplateAPI.current.findAll();
    const bankAccounts = await bankAccountApi.current.findAll();

    if (id) {
      let process = await api.current.findById(+id);
      setProcess(process);
    }

    setMetaInfo({
      stepTypes: stepTypes,
      inputTypes: inputTypes,
      validatorTypes: validatorTypes,
      signTypes: signTypes,
      stepExecutors: executors,
      notificationTypes: notificationTypes,
      files: files,
      emailTemplateTypes: emailTemplates,
      bankAccounts: bankAccounts,
    });
    setLoading(false);
  };

  async function handleFormikSubmit(values: FormValues) {
    const createRequest = toCreateRequest(values);
    if (process && process.id) {
      await api.current.update(
        process?.id,
        { id: process.id, ...createRequest },
        {},
      );
    } else {
      await api.current.create(createRequest, {});
    }
    navigate("/process");
  }

  if (loading) {
    return <CustomSpinner label={"Prašome palaukti. Kraunama..."} />;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          maxWidth: "1024px",
          width: "100%",
          margin: "0 auto",
        }}
      >
        {!loading && (
          <Formik
            initialValues={getInitialValues(process)}
            validateOnChange={false}
            onSubmit={handleFormikSubmit}
          >
            {({ values, isValid, dirty, setValues, setFieldValue }) => {
              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant={"h2"}>Proceso informacija</Typography>
                    {Boolean(process) && (
                      <PaSwitch
                        name={"active"}
                        label={values.active ? "Išjungti" : "Įjungti"}
                      />
                    )}
                  </div>
                  {Boolean(process) && (
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        alert(
                          "Prašome patikrinti consolę. Joje rasite žinutę su generuotu proceso requestu.",
                        );
                        console.log(JSON.stringify(toCreateRequest(values)));

                      }}
                    >
                      Exportuoti
                    </Button>
                  )}
                  {!Boolean(process) && (
                    <Button
                      variant={"outlined"}
                      onClick={async () => {
                        const request = prompt(
                          "Prašome įkopijuoti JSON tipo duomenis:",
                        );
                        if (request === null || request === "") {
                          alert("Neteisingi duomenys");
                        } else {
                          const createRequest = JSON.parse(request);
                          await setValues(
                            getInitialValues(createRequest),
                          );
                          await setFieldValue("imported", false);
                        }
                      }}
                    >
                      Importuoti
                    </Button>
                  )}
                  <PaTextField
                    name={"name"}
                    label={"Proceso pavadinimas"}
                    disabled={Boolean(process)}
                  />
                  {/* <PaTextField name={"i18nkey"} label={"Proceso i18nkey"} /> */}

                  <FieldArray
                    name="steps"
                    render={(arrayHelpers) => (
                      <>
                        {values?.steps?.map(
                          (step: StepCreateRequest, stepIndex: number) => (
                            <div
                              key={stepIndex}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                                border: "1px solid black",
                                padding: "1rem",
                              }}
                            >
                              {/* PROCESS STEP INFORMATION */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant={"h3"}>
                                  Etapo nr. {stepIndex + 1} informacija
                                </Typography>
                                <Button
                                  variant={"outlined"}
                                  onClick={() =>
                                    arrayHelpers.swap(stepIndex, stepIndex + 1)
                                  }
                                >
                                  Perkelti į priekį
                                </Button>

                                <Button
                                  variant={"outlined"}
                                  onClick={() =>
                                    arrayHelpers.swap(stepIndex, stepIndex - 1)
                                  }
                                >
                                  Perkelti atgal
                                </Button>

                                <Button
                                  variant={"outlined"}
                                  onClick={() =>
                                    arrayHelpers.insert(
                                      stepIndex + 1,
                                      getStepInitialValues(undefined, stepIndex),
                                    )
                                  }
                                >
                                  Įterpti po
                                </Button>
                                <Button
                                  variant={"outlined"}
                                  onClick={() => arrayHelpers.remove(stepIndex)}
                                >
                                  Ištrinti etapą
                                </Button>
                              </div>
                              <PaTextField
                                name={`steps.${stepIndex}.name`}
                                label={"Etapo pavadinimas"}
                              />
                                <PaTextField
                                name={`steps.${stepIndex}.description`}
                                label={"Etapo aprašymas"}
                              />
                             
                              {/* <PaTextField
                                name={`steps.${stepIndex}.i18nkey`}
                                label={"Etapo i18nkey"}
                              /> */}
                              <PaSelect
                                name={`steps.${stepIndex}.executors`}
                                label={"Etapo atlikėjai"}
                                tooltip={"Pasirinkite, kas atliks šį etapą."}
                                options={
                                  metaInfo?.stepExecutors?.map((executor) => ({
                                    value: executor,
                                    label: executor,
                                  })) ?? []
                                }
                                multiple
                              />
                              <PaSelect
                                name={`steps.${stepIndex}.notificationType`}
                                label={"Vartotojo informavimas"}
                                tooltip={
                                  "Pasirinkite, kada išsiųsti laišką suformuotą naudojantis šabloną."
                                }
                                options={
                                  metaInfo?.notificationTypes?.map((type) => ({
                                    value: type,
                                    label: type,
                                  })) ?? []
                                }
                              />
                              {values.steps &&
                                values.steps[stepIndex].notificationType !==
                                "NONE" && (
                                  <PaSelect
                                    name={`steps.${stepIndex}.emailTemplateId`}
                                    label={"Laiško šablonas"}
                                    tooltip={
                                      "Pasirinkite, kurį laiško šabloną naudoti."
                                    }
                                    options={
                                      metaInfo?.emailTemplateTypes?.map(
                                        (emailTemplate) => ({
                                          value: emailTemplate?.id,
                                          label: `${emailTemplate?.id}. ${emailTemplate?.subject}`,
                                        }),
                                      ) ?? []
                                    }
                                  />
                                )}
                              <PaSelect
                                name={`steps.${stepIndex}.type`}
                                label={"Etapo tipas"}
                                tooltip={"Pasirinkite, koks yra etapo tipas."}
                                options={
                                  metaInfo?.stepTypes?.map((stepType) => ({
                                    value: stepType,
                                    label: stepType,
                                  })) ?? []
                                }
                              />
                              {/* PROCESS STEP INFORMATION  END */}
                              {step?.type === "DATA_INPUT" && (
                                <DataInputDefinition
                                  meta={metaInfo}
                                  step={step}
                                  stepIndex={stepIndex}
                                />
                              )}
                              {step?.type === "SIGN" && (
                                <SignDefinition
                                  meta={metaInfo}
                                  stepIndex={stepIndex}
                                  values={values}
                                />
                              )}
                              {step?.type === "PAYMENT" && (
                                <PaymentDefinition
                                  stepIndex={stepIndex}
                                  meta={metaInfo}
                                />
                              )}
                    
                              {step?.type === "DATA_OUTPUT" && (
                                <>rodom DATA_OUTPUT</>
                              )}
                              {step?.type === "CONFIRMATION" && (
                                <>CONFIRMATION</>
                              )}
                       
                              {step?.type === "IDENTIFICATION" && (
                                <>IDENTIFICATION</>
                              )}
                            </div>
                          ),
                        )}

                        <Button
                          variant={"outlined"}
                          onClick={() =>
                            arrayHelpers.push(getStepInitialValues())
                          }
                        >
                          Pridėti naują etapą
                        </Button>
                      </>
                    )}
                  />

                  <Button
                    variant={"contained"}
                    type={"submit"}
                    disabled={!(isValid && dirty)}
                  >
                    Patvirtinti ir išsaugoti
                  </Button>
                  {JSON.stringify(values, null, 2)}
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
}

function getInitialValues(process?: ProcessCreateRequest): ProcessCreateRequest {
  return {
    i18nkey: process?.i18nkey ?? "",
    name: process?.name ?? "",
    active: process?.active ?? false,
    steps:
      process?.steps?.map((step: StepResponse) => getStepInitialValues(step)) ??
      [],
  };
}
type FormValues = ReturnType<typeof getInitialValues>;

export function getStepInitialValues(step?: StepResponse, stepIndex?: number): StepCreateRequest {
  let dto = {
    i18nkey: step?.i18nkey ?? "",
    name: step?.name ?? "",
    description: step?.description ?? "",
    stepInformation: {
      inputContent: step?.stepInformation?.inputContent,
      signContent: step?.stepInformation?.signContent,
      paymentContent: step?.stepInformation?.paymentContent,
      generateDocumentContent: step?.stepInformation?.generateDocumentContent,
      confirmationContent: step?.stepInformation?.confirmationContent,
      notificationContent: step?.stepInformation?.notificationContent,
      uploadDocumentContent: step?.stepInformation?.uploadDocumentContent,
    },
    type: step?.type,
    executors: step?.executors ?? [],
    notificationType: step?.notificationType ?? "NONE",
    emailTemplateId: step?.emailTemplate?.id
  };
  return dto;
}
export type StepValues = ReturnType<typeof getStepInitialValues>;

function getSignValues(step?: StepCreateRequest): SignDefinitionInfo {
  if (!step?.stepInformation) throw new Error("missing step information");
  return {
    fileId: step.stepInformation.signContent?.fileId,
    signType: step.stepInformation.signContent?.signType,
    documentFetchLocation: step.stepInformation.signContent?.documentFetchLocation,
    signLabel: step.stepInformation.signContent?.signLabel,
  };
}

function cleanStepInformation(step: StepCreateRequest) {
  if (!step.stepInformation) return;
  switch (step.type) {
    case "DATA_INPUT":
      step.stepInformation.signContent &&
        (step.stepInformation.signContent = undefined);
      step.stepInformation.paymentContent &&
        (step.stepInformation.paymentContent = undefined);
      break;
    case "SIGN":
      step.stepInformation.inputContent &&
        (step.stepInformation.inputContent = undefined);
      step.stepInformation.paymentContent &&
        (step.stepInformation.paymentContent = undefined);
      break;
    case "PAYMENT":
      step.stepInformation.inputContent &&
        (step.stepInformation.inputContent = undefined);
      step.stepInformation.signContent &&
        (step.stepInformation.signContent = undefined);
      break;
  }
}
function toCreateRequest(values: FormValues): ProcessCreateRequest {
  values?.steps?.forEach((step: StepCreateRequest) => {
    step.stepInformation?.inputContent?.forEach(group => {
      group.forEach(input => {
        if (!input || !input.validations)
          return;
      })
    })

    cleanStepInformation(step);
  });

  return {
    name: values.name,
    i18nkey: values.i18nkey,
    active: values.active,
    steps:
      values.steps &&
      values.steps.map(
        (step: StepValues): StepCreateRequest => ({
          i18nkey: step?.i18nkey,
          name: step?.name,
          description: step?.description,
          stepInformation: step?.stepInformation ?? undefined,
          type: step?.type,
          executors: step?.executors,
          notificationType: step?.notificationType ?? "NONE",
          emailTemplateId: step?.emailTemplateId ?? undefined,
        }),
      ),
  };
}
