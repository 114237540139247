/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ProcessExecutionCreateRequest, ProcessExecutionResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ProcessExecutionPublicController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Execute a process
   *
   * @tags process-execution-public-controller
   * @name Execute
   * @request PUT:/api/v1/process-execution/{stepExecutionId}
   */
  execute = (stepExecutionId: number, data: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/process-execution/${stepExecutionId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Fetch all process executions
   *
   * @tags process-execution-public-controller
   * @name FindAllByUser
   * @request GET:/api/v1/process-execution
   */
  findAllByUser = (params: RequestParams = {}) =>
    this.request<ProcessExecutionResponse[], any>({
      path: `/api/v1/process-execution`,
      method: "GET",
      ...params,
    });
  /**
   * @description Create a process
   *
   * @tags process-execution-public-controller
   * @name Create
   * @request POST:/api/v1/process-execution
   */
  create = (data: ProcessExecutionCreateRequest, params: RequestParams = {}) =>
    this.request<ProcessExecutionResponse, any>({
      path: `/api/v1/process-execution`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Fetch a process execution by id
   *
   * @tags process-execution-public-controller
   * @name FindById
   * @request GET:/api/v1/process-execution/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<ProcessExecutionResponse, any>({
      path: `/api/v1/process-execution/${id}`,
      method: "GET",
      ...params,
    });
}
