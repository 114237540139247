
import { AccountController } from "api/auth/AccountController";
import { AuthController } from "api/auth/AuthController";
import { AuthController as AuthControllerMain } from "api/main/AuthController";
import { BankAccountController } from "api/main/BankAccountController";
import { EmailAdminController } from "api/main/EmailAdminController";
import { EmailTemplateAdminController } from "api/main/EmailTemplateAdminController";
import { FileController } from "api/main/FileController";
import { FilePublicController } from "api/main/FilePublicController";
import { IdentificationController } from "api/main/IdentificationController";
import { MessageAdminController } from "api/main/MessageAdminController";
import { MessagePublicController } from "api/main/MessagePublicController";
import { PaymentController } from "api/main/PaymentController";
import { ProcessController } from "api/main/ProcessController";
import { ProcessExecutionAdminController } from "api/main/ProcessExecutionAdminController";
import { ProcessExecutionPublicController } from "api/main/ProcessExecutionPublicController";
import { ProcessPublicController } from "api/main/ProcessPublicController";
import { SigningController } from "api/main/SigningController";
import CommonConstants from "constants/CommonConstants"
import { toast } from 'react-toastify';

const notify = (message: string) => toast.success(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
});

const notifyErr = (message: string) => toast.error(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
});

export const createApi = (type: string): any => {
    let ret;
    switch (type) {
        case "processexecutionadmin":
            ret = new ProcessExecutionAdminController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "processexecution":
            ret = new ProcessExecutionPublicController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "auth":
            ret = new AuthController({ baseURL: CommonConstants.apiBaseUrl });
            break;

        case "account":
            ret = new AccountController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "process":
            ret = new ProcessController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "processpublic":
            ret = new ProcessPublicController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "bankaccount":
            ret = new BankAccountController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "payment":
            ret = new PaymentController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "email":
            ret = new EmailAdminController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "emailtemplate":
            ret = new EmailTemplateAdminController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "filestorage":
            ret = new FileController({ baseURL: CommonConstants.apiBaseUrl })
            break;
            
        case "filestoragepublic":
            ret = new FilePublicController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "authold":
            ret = new AuthControllerMain({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "identification":
            ret = new IdentificationController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "messages":
            ret = new MessageAdminController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "messagespublic":
            ret = new MessagePublicController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        case "signing":
            ret = new SigningController({ baseURL: CommonConstants.apiBaseUrl })
            break;

        default:
            console.error("could not find api with type: ", type)
            return;
    }

    ret.instance.interceptors.response.use(
        (response: any) => {
            return response;
        },
        (error: any) => {
            notifyErr(translate(error?.response?.data || error?.message || error?.error?.message));
            throw error
        }
    )

    return ret;
}


function translate(message: string) {
    let obj = {
        "err.auth.registrationClosed": "Šiuo metu registracija jau yra pasibaigusi. Prisijungti naujiems vartotojams negalima, jeigu jungėtes prie ieškinio anksčiau, prašome jungtis su tuo el. pašto adresu"
    } as any

    let ret = obj[message];

    return ret || message;
} 