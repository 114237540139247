import {
  LocalizationProvider,
  DateTimePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box } from "@mui/material";
import { useState } from "react";

export interface CustomDateTimePickerProps {
  label: string;
  helperText?: string;
  name: string;
  minDateTime?: moment.Moment;
  maxDateTime?: moment.Moment;
  disabled?: boolean;
}

export default function PaDateTimePicker(
  props: CustomDateTimePickerProps & FieldHookConfig<moment.Moment>,
) {
  const [field, meta] = useField(props);
  const [error, setError] = useState<any>(null);
  const { setFieldValue } = useFormikContext<moment.Moment>();

  const handleChange = (value: any): void => {
    setFieldValue(props.name, moment(value), true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box style={{ marginBottom: "1rem", width: "100%" }}>
        <DatePicker
          sx={{ width: "100%" }}
          {...settings}
          value={field.value ? moment(field.value) : null}
          onChange={handleChange}
          label={props.label || "label"}
          minDate={props?.minDateTime}
          maxDate={props?.maxDateTime}
          disabled={props?.disabled}
          onError={(newError) => setError(newError)}
          slotProps={{
            textField: {
              error: Boolean(meta.error),
              helperText: props?.helperText,
              name: field.name,
              placeholder: field.name,
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

const settings = {
  format: "YYYY-MM-DD",
  minDate: moment("1990-01-01T00:00:00"),
  maxDate: moment("2099-12-31T00:00:00"),
};
