import TranslationItem from "models/TranslationItem";

enum FileType {
  MANUAL = "MANUAL",
  GENERATED = "GENERATED",
  SIGNED = "SIGNED",
  USER_INPUTTED = "USER_INPUTTED",
}

const allFileTypes: TranslationItem[] = [
  {
    value: FileType.MANUAL,
    label: "Administratoriaus",
  },
  {
    value: FileType.GENERATED,
    label: "Sugeneruotas",
  },
  {
    value: FileType.SIGNED,
    label: "Pasirašytas",
  },
  {
    value: FileType.USER_INPUTTED,
    label: "Vartotojo",
  },
];

function findFileType(value?: string) {
  return allFileTypes?.find((status) => value && status?.value === value);
}

export { allFileTypes, findFileType };
