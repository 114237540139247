import "./CustomHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import NaturalPersonAvatar from "assets/natural-person-avatar.svg";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAppSelector } from "redux/hooks";
import ReduxCommonApi from "redux/common/ReduxCommonApi";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import FAQDialog from "pages/homepage/FaqDialog";
import ReduxUserApi from "redux/user/ReduxUserApi";
import CommonConstants from "constants/CommonConstants";
import { useNavigate } from "react-router-dom";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { createApi } from "util/ApiCreator";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { MessagePublicController } from "api/main/MessagePublicController";

const CustomHeader = () => {
  const [badgeCount, setBadgeCount] = useState(0);

  // On component mount, check localStorage to see if notification was already clicked
  useEffect(() => {
    getMessages();
  }, []);

  // Handle click on the notification icon
  const handleNotificationClick = () => {
    // Navigate to messages
    navigate('messages');

    // Set badge count to 0
    setBadgeCount(0);

    // Set item in localStorage to remember that user has clicked the notification
    localStorage.setItem('hasClickedNotification', badgeCount + "");
  };

  const getMessages = async () => {
    let num = await messageApi.current.getCount();
    let clicked = localStorage.getItem('hasClickedNotification') || 0;
    if (+clicked !== num) {
      setBadgeCount(num);
    }
  }

  const common = useAppSelector((state) => state.common);
  const me = useAppSelector((state) => state.me);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const authApi = useRef(createApi("auth"));
  const messageApi = useRef(createApi("messagespublic") as MessagePublicController);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoff = () => {
    ReduxUserApi.logOffMe(authApi);
    navigate(CommonConstants.loginPathName);
  };

  const [modalOpen, setOpen] = useState(false);

  return (
    <AppBar position="static" style={{ position: "relative", zIndex: "1" }}>
      <Toolbar
        disableGutters
        className={"customHeader-main"}
        style={{
          justifyContent:
            common?.sideMenuIsPermanent || common?.sideMenuIsHidden
              ? undefined
              : "space-between",
          backgroundColor: "white",
          color: "black",
        }}
      >
        {!common?.sideMenuIsPermanent && !common?.sideMenuIsHidden && (
          <Button
            variant={"text"}
            onClick={ReduxCommonApi.openSideMenu}
            disableRipple
          >
            <MenuIcon
              sx={{
                height: "32px",
                color: "black",
              }}
            />
          </Button>
        )}
        <Box style={{ display: "flex", height: "100%", alignItems: "center" }}>
          <Box onClick={() => navigate("/home")} style={{ cursor: "pointer" }}>
            <img
              alt={"Vartotojų aljanso paveiksliukas"}
              style={{ height: "75px", flex: "1" }}
              src={process.env.PUBLIC_URL + "/logo.png"}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            className={"hide-on-mobile"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginLeft: "1rem",
            }}
          >
            <Box
              style={{
                fontSize: "15px",
                color: "#213141",
              }}
            >
              {" "}
              Grupės ieškinys prieš vairavimo mokyklų kartelį
            </Box>
            <Box
              style={{
                fontSize: "12px",
                color: "#617a96",
              }}
            >
              {" "}
              Dėl žalos atlyginimo
            </Box>
          </Box>
        </Box>
        <Box style={{ marginLeft: "auto", display: "flex", gap: "1rem", alignItems: "center" }}>
          <Box className={"hide-on-mobile"}>
            <IconButton onClick={() => { navigate("messages"); handleNotificationClick() }}>
              <Badge badgeContent={badgeCount} color="primary">
                <NotificationsIcon color="action" />
              </Badge>
            </IconButton>
          </Box>
          <Button
            variant="outlined"
            className={"hide-on-mobile"}
            size="large"
            color="primary"
            onClick={() => setOpen(!open)}
          >
            DUK
          </Button>

          <Button
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img
              src={NaturalPersonAvatar}
              style={{ paddingRight: "0.5rem", height: "46px", width: "46px" }}
              alt={"person icon"}
            />

            <Box className={"hide-on-mobile"} sx={{
              display: "flex"
            }}
            >
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}>
                <Box
                  style={{
                    fontSize: "12px",
                    color: "#617a96",
                    fontWeight: "700",
                  }}
                >
                  Prisijungęs kaip
                </Box>

                <Typography variant={"h6"} id={"me-fullname"}>
                  {me?.info?.email}
                </Typography>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>

          </Button>
        </Box>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setOpen(!modalOpen);
              handleClose();
            }}
            disableRipple
            style={{ display: "flex", alignItems: "center" }}
          >
            <LiveHelpIcon style={{ marginRight: "0.5rem" }} />
            DUK{" "}
          </MenuItem>
          <MenuItem
            onClick={handleLogoff}
            disableRipple
            style={{ display: "flex", alignItems: "center" }}
          >
            <LogoutIcon style={{ color: "red", marginRight: "0.5rem" }} />
            Atsijungti
          </MenuItem>
        </Menu>
      </Toolbar>
      <FAQDialog open={modalOpen} handleClose={() => setOpen(!modalOpen)} />
    </AppBar>
  );
};

export default CustomHeader;
