import { createTheme, responsiveFontSizes } from "@mui/material";
import React from "react";

let AppTheme = createTheme({
  components: {

  },
  typography: {
    h1: {
      fontSize: 36,
      lineHeight: 50/36,
      fontWeight: 700,
      color: "#183568",
    },
    h2: {
      fontSize: 24,
      lineHeight: 39/24,
      fontWeight: 700,
      color: "#171440",
    },
    h3: {
      fontSize: 20,
      lineHeight: 25/20,
      fontWeight: 600,
      color: "#171440",
    },
    h4: {
      fontSize: 18,
      lineHeight: 25/18,
      fontWeight: 500,
      color: "#171440",
    },
    h5: {
      fontSize: 16,
      lineHeight: 22/16,
      fontWeight: "700",
      color: "#171440",
    },
    h6: {
      fontSize: 16,
      lineHeight: 22/16,
      fontWeight: 400,
      color: "#171440",
    },
    subtitle: {
      fontSize: 14,
      lineHeight: 16 / 14,
      fontWeight: 400,
      color: "rgba(23, 20, 64, 0.6)",
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: 14 / 12,
      fontWeight: 400,
      color: "rgba(23, 20, 64, 0.6)",
    },
    button: {
      fontSize: 15,
      lineHeight: 21/ 15,
      fontWeight: 400,
      color: "#171440",
      textTransform: "none",
    },
  },
  palette: {
    // primary: {
    //   // main: "rgb(24, 53, 104)",
    //   // light: "#F7F8FC",
    // },
    // secondary: {
    //   // main: "#FF3B30",
    // },
    // background: {
    //   // default: "#FFFFFF",
    //   // light: "#F7F8FC",
    // },
    // error: {
    //   // main: "#E53935",
    //   // light: "#9d9291",
    // },
    // warning: {
    //   // main: "#FFC107",
    // },
  },
});

AppTheme = responsiveFontSizes(AppTheme)

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    hyperlink: true;
    overline: false;
    body: true;
    body1: false;
    subtitle: true;
    subtitle1: false;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    hyperlink: React.CSSProperties;
    body: React.CSSProperties;
    subtitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    hyperlink?: React.CSSProperties;
    body?: React.CSSProperties;
    subtitle?: React.CSSProperties;
  }
}

export default AppTheme;
