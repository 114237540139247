import { Box, Button, Grid, Typography } from "@mui/material";
import { BankAccountResponse, PaymentContent, ProcessExecutionResponse, StepExecutionResponse } from "api/main/data-contracts";
import CustomSpinner from "components/display/CustomSpinner";
import { useEffect, useRef, useState } from "react";
import { createApi } from "util/ApiCreator";

interface Props {
  process: ProcessExecutionResponse;
  step: StepExecutionResponse;
  execute: (obj: object) => Promise<void>;
  refresh: () => Promise<void>;
  previous: () => void;
  next: () => void;
  canGoNext: boolean;
  canGoBack: boolean;
}

export default function PaymentExecution({
  process,
  step,
  execute,
  refresh,
  next,
  previous,
  canGoBack, 
  canGoNext
}: Props) {

  const [bankAccount, setBankAccount] = useState<BankAccountResponse>();
  const paymentContent: PaymentContent = getPaymentContent();
  const bankAccountApi = useRef(createApi("bankaccount"));
  const kevinApi = useRef(createApi("payment"));
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentStatus, setPaymentStatus] = useState<string>(step.executionInfo?.paymentInfo?.status ?? 'defaultStatus');
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get('paymentId');
  const status = urlParams.get('status');
  const statusGroup = urlParams.get('statusGroup');

  const fetchBankAccount = async () => {
    if (!paymentContent.bankAccountId)
      return;
    setBankAccount(await bankAccountApi.current.findById(paymentContent.bankAccountId));
  }

  const getPaymentStatus = async () => {
    if (!isUrlParamsCorrect()) {
      return;
    }
    step.id && paymentId && setPaymentStatus(await kevinApi.current.getPaymentStatus(step.id, paymentId));
    await refresh();
  }

  function isUrlParamsCorrect(): boolean {
    if (paymentId === null || statusGroup === null || status === null) {
      return false;
    }
    return true;
  };

  function getPaymentContent(): PaymentContent {
    if (!step.step?.stepInformation?.paymentContent) {
      throw new Error("PaymentContent not found");
    }
    return step.step.stepInformation.paymentContent;
  }

  const isCompleted: boolean = paymentStatus === "completed";
  const isStepActive: boolean = step.status === "FINISHED";

  useEffect(() => {
    setLoading(true);
    getPaymentStatus();
    fetchBankAccount();
    setLoading(false);
  }, []);

  const handlePaymentButtonClick = async () => {
    if (!process.id) {
      return;
    }
    if (!step.id) {
      return;
    }
    const response = await kevinApi.current.initializePayment(process?.id, step?.id);
    if (response) {
      window.location.replace(response);
    }
  }

  const handleExecuteButtonClick = async () => {
    if (paymentStatus !== "completed" && paymentStatus !== "pending") {
      return;
    }
    await execute({ paymentContent }).catch((err: any) => {
    });
    await refresh();
  }

  const errorMessage = (error: string) => (
    <Typography
      variant="subtitle"
      color='red'
    >
      {error}
    </Typography>
  )

  if (loading) {
    return <CustomSpinner label={"Prašome palaukti. Kraunama..."} />;
  }
  return (
    <>
      <Box>
        <Typography variant="h2" sx={{ marginBottom: 2 }}>
          Mokėjimo detalės
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <strong>Gavėjas:</strong> {bankAccount?.creditorName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Gavėjo sąskaitos numeris:</strong> {bankAccount?.creditorIban}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Suma:</strong> {paymentContent.amount}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Mokėjimo paskirtis:</strong> {paymentContent.description}
            </Typography>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handlePaymentButtonClick}
          disabled={isCompleted}
          style={{ padding: "0.5rem 1rem" }}
        >
          Apmokėti
        </Button>
        {paymentStatus === "failed" && errorMessage("Nepavyko apmokėti. Bandykite dar kartą.")}

      </Box>
      <Box style={{ display: "flex", marginTop: "auto", gap: "1rem" }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
          disabled={isStepActive}
          onClick={() => handleExecuteButtonClick()}
        >
          Patvirtinti mokėjimą
        </Button>
      </Box>
      <Box style={{ display: "flex", marginTop: "auto", gap: "1rem" }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          disabled={!isCompleted}
          style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
          onClick={() => next()}
        >
          Kitas
        </Button>
      </Box>
    </>
  )
}