import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import CustomSpinner from "components/display/CustomSpinner";
import { createApi } from "util/ApiCreator";
import { InputContent, ProcessExecutionResponse, StepExecutionResponse } from "api/main/data-contracts";
import { ProcessExecutionAdminController } from "api/main/ProcessExecutionAdminController";
import { Box, Button, Divider, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import React from "react";
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import { hasRole } from "util/UtilityFunctions";
import { RoleNames } from "models/auth/RoleNames";
import { useAppSelector } from "redux/hooks";
import CommonConstants from "constants/CommonConstants";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function ProcessExecutionAdminPage() {
  const me = useAppSelector((state) => state.me);

  const { id } = useParams();
  const executionApi = useRef(createApi("processexecutionadmin") as ProcessExecutionAdminController);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setExecution] = useState<ProcessExecutionResponse>();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getData() {
    setLoading(true);
    if (id) {
      setExecution(await executionApi.current.findById(+id));
    }
    setLoading(false);
  }

  if (loading) {
    return <CustomSpinner label={"Prašome palaukti. Kraunama..."} />;
  }
  return <>
    <Box display="flex" flexDirection={"column"} style={{gap: "1rem"}}>
      {data?.status !== "CANCELLED" && data?.status !== "DEAL_DONE" && hasRole(RoleNames.ADMIN, me?.info?.roles) && <Button variant={"contained"}
        color="error"
        size="large"
        style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
        onClick={async () => { if (!data?.id) return; await executionApi.current.cancel(data?.id); getData() }}
      >Atmesti paraišką</Button>
      }
      {data?.status == "CANCELLED" && <Typography variant="h2" color={"error"}>Ieškinys atmestas</Typography>
      }

      {data?.status !== "DEAL_DONE" && data?.status !== "CANCELLED" && hasRole(RoleNames.ADMIN, me?.info?.roles) && <Button variant={"contained"}
        color="info"
        size="large"
        style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
        onClick={async () => { if (!data?.id) return; await executionApi.current.dealDone(data?.id); getData() }}
      >Vairavimo mokykla grąžino pinigus vartotojui</Button>
      }
      {data?.status == "DEAL_DONE" && <Typography variant="h2" color="green">Vairavimo mokykla grąžino pinigus vartotojui</Typography>}
    </Box>

    <div style={{ padding: '20px' }}>
      <Typography variant="h2" gutterBottom>
        Proceso pavadinimas: {data?.process?.name}
      </Typography>
      <Typography variant="h3" gutterBottom>
        Statusas: {data?.status}
      </Typography>

      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Steps
        </Typography>
        <List>
          {data?.stepExecutions?.map((step: StepExecutionResponse, index) => <>
            <React.Fragment key={step.id}>
              <Typography variant="h3" gutterBottom>
                Vykdo: {step.step?.executors}
              </Typography>
              <ListItem>
                <ListItemText
                  primary={step.name}
                  secondary={getContent(step)}
                />

                {step.status === 'FINISHED' && <CheckIcon color="primary" />}
                {step.status === 'NOT_ACTIVE' && <HighlightOffIcon color="error" />}
                {step.status === 'ACTIVE' && <HourglassEmptyIcon color="warning" />}
              </ListItem>
              {data?.process?.steps?.length && index !== data?.process?.steps?.length - 1 && <Divider />}
            </React.Fragment>
          </>)}
        </List>
      </Paper>
    </div>
  </>

}

const showInputContent = (obj: any) => {
  let ret = JSON.stringify(obj);

  let rrr = JSON.parse(ret);
  console.log(typeof ret, typeof rrr);

  if (typeof rrr === "object") {
    console.log("inputcontent: ", rrr);
    return <>{rrr.map((r: any) => <Box onClick={() => handleDownload(r.id, r.name)}>{r.name} ({r.type}) <Button variant="contained">Parsisiųsti</Button> </Box>)}</>
  }

  return ret;
}

const getContent = (step: StepExecutionResponse) => {
  let obj = JSON.parse(step.stepInfo || '{}');

  if (step.status !== 'FINISHED') return <b style={{ color: "red" }}>Šis žingsnis dar neįvykdytas</b>

  return <>
    {step.type === 'SIGN' && <>

      Failą galite parsisiųsti paspaudę&nbsp;
      <a style={{ textDecoration: "underline", fontWeight: 500, cursor: "pointer" }} onClick={() => handleDownload(step.executionInfo?.signingInfo?.fileId, 'download.pdf')}>čia (fileId: {step.executionInfo?.signingInfo?.fileId})</a>
    </>}
    {step.type === 'IDENTIFICATION' && <>
      <div>Vardas: <b>{step.executionInfo?.identificationInfo?.firstName}</b></div>
      <div>Pavardė: <b>{step.executionInfo?.identificationInfo?.lastName}</b></div>
      <div>Asmens kodas: <b>{step.executionInfo?.identificationInfo?.personCode}</b></div>
      <div>Identifikavosi su: <b>{step.executionInfo?.identificationInfo?.identificationType}</b></div>
    </>}
    {step.type === 'DATA_INPUT' && <>
      {Object.keys(obj)
        .map((key, index) => {
          let a = combineArrays(step?.step?.stepInformation?.inputContent, key);
          return <div>{a?.label}: <b> {showInputContent(obj[key])} </b> </div>
        })}
    </>}
    {step.type === 'CONFIRMATION' && <b style={{ color: "red" }}>NOT IMPLEMENTED</b>}
    <>
      <Divider />
      <div>Įvykdymo laikas: {step.executionInfo?.time}</div>
      <div>Ip: {step.executionInfo?.ip}</div>
      <div>User-agent: {step.executionInfo?.userAgent}</div>
    </>
  </>
}

export async function handleDownload(id?: number, filename?: string) {
  try {
    console.log("siunciam failiuka: ", id)
    if (!id) {
      console.warn("NO id", id);
      return;
    }

    window.open(CommonConstants.apiBaseUrl + `/api/admin/v1/filestorage/download/${id}`, "_self");

  } catch (error) {
    // Handle any errors here
    console.error("Download failed", error);
  }
}

const combineArrays = (inputContent?: InputContent[][], findName?: string): InputContent | undefined => {
  if (!inputContent)
    return;
  let ret: InputContent[] = [];
  inputContent.forEach(group => ret.push(...group));

  return ret.find(r => r.name === findName);
}