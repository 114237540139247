import React, { useState } from "react";
import "./HomePage.css";
import { Box, Button, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonConstants from "constants/CommonConstants";
import FAQDialog from "./FaqDialog";

function HomePageLoggedOff() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        backgroundColor: "rgb(229, 238, 247)",
        overflow: "auto",
      }}
    >
      <div
        style={{
          maxWidth: "1024px",
          padding: "1rem 2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Box style={{ flex: 1, display: "flex", alignItems: "center", marginBottom: "1.5rem", justifyContent: "center" }}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleOpen}
            >
              DUK
            </Button>

            <FAQDialog open={open} handleClose={handleClose} />
          </Box>
          <Hidden mdUp>
            <img
              alt={"Vartotojų aljanso paveiksliukas"}
              style={{ height: "120px" }}
              src={process.env.PUBLIC_URL + "/logo.png"}
            />
          </Hidden>
          <Hidden mdDown>
            <img
              alt={"Vartotojų aljanso paveiksliukas"}
              style={{ height: "180px" }}
              src={process.env.PUBLIC_URL + "/logo.png"}
            />
          </Hidden>
          <Box
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate(CommonConstants.loginPathName)}
            >
              Prisijungti prie grupės ieškinio
            </Button>
          </Box>
        </div>
        <div style={{lineHeight: 1.5}}>
          <h3>

            <b><i>Vartotojai, prisijungę prie Vartotojų aljanso inicijuoto grupės ieškinio dėl vairavimo
              mokyklų karteliniu susitarimu padarytos žalos atlyginimo, švenčia pergalę. Vairavimo
              mokyklos sureagavo į Vartotojų aljanso pateiktą pretenziją ir patenkino vartotojų
              reikalavimus bylai nepasiekus teismo.</i></b>
          </h3>
          <p>
            Konkurencijos taryba 2018 metų pabaigoje paskelbė, kad 26 vairavimo mokyklos bei
            jas vienijanti Lietuvos vairuotojų mokymo ir kvalifikacijos kėlimo mokyklų asociacija
            (LVMA) derino įkainius už vairavimo kursus Vilniuje, Kaune bei Jurbarke. Dėl to vartotojai,
            2016-2018 metais lankę vairavimo kursus šiose mokyklose, buvo priversti mokėti nelegaliai
            padidintą kainą už paslaugas ir patyrė nuostolių. Taryba, įvertinus konkrečias aplinkybes,
            vairavimo mokykloms skyrė baudas, taip pat 10-čiai vairavimo mokyklų vadovų buvo
            pritaikytos asmeninės atsakomybės priemonės. Tačiau šiame procese vartotojams patirti
            nuostoliai nebuvo atlyginti.
          </p>
          <p>Vartotojų aljansas inicijavo grupės ieškinį, kad nukentėjusiems asmenims būtų grąžinti
            už vairavimo kursus permokėti pinigai. Surinkus grupę vairavimo mokykloms buvo pateikta
            pretenzija ir nustatytas terminas nuostolių atlyginimui. Jei iki termino pabaigos su vairavimo
            mokyklomis nebūtų susitarta, Vartotojų aljansas teiktų grupės ieškinį teismui. Tačiau to
            neprireikė – visos vairavimo mokyklos (išskyrus vieną, kuriai vykdoma likvidavimo
            procedūra) patenkino vartotojų reikalavimus.</p>
          <p>
            Vartotojų aljansas – organizacija, jungianti aktyviausius Lietuvoje vartotojų teisių
            gynėjus, Europos vartotojų organizacijos BEUC narė, ilgai siekė, kad grupės ieškinys mūsų
            šalyje taptų vartotojams patogia ir naudinga priemone. Prieš porą metų Vartotojų aljansas
            inicijavo pirmąjį Lietuvoje teismą pasiekusį vartotojų grupės ieškinį dėl įmonės UAB „Prime
            Leasing“, valdančios dalinimosi automobiliais platformą “CityBee”, sukeltos žalos nutekinus
            asmenų duomenis. Šios bylos teisminiai procesai vis dar vyksta.</p>
        </div>
      </div>

    </div>
  );
}

export default HomePageLoggedOff;
