import PaSelect, { PaSelectOption } from "../../../components/field/PaSelect";
import { MetaInformation } from "../Process";
import { ProcessCreateRequest, StepCreateRequest } from "api/main/data-contracts";

interface Props {
  stepIndex: number;
  meta?: MetaInformation;
  values: ProcessCreateRequest;
}

const GENERATE_DOC_ACCEPTABLE_FILE_TYPES = ["doc", "docx"];

export default function SignDefinition(props: Props) {
  const { stepIndex, meta, values } = props;

  return (
    <>
      <PaSelect
        name={`steps.${stepIndex}.stepInformation.signContent.signType`}
        label={"Dokumento pasirašymo tipas"}
        tooltip={"Pasirinkite, kokiu būdu bus dokumentas pasirašomas."}
        options={
          meta?.signTypes?.map((signType) => ({
            value: signType,
            label: signType,
          })) ?? []
        }
      />
      {values.steps && values.steps[stepIndex].stepInformation?.signContent?.signType !== "CONSENT" && (
        <>

          <PaSelect
            name={`steps.${stepIndex}.stepInformation.signContent.documentFetchLocation`}
            label="Dokumento fetchinimo tipas"
            options={
              ["FILE_ID", 'LAST_SIGNED_DOCUMENT', 'FROM_DOCX'].map((inputType) => ({
                value: inputType,
                label: inputType,
              })) ?? []
            }
          />

          {values.steps && values.steps[stepIndex].stepInformation?.signContent?.documentFetchLocation === "LAST_SIGNED_DOCUMENT" && (
            <PaSelect
              name={`steps.${stepIndex}.stepInformation.signContent.signLabel`}
              tooltip={
                "Pasirinkite etapo pavadinimą, kurio dokumentą šiame etape reikės pasirašyti."
              }
              label={"Pavadinimas senesnio SIGN tipo etapo"}
              options={values.steps
                .filter(
                  (step: StepCreateRequest, index: number) =>
                    index < stepIndex && step?.name &&
                    step.type === "SIGN"
                )
                .map(
                  (step: StepCreateRequest): PaSelectOption => ({
                    label: step.name,
                    value: step.name,
                  }),
                )}
            />
          )}
        </>
      )}
      {values.steps && values.steps[stepIndex].stepInformation?.signContent?.documentFetchLocation === "FILE_ID" && <PaSelect
        name={`steps.${stepIndex}.stepInformation.signContent.fileId`}
        label="Dokumento pavadinimas"
        tooltip={"Pasirinkite dokumentą, kurį reikės pasirašyti."}
        options={
          meta?.files?.map((file) => ({
            value: file?.id,
            label: file?.name,
          })) ?? []
        }
      />}

      {values.steps && values.steps[stepIndex].stepInformation?.signContent?.documentFetchLocation === "FROM_DOCX" && <PaSelect
        name={`steps.${stepIndex}.stepInformation.signContent.fileId`}
        label="Dokumento pavadinimas"
        tooltip={"Pasirinkite dokumentą, iš kurio susigeneruos failas, kurį reikės pasirašyti."}
        options={
          meta?.files
          ?.filter((file) => {
            const current = file?.name?.split(".")?.at(-1);
            return (
              current && GENERATE_DOC_ACCEPTABLE_FILE_TYPES.includes(current)
            );
          })
          ?.map((file) => ({
            value: file?.id,
            label: file?.name,
          })) ?? []
        }
      />}
    </>
  );
}
