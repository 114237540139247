import { useContext, useEffect, useRef, useState } from "react";
import { MessageResponse } from "api/main/data-contracts";
import { CustomTable, FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import { CreateDialogProps } from "@recodin/fe-components";
import { createApi } from "util/ApiCreator";
import {
    CustomTableHeadRow,
    CustomTableRow,
    TextCell,
} from "@recodin/fe-components";
import { useTheme } from "@mui/material";
import PaTextField from "components/field/PaTextField";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import { MessageAdminController } from "api/main/MessageAdminController";
import { MessagePublicController } from "api/main/MessagePublicController";

const MessagesListPage = () => {
    const api = useRef(createApi("messages") as MessageAdminController);
    const apiPublic = useRef(createApi("messagespublic") as MessagePublicController);
    const [messages, setMessages] = useState<MessageResponse[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const { formikFormRef } = useContext(FormContext);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getData() {
        setLoading(true);
        setMessages(await apiPublic.current.getAll());
        setLoading(false);
    }

    return (
        <div>
            <CustomTable
                data={messages ?? []}
                isLoading={loading}
                headerRow={MessageTableHeadRow}
                contentRow={(props: { row: MessageResponse }) => (
                    <MessageTableRow row={props.row} />
                )}
                createProps={getCreateProps((e) => { api.current.save(e as any); getData(); })}
                disableFiltering
            />
        </div>
    );
};

export default MessagesListPage;


const MessageCreateUpdateModal = ({ handleEdit }: any) => {
    const [preview, setPreview] = useState<boolean>(false);
    const { formikFormRef } = useContext(FormContext);
    const api = useRef(createApi("messages") as MessageAdminController);


    return <>
        <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={(e) => { handleEdit(e) }}
            innerRef={formikFormRef} // used for submit outside Formik component

        >
            {(formik: FormikProps<any>) => (
                <>
                    <FormContextRefreshConduit />
                    <PaTextField label={"Pranešimo pavadinimas"} name={"subject"} required />
                    <PaTextField
                        label={"Pranešimas"}
                        name={"content"}
                        required
                        labelButtonAction={() => setPreview(!preview)}
                        labelButtonLabel={preview ? "Kodas" : "Peržiūra"}
                        showInstead={
                            !preview ? undefined : (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: preview ? formik.values.content : undefined,
                                    }}
                                />
                            )
                        }
                        rows={10}
                    />

                </>)}
        </Formik>
    </>

}


export function getCreateProps(
    handleSave: (selectedFile: File) => void,
): CreateDialogProps {
    return {
        createButtonLabel: "Sukurti pranešimą",
        headerText: "Naujo pranešimo kūrimas",
        contentTextFor: "pranešimui",
        EditForm: <MessageCreateUpdateModal handleEdit={(data: any) => handleSave(data)} />,
    };
}


export function MessageTableHeadRow() {
    return (
        <CustomTableHeadRow
            cells={[
                {
                    label: "Nr.",
                    width: "10%",
                    align: "left",
                },
                {
                    label: "Pavadinimas",
                    width: "10%",
                    align: "left",
                },
                {
                    label: "Žinutė",
                    width: "70%",
                    align: "left",
                },
                {
                    label: "Sukurta",
                    width: "10%",
                },
            ]}
        />
    );
}

interface MessageRowProps {
    row: MessageResponse;
}
export function MessageTableRow({ row }: MessageRowProps) {
    const theme = useTheme();
    const [clicked, setClicked] = useState<boolean>(false);
    return (
        <CustomTableRow>
            <TextCell width={"10%"} align={"left"} label={row?.id + ""} />
            <TextCell width={"10%"} align={"left"} label={row?.subject} />
            <TextCell width={"70%"} align={"left"} label={row?.content} />
            <TextCell width={"10%"} align={"left"} label={row?.dateCreated} />
        </CustomTableRow>
    );
}

const validationSchema = () =>
    yup.object({
        subject: yup.string().required("Tema yra privalomas."),
        content: yup.string().required("Pranešimas yra privalomas."),
    });
