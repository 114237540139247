import {
  CustomTableHeadRow,
  CustomTableRow,
  TextCell,
} from "@recodin/fe-components";
import { EmailResponse } from "api/main/data-contracts";
import DangerousHTMLViewTypography from "components/display/DangerousHTMLViewTypography";
import { findEmailStatus } from "./EmailStatusTranslation";

export function EmailTableHeadRow() {
  return (
    <CustomTableHeadRow
      cells={[
        {
          label: " ",
          width: "5%",
        },
        {
          label: "Tema",
          width: "40%",
          align: "left",
        },
        {
          label: "Gavėjas",
          width: "35%",
          align: "left",
        },
        {
          label: "Būsena",
          width: "20%",
          align: "left",
        },
      ]}
    />
  );
}

interface EmailTableRowProps {
  row: EmailResponse;
}
export function EmailTableRow({ row }: EmailTableRowProps) {
  return (
    <CustomTableRow
      showButtonOnLeftSide
      collapsableItems={
        <DangerousHTMLViewTypography title={row.subject} label={row?.body} />
      }
    >
      <TextCell width={"40%"} align={"left"} label={row.subject} />
      <TextCell
        width={"35%"}
        align={"left"}
        label={row?.emailTo}
        subLabel={row?.dateSent?.split("T").join(" ")}
      />
      <TextCell
        width={"20%"}
        align={"left"}
        icon={findEmailStatus(row.status)?.icon}
        label={findEmailStatus(row.status)?.label ?? "N/A"}
      />
    </CustomTableRow>
  );
}
