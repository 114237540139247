import AppTheme from "themes/AppTheme";
import DraftsIcon from "@mui/icons-material/Drafts";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import TranslationItem from "models/TranslationItem";

enum EmailStatus {
  PREPARED = "PREPARED",
  SUCCEED = "SUCCEED",
  FAILED = "FAILED",
}

const allEmailStatus: TranslationItem[] = [
  {
    value: EmailStatus.PREPARED,
    label: "Paruoštas",
    icon: <DraftsIcon sx={{ color: AppTheme.palette.primary.main }} />,
  },
  {
    value: EmailStatus.SUCCEED,
    label: "Išsiųstas",
    icon: <DoneIcon sx={{ color: AppTheme.palette.success.main }} />,
  },
  {
    value: EmailStatus.FAILED,
    label: "Klaida",
    icon: <ErrorIcon sx={{ color: AppTheme.palette.error.main }} />,
  },
];

function findEmailStatus(value?: string) {
  return allEmailStatus?.find((status) => value && status?.value === value);
}

export { allEmailStatus, findEmailStatus };
