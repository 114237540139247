import "./LoginPage.css";
import "components/field/Field.css";
import * as yup from "yup";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import PaTextField from "components/field/PaTextField";
import GoogleLoginIconButton from "./thirdParties/GoogleLoginIconButton";
import FacebookLoginIconButton from "./thirdParties/FacebookLoginIconButton";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CustomSpinner from "components/display/CustomSpinner";
import { toast } from 'react-toastify';
import { createApi } from "util/ApiCreator";
import { AuthController } from "api/main/AuthController";

export default function LoginPage() {
  const theme = useTheme();
  const authOldAPI = useRef(createApi("authold") as AuthController);
  const [loading, setLoading] = useState<boolean>(false);
  const [letterSent, setLetterSent] = useState<boolean>(false);
  const [magic] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const notify = () => toast.success('Prisijungimo nuoroda išsiųsta elektroniniu paštu', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

  async function login(values: any) {
    if (loading) return;

    setLoading(true);
    try {
      if (magic) {
        await authOldAPI.current.sendMagicLink({
          email: values.email.toLowerCase(),
        });
        setLetterSent(true);
        notify()
      }

    } catch (e: any) {
      const message = e?.response?.data ?? "Failed, please try again.";
      setError(message);
    }
    setLoading(false);
  }

  function getButtonLabel() {
    if (letterSent) {
      return "Siųsti prisijungimo nuorodą dar kartą";
    } else if (magic) {
      return "Gauti prisijungimo nuorodą į el. paštą";
    }
    return "Log in";
  }

  return (
    <div className={"loginPage-outer-container"}>
      <Box>
        <img
          alt={"Vartotojų aljanso paveiksliukas"}
          style={{ height: "180px", flex: "1" }}
          src={process.env.PUBLIC_URL + "/logo.png"}
        />
      </Box>

      <div className={"loginPage-main-container"}>
        <Box style={{ margin: "1rem 0" }}>
          <Alert severity="info">
            <AlertTitle style={{fontWeight: 700}}>Dėmėsio!</AlertTitle>
            Jau galite pateikti prašymus tapti grupės nariu! <b style={{color: "red"}}>
              Šiuo metu registruotis tapti grupės nariu nebegalima. Prie sistemos galite prisijungti savo el. pašto adresu.</b>
          </Alert>
        </Box>

        <div className={"welcome-title"}>
        <div style={{lineHeight: 1.5}}>
          <h3>

            <b><i>Vartotojai, prisijungę prie Vartotojų aljanso inicijuoto grupės ieškinio dėl vairavimo
              mokyklų karteliniu susitarimu padarytos žalos atlyginimo, švenčia pergalę. Vairavimo
              mokyklos sureagavo į Vartotojų aljanso pateiktą pretenziją ir patenkino vartotojų
              reikalavimus bylai nepasiekus teismo.</i></b>
          </h3>
          <p>
            Konkurencijos taryba 2018 metų pabaigoje paskelbė, kad 26 vairavimo mokyklos bei
            jas vienijanti Lietuvos vairuotojų mokymo ir kvalifikacijos kėlimo mokyklų asociacija
            (LVMA) derino įkainius už vairavimo kursus Vilniuje, Kaune bei Jurbarke. Dėl to vartotojai,
            2016-2018 metais lankę vairavimo kursus šiose mokyklose, buvo priversti mokėti nelegaliai
            padidintą kainą už paslaugas ir patyrė nuostolių. Taryba, įvertinus konkrečias aplinkybes,
            vairavimo mokykloms skyrė baudas, taip pat 10-čiai vairavimo mokyklų vadovų buvo
            pritaikytos asmeninės atsakomybės priemonės. Tačiau šiame procese vartotojams patirti
            nuostoliai nebuvo atlyginti.
          </p>
          <p>Vartotojų aljansas inicijavo grupės ieškinį, kad nukentėjusiems asmenims būtų grąžinti
            už vairavimo kursus permokėti pinigai. Surinkus grupę vairavimo mokykloms buvo pateikta
            pretenzija ir nustatytas terminas nuostolių atlyginimui. Jei iki termino pabaigos su vairavimo
            mokyklomis nebūtų susitarta, Vartotojų aljansas teiktų grupės ieškinį teismui. Tačiau to
            neprireikė – visos vairavimo mokyklos (išskyrus vieną, kuriai vykdoma likvidavimo
            procedūra) patenkino vartotojų reikalavimus.</p>
          <p>
            Vartotojų aljansas – organizacija, jungianti aktyviausius Lietuvoje vartotojų teisių
            gynėjus, Europos vartotojų organizacijos BEUC narė, ilgai siekė, kad grupės ieškinys mūsų
            šalyje taptų vartotojams patogia ir naudinga priemone. Prieš porą metų Vartotojų aljansas
            inicijavo pirmąjį Lietuvoje teismą pasiekusį vartotojų grupės ieškinį dėl įmonės UAB „Prime
            Leasing“, valdančios dalinimosi automobiliais platformą “CityBee”, sukeltos žalos nutekinus
            asmenų duomenis. Šios bylos teisminiai procesai vis dar vyksta.</p>
        </div>
          {/* <Typography variant={"h1"}>
            Registruokis ir prisijunk prie grupės ieškinio!
          </Typography>
          <Typography
            variant={"h4"}
            fontWeight={400}
            sx={{ my: 2, textAlign: "justify" }}
          >
            2018 m. gruodžio 20 d. Konkurencijos taryba paskelbė, kad 26
            vairavimo mokymo paslaugas teikiančios mokyklos iš Vilniaus, Kauno
            ir Jurbarko sudarė konkurenciją ribojantį susitarimą (kartelį),
            kuriuo nuo 2016 m. liepos mėn. iki 2018 m. gruodžio 20 d. derino
            įkainius už vairavimo mokymo paslaugas.{" "}
            <a
              target="_blank"
              href="https://kt.gov.lt/uploads/docs/docs/3705_2de8c4a97568bfd851c2746d0f8b23f4.pdf"
              rel="noopener noreferrer"
            >
              Pilną Konkurencijos tarybos sprendimą galite perskaityti čia.
            </a>
          </Typography>

          <Typography
            variant={"h4"}
            fontWeight={400}
            sx={{ mb: 2, textAlign: "justify" }}
          >
            Jeigu Jūs lankėte vairavimo kursus minėtose mokyklose tarp 2016 m.
            liepos mėn. ir 2018 m. gruodžio 20 d. Jūs galimai už kursus mokėjote
            padidintą kainą ir turite teisę gauti žalos atlyginimą.
          </Typography> */}
        </div>

        {/* <Formik
          initialValues={getInitialValues()}
          validationSchema={getSchemaValidation()}
          onSubmit={(values: any) => login(values)}
        >
          {(formik: FormikProps<any>) => (
            <div className={"input-form"}>
              {error && (
                <Box className="paTextField-error-container">
                  <ErrorRoundedIcon className="paTextField-error-icon" />
                  <Typography
                    variant="subtitle"
                    color={theme.palette.error.main}
                  >
                    {error}
                  </Typography>
                </Box>
              )}
              <PaTextField
                label={"El. paštas"}
                name={"email"}
                type={"email"}
                autoFocus
                fullWidth
                hideError
              />
              <Button
                variant={"contained"}
                size={"large"}
                type={"submit"}
                onClick={formik.submitForm}
                style={{ padding: "1rem 0" }}
                fullWidth
              >
                {loading && (
                  <CustomSpinner
                    label={""}
                    size={"small"}
                    color={"white"}
                    noPadding
                  />
                )}
                {!loading && getButtonLabel()}
              </Button>
            </div>
          )}
        </Formik> */}
        {/* <Divider>arba</Divider>
        <div className={"login-using-third-party"}>
          <GoogleLoginIconButton />
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          width: "calc(100% - 2rem)",
          color: "#778ea8",
          justifyContent: "space-between",
          borderTop: "#c8d8e9 solid 2px",
          marginTop: "auto",
          padding: "1rem",
        }}
      >
        <div>© Vartotojų aljansas {new Date().getFullYear()}</div>
        <div style={{fontSize: "12px", padding: "0 6px"}}>PROJEKTĄ FINANSUOJA: Teisingumo ministerija ir Europos vartotojų organizacija BEUC</div>
        <div>
          <a
            target="_blank"
            href={process.env.PUBLIC_URL + "/privatumo_pranesimas.pdf"}
          >
            Privatumo pranešimas
          </a>
        </div>
      </div>
    </div>
  );
}

function getSchemaValidation() {
  return yup.object({
    email: yup.string().email("El. paštas yra neteisingo formato"),
  });
}
function getInitialValues() {
  return { email: "" };
}
