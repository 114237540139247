import { RoleNames } from "models/auth/RoleNames";
import moment from "moment";
import InputValidation from "../models/validation/InputValidation";

function hasRole(role: RoleNames, roles?: string[]): boolean {
  return (
    roles !== undefined && roles.some((userRole) => userRole === role)
  );
}

const customParseInt = (
  number?: number | string | null,
  defaultReturn?: number,
): number | undefined => {
  if (!number || number === "") {
    return defaultReturn;
  } else if (typeof number === "string") {
    return parseInt(number, 10);
  } else {
    return number;
  }
};

const isNumeric = (value?: string): boolean => {
  const maybeFloat = parseFloat(value ?? "");
  return (
    value !== undefined &&
    !isNaN(maybeFloat) &&
    isFinite(maybeFloat) &&
    String(maybeFloat).length === value.length
  );
};

const getDateTime = (
  date?: moment.Moment,
  firstDay?: boolean,
): string | undefined => {
  if (!date) return undefined;

  const dateToFormat = !firstDay ? moment(date) : moment(date).startOf("month");
  return dateToFormat.format("YYYY-MM-DDTHH:mm:ss");
};

function findValue(inputString: any, name: string): any {
  const validations = inputString.validations as InputValidation[];
  return findValidationValue(validations, name);
}

function findValidationValue(
  validations: InputValidation[],
  name: string,
): any {
  return validations.find(
    (validation: InputValidation) => validation?.type === name,
  )?.value;
}

function findDateValue(
  inputString: any,
  name: string,
): moment.Moment | undefined {
  const value = findValue(inputString, name);
  return value ? moment(value) : undefined;
}

function capitaliseFirstLetter(text: string): string {
  return text.replace(/^\w/, (c) => c.toUpperCase());
}

function getTrimmedText(text: string, numberOfCharsToShow: number): string {
  return (
    capitaliseFirstLetter(text.slice(0, numberOfCharsToShow)) +
    (text.length <= numberOfCharsToShow ? "" : "...")
  );
}

export {
  hasRole,
  customParseInt,
  isNumeric,
  getDateTime,
  findValue,
  findValidationValue,
  findDateValue,
  capitaliseFirstLetter,
  getTrimmedText,
};
