import { CircularProgress, Typography, useTheme } from "@mui/material";
import React from "react";
import "./Display.css";

type TextVariant =
  | "h4"
  | "body"
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h5"
  | "h6";

interface Props {
  size?: "large" | "medium" | "small";
  label?: string;
  withoutOuterDiv?: boolean;
  textVariant?: TextVariant;
  color?: string;
  noPadding?: boolean;
}

export default function CustomSpinner(props: Props) {
  const {
    size = "medium",
    label = "Gauname Jūsų duomenis... Prašome palaukti...",
    withoutOuterDiv = false,
    textVariant = "h4",
    color,
    noPadding,
  } = props;

  const theme = useTheme();

  const Spinner = (
    <CircularProgress
      size={getSpinnerSize(size)}
      style={{
        color: color ?? theme.palette.primary.main,
      }}
    />
  );

  return withoutOuterDiv ? (
    Spinner
  ) : (
    <div
      className={"display-loading-icon-style"}
      style={{ paddingTop: noPadding ? "0px" : `${getSpinnerSize(size)}px` }}
    >
      {label !== "" && (
        <Typography variant={textVariant} fontWeight={600}>
          {label}
        </Typography>
      )}
      {Spinner}
    </div>
  );
}

function getSpinnerSize(size: "large" | "medium" | "small"): number {
  switch (size) {
    case "large":
      return 75;
    default:
    case "medium":
      return 50;
    case "small":
      return 25;
  }
}
