/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SigningMarkIdResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class IdentificationController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Fetch Smart-ID authentication data
   *
   * @tags identification-controller
   * @name PollSmartId
   * @request GET:/api/v1/identification/login/smart-id
   */
  pollSmartId = (
    query: {
      sessionId: string;
      /** @format int64 */
      stepExecutionId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<SigningMarkIdResponse, any>({
      path: `/api/v1/identification/login/smart-id`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Log-in with Smart-ID authentication
   *
   * @tags identification-controller
   * @name InitSmartIdLogin
   * @request POST:/api/v1/identification/login/smart-id
   */
  initSmartIdLogin = (
    query: {
      personCode: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v1/identification/login/smart-id`,
      method: "POST",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Fetch Mobile-ID authentication data
   *
   * @tags identification-controller
   * @name PollMobileId
   * @request GET:/api/v1/identification/login/mobile-id
   */
  pollMobileId = (
    query: {
      sessionId: string;
      /** @format int64 */
      stepExecutionId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<SigningMarkIdResponse, any>({
      path: `/api/v1/identification/login/mobile-id`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Log-in with Mobile-ID authentication
   *
   * @tags identification-controller
   * @name InitMobileIdLogin
   * @request POST:/api/v1/identification/login/mobile-id
   */
  initMobileIdLogin = (
    query: {
      personCode: string;
      phone: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v1/identification/login/mobile-id`,
      method: "POST",
      query: query,
      format: "json",
      ...params,
    });
}
