import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { updateWidth, updateSideMenuFlags } from "./CommonSlice";

export const addWidthListener =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    window.addEventListener("resize", () => {
      dispatch(
        updateWidth({
          innerWidth: window.innerWidth,
          outerWidth: window.outerWidth,
        }),
      );
    });
  };

export const changeSideMenuFlags =
  (
    isOpen?: boolean,
    isHidden?: boolean,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(
      updateSideMenuFlags({
        sideMenuIsHidden: isHidden,
        sideMenuIsOpen: isOpen,
      }),
    );
  };
