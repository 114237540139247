import "./Layout.css";
import {
  Alert,
  AlertTitle,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import CustomHeader from "components/header/CustomHeader";
import { useAppSelector } from "redux/hooks";
import ReduxUserApi from "redux/user/ReduxUserApi";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ReduxCommonApi from "redux/common/ReduxCommonApi";
import AllRoutes from "constants/AllRoutes";
import { RoleNames } from "models/auth/RoleNames";
import PathItem from "models/route/PathItem";
import { hasRole } from "util/UtilityFunctions";
import EmailIcon from "@mui/icons-material/Email";
import { getCurrentPathItem } from "pages/main/Main";
import { createApi } from "util/ApiCreator";
import { autoBatchEnhancer } from "@reduxjs/toolkit";

export default function AdminLayout() {
  const api = useRef(createApi("account"));

  const common = useAppSelector((state) => state.common);
  const me = useAppSelector((s) => s?.me?.info);
  const navigate = useNavigate();
  const location = useLocation();
  const [shrink, setShrink] = useState<boolean>(false);

  const [items, setItems] = useState<PathItem[]>([]);

  useEffect(() => {
    let naujiroutes = AllRoutes[1].paths
      .filter((item: PathItem) => item?.menuIcon && item?.menuLabel)
      .filter(
        (item: PathItem) =>
          (item.forAdmin && hasRole(RoleNames.ADMIN, me?.roles)) ||
          (item.forUser && hasRole(RoleNames.USER, me?.roles)),
      );
    setItems(naujiroutes);
  }, [me]);

  useEffect(() => {
    ReduxUserApi.getAndSetMe(api);
  }, []);

  const navigateToTarget = (item: PathItem) => {
    navigate(item.path);
    ReduxCommonApi.closeSideMenu();
  };

  useEffect(() => {
    const maybePath = getCurrentPathItem(location.pathname);
    setShrink(maybePath?.path === "/process/execution/:id/:stepIndex");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, me]);

  if (common?.sideMenuIsHidden) {
    return null;
  }

  return (
    <div className={"layout-container"}>
      {me && <CustomHeader />}
      <Box className={"layout-admin-body"}>
        {items && items.length > 0 && (
          <Drawer
            sx={{
              width: "250px",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: "250px",
                zIndex: "0",
                height: common?.sideMenuIsPermanent ? "100%" : undefined,
                boxSizing: common?.sideMenuIsPermanent
                  ? "border-box"
                  : undefined,
                position: "relative",
              },
            }}
            variant={common?.sideMenuIsPermanent ? "permanent" : undefined}
            open={common?.sideMenuIsOpen}
            onClose={() => ReduxCommonApi.closeSideMenu()}
            anchor={"left"}
          >
            <Box
              style={{ width: "auto" }}
              role={"presentation"}
              onClick={() => ReduxCommonApi.closeSideMenu()}
              onKeyDown={() => ReduxCommonApi.closeSideMenu()}
            >
              <List>
                {items.map((item) => (
                  <ListItem
                    key={item.menuLabel}
                    className={"sideMenuItems-paths"}
                  >
                    <ListItemButton
                      className={"sideMenuItems-path"}
                      onClick={() => navigateToTarget(item)}
                    >
                      {item.menuIcon ? item.menuIcon : <EmailIcon />}
                      <div className={"sideMenuItems-path-label"}>
                        <Typography variant="h6">{item.menuLabel}</Typography>
                      </div>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        )}
        <div className={"layout-admin-content"} >
          <Outlet />
        </div>
        {shrink && common?.sideMenuIsPermanent && <div style={{ width: "300px" }}></div>}
      </Box>

      <div
        style={{
          display: "flex",
          width: "calc(100% - 2rem)",
          color: "#778ea8",
          justifyContent: "space-between",
          borderTop: "#c8d8e9 solid 2px",
          marginTop: "auto",
          padding: "1rem",
        }}
      >
        <div>© Vartotojų aljansas {new Date().getFullYear()}</div>
        <div style={{fontSize: "12px", padding: "0 6px"}}>PROJEKTĄ FINANSUOJA: Teisingumo ministerija ir Europos vartotojų organizacija BEUC</div>

        <div>
          <a
            target="_blank"
            href={process.env.PUBLIC_URL + "/privatumo_pranesimas.pdf"}
          >
            Privatumo pranešimas
          </a>
        </div>
      </div>
    </div>
  );
}
