import {
  CustomTableHeadRow,
  CustomTableRow,
  TextCell,
} from "@recodin/fe-components";
import { AccountResponse } from "api/auth/data-contracts";
import { translateRoleByName } from "models/auth/RoleNames";
import { useNavigate } from "react-router-dom";

export function AccountTableHeadRow() {
  return (
    <CustomTableHeadRow
      cells={[
        {
          label: "Asmens informacija",
          width: "60%",
          align: "left",
        },
        {
          label: "Rolės (sistemoje)",
          width: "40%",
          align: "left",
        },
      ]}
    />
  );
}

interface AccountTableRowProps {
  row: AccountResponse;
}
export function AccountTableRow({ row }: AccountTableRowProps) {
  const navigate = useNavigate();
  function handleRowClick() {
    navigate(`/account/${row?.id}`);
  }
  return (
    <CustomTableRow onClick={handleRowClick}>
      <TextCell width={"60%"} align={"left"} label={`${row?.email}`} />
      <TextCell
        width={"40%"}
        align={"left"}
        label={
          !row?.roles || row?.roles.length === 0
            ? "N/A"
            : row?.roles
                ?.map((role: string) => translateRoleByName(role))
                ?.join(", ")
        }
      />
    </CustomTableRow>
  );
}
