import PathGroup from "models/route/PathGroup";
import CommonConstants from "./CommonConstants";
import PublicLayout from "pages/layout/PublicLayout";
import AdminLayout from "pages/layout/AdminLayout";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import GroupIcon from "@mui/icons-material/Group";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailIcon from "@mui/icons-material/Email";
import PaymentsIcon from "@mui/icons-material/Payments";
import HomePageLoggedOff from "pages/homepage/HomePageLoggedOff";
import ProcessPage from "pages/process/Process";
import ProcessList from "pages/process/ProcessList";
import FileList from "pages/file/FileList";
import AccountList from "pages/account/AccountList";
import AccountPage from "pages/account/AccountPage";
import ProcessExecutionList from "pages/processexecution/ProcessExecutionList";
import ProcessExecution from "pages/processexecution/ProcessExecution";
import LoginPage from "pages/login/LoginPage";
import MagicLoginHandler from "pages/login/MagicLoginHandler";
import RegistrationFinalizationPage from "pages/login/RegistrationFinalizationPage";
import EmailTemplatePage from "pages/emailtemplate/EmailTemplatePage";
import EmailPage from "pages/email/EmailPage";
import BankAccountPage from "pages/bankaccount/BankAccountPage";
import GavelIcon from "@mui/icons-material/Gavel";
import MessagesListPage from "pages/MessagesListPage";
import MessagePage from "pages/MessagePage";
import ProcessExecutionAdminPage from "pages/processexecution/admin/ProcessExecutionAdminPage";
import ProcessExecutionAdminList from "pages/processexecution/admin/ProcessExecutionAdminList";
import { Navigate } from "react-router-dom";
const AllRoutes: PathGroup[] = [
  {
    label: "Vieši",
    layout: <PublicLayout />,
    paths: [
      {
        path: CommonConstants.loggedOffHomepage,
        page: <HomePageLoggedOff />,
        forPublic: true,
      },
      {
        path: CommonConstants.loginPathName,
        page: <LoginPage />,
        forPublic: true,
      },
      // {
      //   path: CommonConstants.signInPathName,
      //   page: <SignInPage />,
      //   forPublic: true,
      // },
      {
        path: "/login/magic",
        page: <MagicLoginHandler />,
        forPublic: true,
      },
      {
        path: "*",
        page: <Navigate to={CommonConstants.loginPathName} replace />,
        forPublic: true,
      }
    ],
  },
  {
    label: "Prisijungę",
    layout: <AdminLayout />,
    paths: [
      {
        path: "/account/me",
        page: <AccountPage />,
        isLink: true,
        forAdmin: true,
        forUser: true,
      },
      {
        path: CommonConstants.loggedInHomepage,
        // page: <HomePageLoggedIn />,
        page: <ProcessExecutionList />,
        forAdmin: true,
        forUser: true,

        menuLabel: "Ieškiniai",
        menuIcon: <GavelIcon />,
      },
      // {
      //   path: CommonConstants.registrationFinalizationPathName,
      //   page: <RegistrationFinalizationPage />,
      //   forAdmin: true,
      //   forUser: true,
      // },
      {
        path: "/process",
        page: <ProcessList />,
        forAdmin: true,

        menuLabel: "Procesai",
        menuIcon: <AccountTreeIcon />,
      },
      {
        path: "/process/new",
        page: <ProcessPage />,
        forAdmin: true,
      },
      {
        path: "/process/:id",
        page: <ProcessPage />,
        forAdmin: true,
      },
      // {
      //   path: "/process/execution",
      //   page: <ProcessExecutionList />,
      //   forAdmin: true,
      //   forUser: true,

      //   menuLabel: "Procesų vykdymas",
      //   menuIcon: <ManageHistoryIcon />,
      // },
      {
        path: "/process/execution/:id/:stepIndex",
        page: <ProcessExecution />,
        forAdmin: true,
        forUser: true,
      },
      {
        path: "/files",
        page: <FileList />,
        forAdmin: true,

        menuLabel: "Failai",
        menuIcon: <FolderCopyIcon />,
      },
      {
        path: "/account",
        page: <AccountList />,
        forAdmin: true,

        menuLabel: "Paskyros",
        menuIcon: <GroupIcon />,
      },
      {
        path: "/account/new",
        page: <AccountPage />,
        forAdmin: true,
      },
      {
        path: "/account/:id",
        page: <AccountPage />,
        forAdmin: true,
        forUser: true,
      },
      {
        path: "/laisku-sablonai",
        page: <EmailTemplatePage />,
        forAdmin: true,

        menuLabel: "Laiškų šablonai",
        menuIcon: <MailOutlineIcon />,
      },
      {
        path: "/laiskai",
        page: <EmailPage />,
        forAdmin: true,

        menuLabel: "Laiškai",
        menuIcon: <EmailIcon />,
      },
      {
        path: "/saskaitos",
        page: <BankAccountPage />,
        // forAdmin: true,

        menuLabel: "Banko sąskaitos",
        menuIcon: <PaymentsIcon />,

      },
      {
        path: "/process/execution",
        page: <ProcessExecutionAdminList />,
        forAdmin: true,

        menuLabel: "Visi ieškiniai",
        menuIcon: <GavelIcon />,
      },
      {
        path: "/process/execution/:id",
        page: <ProcessExecutionAdminPage />,
        forAdmin: true,
      },
      {
        path: "/messages-list",
        page: <MessagesListPage />,
        menuLabel: "Pranešimų kūrimas",
        menuIcon: <GavelIcon />,
        forAdmin: true,
        // forUser: true,
      },
      {
        path: "/messages",
        page: <MessagePage/>,
        forAdmin: true,
        forUser: true,
      },
      {
        path: "*",
        page: <Navigate to={CommonConstants.loginPathName} replace />,
        forPublic: true,
      }
    ],
  },
];

export default AllRoutes;
