import { Box, Button, Typography } from "@mui/material";
import { FieldArray } from "formik";
import DataInputForm from "./DataInputForm";
import { FileResponse, InputContent, StepCreateRequest, ValidationTypeResponse } from "api/main/data-contracts";
import {
  MetaInformation,
} from "../Process";

interface Props {
  meta?: MetaInformation;
  step: StepCreateRequest;
  stepIndex: number;
}

export default function DataInputDefinition(props: Props) {
  const { meta, step, stepIndex } = props;

  function getSelectOptions(input: InputContent): string[] {
    let result;
    if (input.type === "FILE") {
      result = meta?.files?.map(
        (file: FileResponse) => `${file?.id}. ${file?.name}`,
      );
    }
    return result ?? [];
  }

  return (
    <FieldArray
      name={`steps.${stepIndex}.stepInformation.inputContent`}
      render={(inputGroupArrayHelper) => (
        <>
          {step?.stepInformation?.inputContent?.map((group: InputContent[], inputGroupIndex: number) => (
            <Box key={inputGroupIndex}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "rgb(247, 248, 252)",
                  marginBottom: "10px",
                }}
              >
                <Typography variant={"h2"}>
                  Laukelių grupės nr. {inputGroupIndex + 1} informacija
                </Typography>
                <Button
                  variant={"outlined"}
                  onClick={() => inputGroupArrayHelper.remove(inputGroupIndex)}
                >
                  Ištrinti grupę
                </Button>
              </Box>

              <FieldArray
                name={`steps.${stepIndex}.stepInformation.inputContent.${inputGroupIndex}`}
                render={(inputHelper) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {group?.map((input: InputContent, inputIndex: number) => (
                        <Box
                          key={inputIndex}
                          style={{
                            borderBottom: "1px solid black",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            padding: "10px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "5px",
                            }}
                          >
                            <Typography variant={"h3"}>
                              Laukelis nr. {inputIndex + 1}
                            </Typography>
                            <Button
                              variant={"outlined"}
                              onClick={() => inputHelper.remove(inputIndex)}
                            >
                              Ištrinti laukelį
                            </Button>
                          </Box>
                          <DataInputForm
                            stepIndex={stepIndex}
                            inputGroupIndex={inputGroupIndex}
                            index={inputIndex}
                            selectOptions={getSelectOptions(input)}
                            inputTypes={meta?.inputTypes ?? []}
                            validatorTypes={getFilteredValidationTypes(
                              meta?.validatorTypes,
                              input.type,
                            )}
                          />
                        </Box>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant={"outlined"}
                        onClick={() =>
                          inputHelper.push(
                            getInputStepInitialValues(
                              undefined,
                              inputGroupIndex,
                              group?.length ?? 0,
                            ),
                          )
                        }
                        fullWidth
                      >
                        Pridėti laukelį
                      </Button>
                    </div>
                  </div>
                )}
              />
            </Box>
          ))}
          <Button
            variant={"outlined"}
            onClick={() =>
              inputGroupArrayHelper.push(getInputGroupInitialValues())
            }
          >
            Prideti naują input grupę
          </Button>
        </>
      )}
    />
  );
}

function getFilteredValidationTypes(
  types?: ValidationTypeResponse[],
  inputType?: string,
): ValidationTypeResponse[] {
  return (
    types?.filter(
      (validation: ValidationTypeResponse) =>
        validation.inputType === inputType || !validation.inputType,
    ) ?? []
  );
}


function getInputGroupInitialValues(
  group?: InputContent[],
): InputContent[] {
  if (!group) return [];
  return group.map((input: InputContent) => getInputStepInitialValues(input));
}

function getInputStepInitialValues(
  inputStep?: InputContent,
  inputGroupIndex?: number,
  inputIndex?: number,
): InputContent {
  
  return {
    name: inputStep?.name ?? `group${inputGroupIndex}field${inputIndex}`,
    label: inputStep?.label ?? "",
    i18nkey: inputStep?.i18nkey ?? "",
    type: inputStep?.type,
    options: inputStep?.options ?? [],
    selectOptions: inputStep?.selectOptions ?? [],
    validations: inputStep?.validations ?? [],
  };
}
