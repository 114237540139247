import { CreateDialogProps } from "@recodin/fe-components";
import FileUploadForm from "./FileUploadForm";

export function getCreateProps(
  handleSave: (selectedFile: File) => void,
): CreateDialogProps {
  return {
    createButtonLabel: "įkelti failą",
    headerText: "Naujo failo pridėjimas",
    contentTextFor: "failą",
    EditForm: <FileUploadForm handleSave={handleSave} />,
  };
}
