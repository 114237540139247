import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "api/auth/data-contracts";

export interface UserState {
  info?: AccountResponse;
}

const initialState: UserState = {
  info: undefined,
};

const userSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    setMe: (
      state: UserState,
      action: PayloadAction<{ info?: AccountResponse }>,
    ) => {
      state.info = action.payload.info;
    },
    logOff: (state: UserState) => {
      state.info = undefined;
    },
  },
});

export const { setMe, logOff } = userSlice.actions;
export default userSlice.reducer;
