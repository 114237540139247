function getBaseUrl() {
  if (process.env.NODE_ENV === "production") {
    return "https://vairavimokartelis.lt";
  }
  return "http://localhost:3000";
}

function getFacebookClientId() {
  if (process.env.NODE_ENV === "production") {
    return "790282379002115";
  }
  // testing app, which is a clone of prod app
  return "584522536998437";
}

const CommonConstants = {
  loggedInHomepage: "/home",
  loggedOffHomepage: "/",
  loginPathName: "/login",
  signInPathName: "/sign-up",
  minPermanentSideMenuWidth: 1000,
  apiBaseUrl: getBaseUrl(),
  facebookClientId: getFacebookClientId(),
  googleClientId:
    "639858748390-dvbtd7k8crfcdabhue4iggnsieepehf9.apps.googleusercontent.com",
};

export default CommonConstants;
