import { Box, Typography, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import CustomSpinner from "components/display/CustomSpinner";
import PaTextField from "components/field/PaTextField";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import MobileId from "assets/mobile.svg";
import SmartId from "assets/smart-id.svg";
import * as yup from "yup";
import { isNumeric } from "util/UtilityFunctions";
import TimerComponent from "./SmartTimer";
import { useAppSelector } from "redux/hooks";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createApi } from "util/ApiCreator";
import { SigningController } from "api/main/SigningController";
import CloseIcon from '@mui/icons-material/Close';
import { IdentificationController } from "api/main/IdentificationController";

const REFRESH_RATE_MILLI_SECONDS = 3000;

interface Props {
    type: 'PIN1' | 'PIN2';
    isConsent?: boolean;
    stepExecutionId: number;
    refresh: () => Promise<void>;
    handleSuccess: () => void;
    handleClose: () => void;
    open: boolean;
}

interface BoxConfigI {
    loadingCode?: string;
    error?: string;
    mode:
    | "CHOOSE"
    | "CONSENT"
    | "SMART_ID"
    | "MOBILE_ID"
    | "SUCCESS"
    | "ERROR"
    | "LOADING"
    | "INPUT";
    chosen: "SMART_ID" | "MOBILE_ID";
}

export default function VerySmartMarkId(props: Props) {

    useEffect(() => {
        setDefaultConfig();

    }, [])

    const setDefaultConfig = () => {
        if (props.isConsent) {
            setBoxConfig({
                error: undefined,
                mode: "CONSENT",
                chosen: "SMART_ID",
            });
        } else {
            setBoxConfig({
                error: undefined,
                mode: "CHOOSE",
                chosen: "SMART_ID",
            });
        }
    }

    const identificationApi = useRef(createApi("identification") as IdentificationController);
    const signingApi = useRef(createApi("signing") as SigningController);
    const me = useAppSelector((s) => s.me.info);

    const handleClose = async () => {
        if (boxConfig.mode === "SUCCESS") {
            props.handleSuccess();
            props.handleClose();
        }

        if (boxConfig.mode === "LOADING") {
            return;
        }

        setDefaultConfig();
        props.handleClose();
    };

    const [controlCode, setControlCode] = useState<string>();
    const [personCode, setPersonCode] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    const [boxConfig, setBoxConfig] = useState<BoxConfigI>({
        error: undefined,
        mode: "CHOOSE",
        chosen: "SMART_ID",
    });

    function setModeType(chosen: "SMART_ID" | "MOBILE_ID"): void {
        setBoxConfig({ ...boxConfig, chosen: chosen });
    }

    function setMode(
        mode:
            | "CHOOSE"
            | "CONSENT"
            | "SMART_ID"
            | "MOBILE_ID"
            | "SUCCESS"
            | "ERROR"
            | "LOADING"
    ): void {
        setBoxConfig({ ...boxConfig, mode: mode });
    }

    const handleSubmit = async (obj: any) => {
        setMode("LOADING");
        setPersonCode(obj?.personCode);
        setPhone(obj?.phone);
    };

    const getContent = () => {
        if (boxConfig.mode === "CONSENT") {
            return <Box>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"large"}
                    fullWidth
                    style={{ padding: "0.5rem 1rem" }}
                    type={"submit"}
                    onClick={() => handleSubmit({},)}
                >
                    Patvirtinu, kad perskaičiau dokumentą ir su juo susipažinau

                </Button>
            </Box>
        }

        if (boxConfig.mode === "CHOOSE") {
            return (
                <Box>
                    <div className="main-subtitle">
                        <Typography variant={"h3"}>
                            {props.type === "PIN1" && <>Pasirinkite tapatybės patvirtinimo būdą</>}
                            {props.type === "PIN2" && <>Pasirinkite pasirašymo būdą</>}
                        </Typography>

                        <IconButton
                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </IconButton>

                    </div>
                    <div className="payment-cards">
                        <div
                            className={`payment-card ${boxConfig.chosen === "SMART_ID" ? "payment-card-selected" : ""
                                }`}
                            onClick={() => { setModeType("SMART_ID") }}
                        >
                            <img src={SmartId} alt="Smart-ID" />
                            <div className="main-subtitle card-subtitle" style={{textAlign: "center"}}>
                                {props.type === "PIN1" && <>Identifikacija su SMART-ID programėle</>}
                                {props.type === "PIN2" && <>Pasirašymas su SMART-ID programėle</>}
                            </div>
                        </div>
                        <div
                            className={`payment-card ${boxConfig.chosen === "MOBILE_ID" ? "payment-card-selected" : ""
                                }`}
                            onClick={() => { setModeType("MOBILE_ID"); }}
                        >
                            <img src={MobileId} alt="Mobile-ID" />
                            <div className="main-subtitle card-subtitle-selected" style={{textAlign: "center"}}>
                                {props.type === "PIN1" && <>Identifikacija su MOBILE-ID</>}
                                {props.type === "PIN2" && <>Pasirašymas su MOBILE ID</>}
                            </div>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            personCode: me?.personCode,
                            phone: me?.phone,
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={boxConfig.chosen === "MOBILE_ID" ? getValidationSchemaForMobileId() : getValidationSchemaForSmartId()}
                    >
                        {({ isSubmitting }) => (
                            <Form>

                                <Box>
                                    <PaTextField
                                        name={"personCode"}
                                        label={"Asmens kodas"}
                                        fullWidth
                                        required
                                    />
                                    {boxConfig.chosen === "MOBILE_ID" &&
                                        <PaTextField
                                            name={"phone"}
                                            label={"Telefono nr."}
                                            fullWidth
                                            required
                                        />}
                                </Box>
                                <Box>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        size={"large"}
                                        fullWidth
                                        style={{ padding: "0.5rem 1rem" }}
                                        disabled={isSubmitting}
                                        type={"submit"}
                                    >
                                        {props.type === "PIN1" && <>Patvirtinti tapatybę</>}
                                        {props.type === "PIN2" && <>Pradėti pasirašymą</>}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>

                </Box>
            );
        }

        if (boxConfig.mode === "SUCCESS") {
            return <>

                <IconButton
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CheckCircleIcon
                        sx={{
                            fontSize: 200,
                            color: 'success.main'
                        }}
                    />
                    <Typography variant="h2" align="center">
                        {props.type === "PIN1" && <>Jūs sėkmingai atlikote tapatybės patvirtinimą!</>}
                        {props.type === "PIN2" && <>    Dokumentas sėkmingai pasirašytas!</>}
                    </Typography>

                    <Button variant={"contained"} onClick={() => handleClose()}>Tęsti toliau</Button>
                </Box>
            </>
        }

        if (boxConfig.mode === "LOADING") {
            return <>
                {props.isConsent && <Box>
                    <Typography variant="h1" align="center">
                        Užklausa vykdoma, prašome palaukti
                    </Typography>
                    <CustomSpinner
                        label={"Kraunasi..."}
                    />
                </Box>}
                {!props.isConsent && (props.type === "PIN2" || props.type === "PIN1") && <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                >
                    <Typography variant="h2" align="center">
                        Įsitikinkite, kad kodas pateiktas žemiau atitinka kodą esantį jūsų įrenginyje. Atitikus kodui, įveskite s{props.type}
                    </Typography>

                    <Typography variant="h1" align="center">
                        Kodas: {controlCode ? controlCode : <>kraunasi...</>}
                    </Typography>

                    <Typography variant="body" align="center">
                        <div>kodas nebegalios po: <TimerComponent />s</div>
                    </Typography>
                    <CustomSpinner
                        label={"Laukiama Jūsų patvirtinimo"}
                    />

                    {!controlCode && <Typography variant="h6" align="center" color={"red"} style={{marginTop: "2rem"}}> 
                        SMART_ID atveju, kodas pasirodys tik atvėrus SMART_ID programėlę
                    </Typography>}
                </Box>}
            </>
        }

        if (boxConfig.mode === "ERROR") {
            return <>
                <Box>Įvyko klaida, prašome pabandyti iš naujo</Box>
                <IconButton
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
            </>
                ;
        }

        return <>nieko nevyksta, nezinau tokio mode</>
    };

    useEffect(() => {
        loadingModeInit();
    }, [boxConfig.mode]);

    const getFuncs = () => {
        if (props.type === 'PIN1') {
            if (boxConfig.chosen === 'SMART_ID')
                return { init: identificationApi.current.initSmartIdLogin, poll: identificationApi.current.pollSmartId };

            if (boxConfig.chosen === 'MOBILE_ID')
                return { init: identificationApi.current.initMobileIdLogin, poll: identificationApi.current.pollMobileId };
        }

        if (props.type === 'PIN2') {
            if (boxConfig.chosen === 'SMART_ID')
                return { init: signingApi.current.initiateSigning, poll: signingApi.current.checkStatusOfSigning };

            if (boxConfig.chosen === 'MOBILE_ID')
                return { init: signingApi.current.initiateSigning, poll: signingApi.current.checkStatusOfSigning };
        }
    }

    const loadingModeInit = async () => {
        if (boxConfig.mode === "SUCCESS") {
            if (intervalId) {
                clearInterval(intervalId);
            }
            return;
        }

        if (boxConfig.mode !== "LOADING")
            return;

        let funcs = getFuncs();
        if (!funcs?.init) {
            return;
        }

        if (!props.stepExecutionId)
            return;

        let obj = {
            personCode,
            phone,
            stepExecutionId: props.stepExecutionId,
            type: boxConfig.chosen
        } as any;

        let sessionId = await funcs?.init(obj);

        if (props.isConsent) {
            setMode("SUCCESS");
            // await props.refresh();
            return;
        }

        let id = setInterval(async () => {

            let polls = {
                sessionId,
                stepExecutionId: props.stepExecutionId,
            } as any;

            const response = await funcs?.poll(polls)
                .catch((error: any) => {
                    clearInterval(id);
                    setMode("ERROR");
                });

            if (!response) return;

            setControlCode(response.control_code);

            if (response.status === "ok") {
                clearInterval(id);
                setMode("SUCCESS");
            }

            // await props.refresh();
        }, REFRESH_RATE_MILLI_SECONDS);

        setIntervalId(id);
    }

    return <Dialog open={props.open} onClose={handleClose} style={{ marginTop: "1rem" }} maxWidth={"lg"} fullWidth>
        <DialogContent
            style={{
                display: "flex",
                flexDirection: "column",
            }}>
            {getContent()}
        </DialogContent>
    </Dialog>

}


function createCommonValidationSchema() {
    return yup.object({
        personCode: yup
            .string()
            .length(11, "Asmens kodas privalo turėti 11 skaičių")
            .test(
                "isNumeric",
                "Asmens kodą privalo sudaryti tik skaitmenys",
                (value) => isNumeric(value),
            )
            .test(
                "fisrtNumberMustBeCorrect",
                "Asmens kodas privalo prasidėti 3, 4, 5 arba 6 skaitmenimis",
                (value) =>
                    value?.charAt(0) === "3" ||
                    value?.charAt(0) === "4" ||
                    value?.charAt(0) === "5" ||
                    value?.charAt(0) === "6",
            )
            .required("Asmens kodas yra privalomas"),
    });
}

function getValidationSchemaForSmartId() {
    return createCommonValidationSchema();
}

function getValidationSchemaForMobileId() {
    return yup.object({
        phone: yup
            .string()
            .matches(/^\+[0-9]{11}$/, {
                message:
                    "Numeris turi prasidėti + simboliu ir numerį privalo sudaryti 11 skaitmenų",
                excludeEmptyString: true,
            })
            .required("Numeris yra privalomas"),
        ...createCommonValidationSchema().fields,
    });
}