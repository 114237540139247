/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MessageResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class MessagePublicController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags message-public-controller
   * @name GetAll
   * @request GET:/api/v1/messages
   */
  getAll = (params: RequestParams = {}) =>
    this.request<MessageResponse[], any>({
      path: `/api/v1/messages`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags message-public-controller
   * @name GetCount
   * @request GET:/api/v1/messages/count
   */
  getCount = (params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v1/messages/count`,
      method: "GET",
      ...params,
    });
}
