import "./Main.css";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AllRoutes from "constants/AllRoutes";
import ReduxCommonApi from "redux/common/ReduxCommonApi";
import { useAppSelector } from "redux/hooks";
import PathItem from "models/route/PathItem";
import { AccountResponse } from "api/auth/data-contracts";
import RequireAuth from "setup/RequireAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Don't show side menu for not logged-in user.
const doNotShowSideMenuFor = AllRoutes[0].paths.map((path) => path.path);
const allPathItems = AllRoutes.flatMap((group) => group.paths);

function Main() {
  const me = useAppSelector((state) => state.me);
  const location = useLocation();

  useEffect(() => {
    ReduxCommonApi.addWidthListener();
  }, []);

  useEffect(() => {
    const maybePath = getCurrentPathItem(location.pathname);
    ReduxCommonApi.changeSideMenuIsHidden(hideSideMenu(maybePath, me?.info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, me?.info]);

  return <>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />

    <Routes>

      {AllRoutes.map((group) => (
        <Route key={group.label} element={group.layout}>
          {group.paths
            .filter((item) => !item.isLink)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={
                  <RequireAuth
                    forAdmin={item.forAdmin}
                    forPublic={item.forPublic}
                    forUser={item.forUser}
                  >
                    {item.page}
                  </RequireAuth>
                }
              />
            ))}
        </Route>
      ))}
    </Routes>
  </>
}

const hideSideMenu = (maybePath?: PathItem, me?: AccountResponse): boolean => {
  return !me || !maybePath || doNotShowSideMenuFor.includes(maybePath?.path);
};

export const getCurrentPathItem = (path: string): PathItem | undefined => {
  const getSplitPath = (pathString: string): string[] =>
    pathString.split("/").filter((s) => s !== "");

  for (const item of allPathItems) {
    const splitItemPath = getSplitPath(item.path);
    const splitGivenPath = getSplitPath(path);

    if (splitItemPath.length !== splitGivenPath.length) {
      // Not this one
      continue;
    }

    let found = true;
    for (let i = 0; i < splitItemPath.length; i++) {
      if (
        !splitItemPath.at(i)?.includes(":") &&
        splitItemPath.at(i) !== splitGivenPath.at(i)
      ) {
        // value between dashes / do not match
        found = false;
        break;
      }
    }

    if (found) {
      return item;
    }
  }
};

export default Main;
