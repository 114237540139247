import {
  CustomTableHeadRow,
  CustomTableRow,
  TextCell,
  ActionsCell,
} from "@recodin/fe-components";
import {
  deleteDialogProps,
  editDialogProps,
} from "pages/bankaccount/BankAccountDialogProps";
import { BankAccountResponse } from "api/main/data-contracts";

export function BankAccountTableHeadRow() {
  return (
    <CustomTableHeadRow
      cells={[
        {
          label: " ",
          width: "5%",
        },
        {
          label: "Gavėjo pavadinimas",
          width: "25%",
          align: "left",
        },
        {
          label: "Sąskaitos numeris",
          width: "25%",
          align: "left",
        },
        {
          label: "Valiuta",
          width: "10%",
          align: "left",
        },
        {
          label: "Būsena",
          width: "10%",
          align: "left",
        },
        {
          label: "Veiksmai",
          width: "10%",
        },
      ]}
    />
  );
}

interface BankAccountTableRowProps {
  row: BankAccountResponse;
  handleDelete: (row: BankAccountResponse) => void;
  handleCreateUpdate: (row: BankAccountResponse) => void;
}
export function BankAccountTableRow(props: BankAccountTableRowProps) {
  const { row, handleDelete, handleCreateUpdate } = props;
  return (
    <CustomTableRow>
      <TextCell width={"5%"} label={""} />
      <TextCell width={"25%"} align={"left"} label={row.creditorName} />
      <TextCell width={"25%"} align={"left"} label={row.creditorIban} />
      <TextCell width={"10%"} align={"left"} label={row.currency} />
      <TextCell
        width={"10%"}
        align={"left"}
        label={row.approved ? "Patvirtintas" : "Nepatvirtintas"}
      />
      <ActionsCell
        width={"10%"}
        editDialogProps={editDialogProps(handleCreateUpdate, row)}
        deleteDialogProps={deleteDialogProps(row)}
        handleDelete={() => handleDelete(row)}
      />
    </CustomTableRow>
  );
}
