import React, { useEffect, useRef, useState } from "react";
import {
  ProcessExecutionAdminTableHeadRow,
  ProcessExecutionAdminTableRow,
  translateToLithuanian,
} from "./ProcessExecutionAdminListTableRow";
import { CustomTable } from "@recodin/fe-components";
import { FilterRequest, SelectItem } from "@recodin/fe-components/dist/components/form/FilterForm";
import { SearchRequest } from "@recodin/fe-components/dist/components/form/SearchForm";
import { createApi } from "util/ApiCreator";
import { ProcessExecutionResponse, EmailResponse, StepExecutionResponse } from "api/main/data-contracts";
import { ProcessExecutionPublicController } from "api/main/ProcessExecutionPublicController";
import { ProcessExecutionAdminController } from "api/main/ProcessExecutionAdminController";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { exec } from "child_process";

export interface processExecutionAdminData extends ProcessExecutionResponse {
  email?: string;
}

export default function ProcessExecutionInitiatedList() {
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedStepStatus, setSelectedStepStatus] = useState<string | null>(null);
  const [statuses, setStatuses] = useState<string[] | null>(null);
  const [stepStatuses, setStepStatuses] = useState<string[] | null>(null);
  const [executions, setExecutions] = useState<ProcessExecutionResponse[]>([]);
  const [filteredExecutions, setFilteredExecutions] = useState<ProcessExecutionResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const executionApi = useRef(createApi("processexecutionadmin") as ProcessExecutionAdminController);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setAllStatuses();
    getAllStepStatuses();
  }, [executions]);

  useEffect(() => {
    let filteredData = [...executions];
    console.log(filteredData);

    if (selectedStatus) {
      filteredData = filteredData.filter((e) => e.status === selectedStatus);
    }

    if (selectedStepStatus) {
      console.log(selectedStepStatus);
      filteredData = filteredData.filter((e) => {
        const stepExecutions = e.stepExecutions || [];
        console.log("se: ", stepExecutions)

        return stepExecutions.find((step) => step.name === selectedStepStatus && step.status === 'ACTIVE');
      });
    }

    setFilteredExecutions(filteredData);
  }, [selectedStatus, selectedStepStatus]);

  async function getData() {
    setLoading(true);
    let data = await executionApi.current.findAll();
    setExecutions(data);
    setFilteredExecutions(data);
    setLoading(false);
  }

  function setAllStatuses() {
    const uniqueStatuses: string[] = [];

    executions.forEach((execution: any) => {
      if (execution.status && !uniqueStatuses.includes(execution.status)) {
        uniqueStatuses.push(execution.status);
      }
    });

    setStatuses(uniqueStatuses);
  }

  function getAllStepStatuses() {
    const uniqueStatuses: string[] = [];
    let stepExecutions: any = [];

    executions.forEach((execution: any) => stepExecutions.push(...execution.stepExecutions));

    stepExecutions.forEach((step: any) => {
      if (step.name && !uniqueStatuses.includes(step.name)) {
        uniqueStatuses.push(step.name);
      }
    });

    setStepStatuses(uniqueStatuses);
  }

  function handleStatusChange(event: any) {
    setSelectedStatus(event.target.value);
  }

  function handleStepStatusChange(event: any) {
    setSelectedStepStatus(event.target.value);
  }



  return (
    <>
      <FormControl fullWidth style={{marginBottom: "1rem"}}>
        <InputLabel id="status-select-label">Filtruoti pagal ieškinio statusą</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={selectedStatus || ""}
          label="Filter by Status"
          onChange={handleStatusChange}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {statuses?.map((item) => (
            <MenuItem key={item} value={item}>
              {translateToLithuanian(item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth style={{marginBottom: "1rem"}}>
        <InputLabel id="step-status-select-label">Filtruoti pagal aktyvų žingsnį</InputLabel>
        <Select
          labelId="step-status-select-label"
          id="step-status-select"
          value={selectedStepStatus || ""}
          label="Filter by Step Status"
          onChange={handleStepStatusChange}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {stepStatuses?.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomTable
        data={filteredExecutions || []}
        headerRow={ProcessExecutionAdminTableHeadRow}
        isLoading={loading}
        contentRow={(props: { row: processExecutionAdminData }) => (
          <ProcessExecutionAdminTableRow row={props.row} />
        )}
        showPagination
        disableFiltering
      />
    </>
  );
}
