import * as yup from "yup";
import { DateSchema, NumberSchema, Schema, StringSchema } from "yup";
import moment from "moment";
import { findValue, getDateTime, isNumeric } from "util/UtilityFunctions";
import { IBAN } from "ibankit";
import "yup-phone";

export default function getValidationSchema(inputGroups: any) {
  // Flat map this double array.
  const inputs = [].concat.apply([], inputGroups);
  // Reduce all input validations to a map.
  const validations = inputs.reduce(
    (schema: any, input: any) => ({
      ...schema,
      [input.name]: getInputValidationSchema(input),
    }),
    {},
  );

  return yup.object().shape(validations);
}

function getInputValidationSchema(input: any) {
  // TODO: refactor general validations (REQUIRED, SELECT, for example) into one method
  switch (input.type) {
    case "TEXT":
      return getTextSchema(input);
    case "NUMBER":
      return getNumberSchema(input);
    case "DATE":
      return getDateSchema(input);
    case "FILE":
      return getFileSchema(input);
    default:
      console.warn(
        "Warning: returning default validation schema type for given type:",
        input.type,
      );
      return yup.string();
  }
}

function getTextSchema(input: any): StringSchema<any> {
  let schema = yup.string();
  if (findValue(input, "REQUIRED")) {
    schema = schema.required("Šis laukelis yra privalomas.");
  }
  
  if (findValue(input, "PERSON_CODE_LT")) {
    schema = schema
      .length(11, "Asmens kodas turi būti sudarytas iš 11-os skaitmenų.")
      .test(
        "isNumeric",
        "Asmens kodas turi būti sudarytas tik iš skaitmenų.",
        (value?: string) => isNumeric(value),
      )
      .test(
        "firstNumberMustBeCorrect",
        "Neteisingas asmens kodo pirmasis skaitmuo",
        (value?: string) => value?.at(0) === "3" || value?.at(0) === "4" || value?.at(0) === "5" || value?.at(0) === "6",
      );
  }

  if (findValue(input, "IBAN")) {
    schema = schema.test(
      "isIBANValid",
      "IBAN turi būti egzistuojantis",
      (value?: string) => !!value && IBAN.isValid(value),
    );
  }

  if (findValue(input, "PHONE")) {
    schema = schema.phone(
      "LT",
      true,
      "Tinkami pavyzdžiai: +37061234567 or 861234567",
    );
  }

  if (findValue(input, "SELECT")) {
    schema = schema.oneOf(
      input.selectOptions,
      "Pasirinkite tik vieną iš galimų variantų.",
    );
  }

  let value: number | null;

  value = findValue(input, "MIN_LENGTH");
  if (value) {
    schema = schema.min(
      value,
      `Turi būti sudarytas iš ne mažiau kaip ${value} simbolių.`,
    );
  }

  value = findValue(input, "MAX_LENGTH");
  if (value) {
    schema = schema.max(
      value,
      `Turi būti sudarytas iš ne daugiau kaip ${value} simbolių.`,
    );
  }

  value = findValue(input, "LENGTH");
  if (value) {
    schema = schema.length(value, `Turi būti sudarytas iš ${value} simbolių.`);
  }
  return schema;
}

//   REQUIRED(Boolean.class),
//   MIN(Integer.class),
//   MAX(Integer.class);
function getNumberSchema(input: any): NumberSchema {
  let schema = yup.number();

  if (findValue(input, "REQUIRED")) {
    schema = schema.required("Šis laukelis yra privalomas.");
  }
  if (findValue(input, "SELECT")) {
    schema = schema.oneOf(
      input.selectOptions,
      "Pasirinkite tik vieną iš galimų variantų.",
    );
  }

  let value: number | null;

  value = findValue(input, "MIN");
  if (value) {
    schema = schema.min(value, `Turi būti daugiau arba lygu ${value}.`);
  }

  value = findValue(input, "MAX");
  if (value) {
    schema = schema.max(value, `Turi būti mažiau arba lygu ${value}.`);
  }
  return schema;
}

//   REQUIRED(Boolean.class),
//   MIN_DATE(LocalDateTime.class),
//   MAX_DATE(LocalDateTime.class),
function getDateSchema(input: any): DateSchema {
  let schema = yup.date();
  if (findValue(input, "REQUIRED")) {
    schema = schema.required("Šis laukelis yra privalomas.");
  }
  if (findValue(input, "SELECT")) {
    schema = schema.oneOf(
      input.selectOptions,
      "Pasirinkite tik vieną iš galimų variantų.",
    );
  }

  let value: any;
  
  value = findValue(input, "MIN_DATE");
  if (value) {
    schema = schema.min(
      moment(value),
      `Turi būti ne anksčiau kaip ${getDateTime(value)}.`,
    );
  }

  value = findValue(input, "MAX_DATE");
  if (value) {
    schema = schema.max(
      moment(value),
      `Turi būti ne vėliau kaip ${getDateTime(value)}.`,
    );
  }
  return schema;
}

function getFileSchema(input: any): Schema<any> {
  let schema = yup.array();
  if (findValue(input, "REQUIRED")) {
    schema = schema.required("Šis laukelis yra privalomas.");
  }
  // if (findValue(input, "SELECT")) {
  //   const options = input.selectOptions.map((file: string) => customParseInt(file.split(".")[0]))
  //   schema = schema.oneOf(options, 'Choose one of the given values.');
  // }

  return schema;
}
