import PaSelect from "components/field/PaSelect";
import PaTextField from "components/field/PaTextField";
import { MetaInformation } from "../Process";

interface Props {
  stepIndex: number;
  meta?: MetaInformation;
}

export default function PaymentDefinition(props: Props) {
  const { stepIndex, meta } = props;
  return (
    <>
      <PaSelect
        name={`steps.${stepIndex}.stepInformation.paymentContent.bankAccountId`}
        label={"Banko sąskaita"}
        tooltip={"Pasirinkite banko sąskaitą, į kurią norite gauti mokėjimus"}
        options={
          meta?.bankAccounts?.map((bankAccount) =>
            bankAccount.approved
              ? {
                  value: bankAccount.id,
                  label: bankAccount.creditorIban,
                }
              : {},
          ) ?? []
        }
      />
      <PaTextField
        name={`steps.${stepIndex}.stepInformation.paymentContent.amount`}
        label={"Suma"}
      />
      <PaTextField
        name={`steps.${stepIndex}.stepInformation.paymentContent.description`}
        label={"Paskirtis"}
      />
    </>
  );
}
