import { Box, Button, Dialog, DialogContent, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Formik, FormikContextType, FormikProps, useField, useFormikContext } from "formik";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import "./../field/Field.css";
import PaSelect from "components/field/PaSelect";
import CloseIcon from '@mui/icons-material/Close';
import { handleDownload } from "pages/processexecution/admin/ProcessExecutionAdminPage";
import { hasRole } from "util/UtilityFunctions";
import { RoleNames } from "models/auth/RoleNames";
import { useAppSelector } from "redux/hooks";

interface FileUploadButtonProps {
  name: string;
  label?: string;
  title?: string;
  onUpload?: (file: File[], type: string) => void;
  multiple?: boolean;
  showNames?: boolean;
  hideError?: boolean;
  disabled?: boolean;
  onDelete?: (id: number) => void;
}

export default function FileUploadButton(props: FileUploadButtonProps) {
  const {
    name,
    title,
    onUpload,
    multiple = false,
    hideError,
    label = "Įkelti failą",
    disabled,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const me = useAppSelector((s) => s?.me?.info);

  const theme = useTheme();
  const formikContext: FormikContextType<any> = useFormikContext();
  const [, meta] = useField(name);

  async function handleFileUpload(event: any, type: string) {
    const current = event.currentTarget;
    const input: (File | null)[] = multiple ? Array.from(current.files) : [current.files?.item(0)];
    const cleanInput: File[] = input
      .filter((file) => file)
      .map((file) => file as File);

    if (onUpload) {
      await onUpload(cleanInput, type);
      setOpen(false);
    } else {
      formikContext.setFieldValue(name, cleanInput);
    }
  }

  function getFiles() {
    let values = formikContext?.values;
    let arr = values[name];
    if (!arr) {
      return [];
    }

    return arr;
  }

  return <div style={{ display: "flex", flexDirection: "column", marginBottom: "1.5rem" }}>
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Typography variant={"h4"}>{title}</Typography>
      <Button variant="contained" onClick={() => setOpen(true)} disabled={disabled}>Įkelti failus</Button>
    </div>
    {!hideError && meta.touched && Boolean(meta.error) && (
      <Box className="paTextField-error-container">
        <ErrorRoundedIcon className="paTextField-error-icon" />
        <Typography
          variant="subtitle"
          color={theme.palette.error.main}
          align="left"
        >
          {meta.error}
        </Typography>
      </Box>
    )}
    <>
      <Formik
        initialValues={{
          type: '',
        }}
        onSubmit={(data: any) => console.log("on submit")}
      >
        {(formik: FormikProps<any>) => <>
          <Dialog open={open} onClose={() => { setOpen(false); console.log("reset"); formik.resetForm() }} maxWidth={"lg"} fullWidth>

            <DialogContent >

              <PaSelect
                name={`type`}
                label={"Įkeliamo failo tipas"}
                tooltip={"Pasirinkite, kas atliks šį etapą."}
                options={
                  ['Mokėjimo dokumentas', 'Vairavimo kursų sutartis', 'Kitas dokumentas'].map((executor) => ({
                    value: executor,
                    label: executor,
                  })) ?? []
                }
              />
              <Button variant="contained" component="label" disabled={!formik.values.type}>
                {label}
                <input
                  id="selectedFile"
                  name="selectedFile"
                  type="file"
                  onChange={(e) => handleFileUpload(e, formik.values.type)}
                  multiple={multiple}
                  accept={".pdf,.jpg,.png,.doc,.docx"}
                  disabled={!formik.values.type}
                  hidden
                />
              </Button>
            </DialogContent>
          </Dialog></>}


      </Formik>
    </>
    {getFiles()?.length > 0 && <>
      <Typography variant="h6" fontWeight={700}>Įkelti failai</Typography>

      {getFiles().map((currFile: any) => <Box display="flex" justifyContent={"space-between"}>
        <Typography
          key={currFile.id}
          variant={"h5"}
          fontWeight={400}
        >
          {currFile.id}. {currFile.name} {hasRole(RoleNames.ADMIN, me?.roles) && <Button variant="contained" onClick={async () => handleDownload(currFile.id)}>Parsisiųsti</Button>}
        </Typography>
        {!disabled && <CloseIcon onClick={() => formikContext.setFieldValue(name, formikContext.values[name].filter((file: any) => file.id !== currFile.id))}
        />}
      </Box>
      )}

    </>}
  </div>

}
