import { store } from "../store";
import { addWidthListener, changeSideMenuFlags } from "./CommonActions";

const ReduxCommonApi = {
  addWidthListener: () => store.dispatch(addWidthListener()),
  closeSideMenu: () => store.dispatch(changeSideMenuFlags(false)),
  openSideMenu: () => store.dispatch(changeSideMenuFlags(true)),
  changeSideMenuIsHidden: (isHidden: boolean) =>
    store.dispatch(changeSideMenuFlags(undefined, isHidden)),
};

export default ReduxCommonApi;
