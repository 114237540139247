import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useContext, useState } from "react";
import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import { EmailTemplateResponse } from "api/main/data-contracts";
import PaTextField from "components/field/PaTextField";
import { allEmailTemplateTypes } from "./EmailTemplateTypeTranslation";

interface Props {
  emailTemplate?: EmailTemplateResponse;
  handleEdit: (emailTemplate: EmailTemplateResponse) => void;
}

export default function EmailTemplateEditForm(props: Props) {
  const { emailTemplate = getDefaultValues(), handleEdit } = props;

  const [preview, setPreview] = useState<boolean>(false);
  const { formikFormRef } = useContext(FormContext);
  const allTypes = allEmailTemplateTypes.map((type) => type.value);

  const handleFormikSubmit = (values: any) => {
    handleEdit(values as EmailTemplateResponse);
  };

  return (
    <Formik
      initialValues={emailTemplate}
      validationSchema={validationSchema(allTypes)}
      onSubmit={handleFormikSubmit}
      innerRef={formikFormRef} // used for submit outside Formik component
    >
      {(formik: FormikProps<any>) => (
        <>
          {/* Updates formContext, which is used in outside dialog */}
          <FormContextRefreshConduit />

          <PaTextField label={"Tema"} name={"subject"} required />
          <PaTextField label={"Šablono pavadinimas"} name={"type"} required />

          <PaTextField
            label={"Turinys"}
            name={"body"}
            required
            labelButtonAction={() => setPreview(!preview)}
            labelButtonLabel={preview ? "Kodas" : "Peržiūra"}
            showInstead={
              !preview ? undefined : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: preview ? formik.values.body : undefined,
                  }}
                />
              )
            }
            rows={10}
          />
        </>
      )}
    </Formik>
  );
}

const getDefaultValues = () => ({
  id: undefined,
  subject: "",
  body: "",
  type: null,
});

const validationSchema = (allTypes: string[]) =>
  yup.object({
    type: yup
      .string()
      // .oneOf(allTypes, "Pasirinkite vieną iš nurodytų tipų")
      .required("Laiško tipas yra privalomas.")
      .nullable(),
    subject: yup.string().required("Tema yra privaloma."),
    body: yup.string().required("Turinys yra privalomas."),
  });
