import { store } from "../store";
import { getAndSetMe, logOffMe } from "./UserActions";
import React from "react";
import { AccountController } from "api/auth/AccountController";
import { AuthController } from "api/auth/AuthController";

const ReduxUserApi = {
  getAndSetMe: (apiRef: React.MutableRefObject<AccountController>) =>
    store.dispatch(getAndSetMe(apiRef)),
  logOffMe: (authAPI: React.MutableRefObject<AuthController>) =>
    store.dispatch(logOffMe(authAPI)),
};

export default ReduxUserApi;
