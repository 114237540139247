import CommonConstants from "constants/CommonConstants";
import { RoleNames } from "models/auth/RoleNames";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { hasRole } from "util/UtilityFunctions";

interface Props {
  forAdmin?: boolean;
  forUser?: boolean;
  forPublic?: boolean;
  children: any;
}

export default function RequireAuth(props: Props) {
  const me = useAppSelector((s) => s.me.info);

  if (!me && !props?.forPublic) {
    // If there is no user data, then don't show anything
    return null;
  }

  if (
    (props.forAdmin && hasRole(RoleNames.ADMIN, me?.roles)) ||
    (props.forUser && hasRole(RoleNames.USER, me?.roles)) ||
    props?.forPublic

  ) {
    // If user has sufficient privileges for this page, then show content
    return <>{props.children}</>;
  }

  return <Navigate to={CommonConstants.loginPathName} replace />;
}
