import { combineReducers, configureStore } from "@reduxjs/toolkit";
import CommonSlice from "./common/CommonSlice";
import UserSlice from "./user/UserSlice";

export const rootReducer = combineReducers({
  common: CommonSlice,
  me: UserSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof rootReducer>;
