import "./index.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import AppTheme from "./themes/AppTheme";
import { Provider } from "react-redux";
import Main from "./pages/main/Main";
import { store } from "redux/store";
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider } from "react-facebook";
import CommonConstants from "constants/CommonConstants";
import moment from "moment";

const root = ReactDOM.createRoot(document.getElementById("root")!);

moment.locale("lt");

root.render(
  <GoogleOAuthProvider clientId={CommonConstants.googleClientId}>
    <FacebookProvider appId={CommonConstants.facebookClientId}>
      <BrowserRouter>
        <ThemeProvider theme={AppTheme}>
          <Provider store={store}>
            <Main />
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </FacebookProvider>
  </GoogleOAuthProvider>,
);
