/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ProcessExecutionResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class ProcessExecutionAdminController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Deal done with a driving school
   *
   * @tags process-execution-admin-controller
   * @name DealDone
   * @request PUT:/api/admin/v1/process-execution/dealDone/{processExecutionId}
   */
  dealDone = (processExecutionId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/v1/process-execution/dealDone/${processExecutionId}`,
      method: "PUT",
      ...params,
    });
  /**
   * @description Cancel a process
   *
   * @tags process-execution-admin-controller
   * @name Cancel
   * @request PUT:/api/admin/v1/process-execution/cancel/{processExecutionId}
   */
  cancel = (processExecutionId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/v1/process-execution/cancel/${processExecutionId}`,
      method: "PUT",
      ...params,
    });
  /**
   * @description Fetch all process executions
   *
   * @tags process-execution-admin-controller
   * @name FindAll
   * @request GET:/api/admin/v1/process-execution
   */
  findAll = (params: RequestParams = {}) =>
    this.request<ProcessExecutionResponse[], any>({
      path: `/api/admin/v1/process-execution`,
      method: "GET",
      ...params,
    });
  /**
   * @description Fetch a process execution by id
   *
   * @tags process-execution-admin-controller
   * @name FindById
   * @request GET:/api/admin/v1/process-execution/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<ProcessExecutionResponse, any>({
      path: `/api/admin/v1/process-execution/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * @description Fetch all process executions
   *
   * @tags process-execution-admin-controller
   * @name FindAllForAction
   * @request GET:/api/admin/v1/process-execution/action-admin
   */
  findAllForAction = (params: RequestParams = {}) =>
    this.request<ProcessExecutionResponse[], any>({
      path: `/api/admin/v1/process-execution/action-admin`,
      method: "GET",
      ...params,
    });
}
