import {
  CustomTableHeadRow,
  CustomTableRow,
  TextCell,
  ActionsCell,
} from "@recodin/fe-components";
import { useState } from "react";
import { deleteDialogProps, editDialogProps } from "./EmailTemplateDialogProps";
import { EmailTemplateResponse } from "api/main/data-contracts";
import DangerousHTMLViewTypography from "components/display/DangerousHTMLViewTypography";
import { getTrimmedText } from "util/UtilityFunctions";
import { findEmailTemplateType } from "./EmailTemplateTypeTranslation";

export function EmailTemplateTableHeadRow() {
  return (
    <CustomTableHeadRow
      cells={[
        {
          label: " ",
          width: "5%",
        },
        {
          label: "Tipas",
          width: "30%",
          align: "left",
        },
        {
          label: "Tema",
          width: "55%",
          align: "left",
        },
        {
          label: "Veiksmai",
          width: "10%",
        },
      ]}
    />
  );
}

interface EmailTemplateTableRowProps {
  row: EmailTemplateResponse;
  handleDelete: (row: EmailTemplateResponse) => void;
  handleCreateUpdate: (row: EmailTemplateResponse) => void;
}
export function EmailTemplateTableRow(props: EmailTemplateTableRowProps) {
  const { row, handleDelete, handleCreateUpdate } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <CustomTableRow
      setOpen={setOpen}
      showButtonOnLeftSide
      collapsableItems={
        <DangerousHTMLViewTypography title={row.subject} label={row?.body} />
      }
    >
      <TextCell
        width={"30%"}
        align={"left"}
        label={findEmailTemplateType(row?.type)?.label ?? row?.type}
      />
      <TextCell
        width={"60%"}
        align={"left"}
        label={row.subject}
        subLabel={open || !row.body ? undefined : getTrimmedText(row.body, 50)}
      />
      <ActionsCell
        width={"10%"}
        editDialogProps={editDialogProps(handleCreateUpdate, row)}
        deleteDialogProps={deleteDialogProps(row)}
        handleDelete={() => handleDelete(row)}
      />
    </CustomTableRow>
  );
}
