import { Box, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { FastField, Field, useField } from "formik";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

export interface PaSelectOption {
  label?: any;
  value?: any;
}

interface Props {
  name: string;
  label?: string;
  tooltip?: string;
  options: PaSelectOption[];
  fast?: boolean;
  multiple?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const PaTextArea = ({
  name,
  label,
  tooltip,
  options,
  fast = true,
  multiple = false,
  placeholder,
  disabled,
}: Props) => {
  const [, meta] = useField(name);
  const theme = useTheme();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      {fast && (
        <FastField
          as={Select}
          multiple={multiple}
          name={name}
          title={tooltip}
          placeholder={placeholder}
          disabled={disabled}
        >
          {["", ...options].map((option: any, i) =>
            option === "" ? null : (
              <MenuItem key={i} value={option?.value}>
                {option?.label}
              </MenuItem>
            ),
          )}
        </FastField>
      )}
      {!fast && (
        <Field
          as={Select}
          multiple={multiple}
          name={name}
          title={tooltip}
          placeHolder={placeholder}
          disabled={disabled}
        >
          {options.map((option: any, i) =>
            option === "" ? null : (
              <MenuItem key={i} value={option?.value}>
                {option?.label}
              </MenuItem>
            ),
          )}
        </Field>
      )}
      {meta.touched && Boolean(meta.error) && (
            <Box className="paTextField-error-container" style={{marginTop: "0.5rem"}}>
              <ErrorRoundedIcon className="paTextField-error-icon" />
              <Typography
                variant="subtitle"
                color={theme.palette.error.main}
                align="left"
              >
                {meta.error}
              </Typography>
            </Box>
          )}
    </div>
  );
};

export default PaTextArea;
