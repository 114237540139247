import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setMe, logOff } from "./UserSlice";
import { AccountResponse } from "api/auth/data-contracts";
import React from "react";
import { AccountController } from "api/auth/AccountController";
import { AuthController } from "api/auth/AuthController";

const getAndSetMe =
  (
    accountAPI: React.MutableRefObject<AccountController>,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    accountAPI.current.getMe().then((me: AccountResponse) => {
      dispatch(setMe({ info: me }));
    });
  };

const logOffMe =
  (
    authAPI: React.MutableRefObject<AuthController>,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    authAPI.current.logoff();
    dispatch(logOff());
  };

export { getAndSetMe, logOffMe };
