/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BankAccountCreateRequest, BankAccountResponse, BankAccountUpdateRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BankAccountController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags bank-account-controller
   * @name FindAll
   * @summary Find all bank accounts
   * @request GET:/api/admin/v1/bank-account
   */
  findAll = (params: RequestParams = {}) =>
    this.request<BankAccountResponse[], any>({
      path: `/api/admin/v1/bank-account`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags bank-account-controller
   * @name Update
   * @summary Update bank account
   * @request PUT:/api/admin/v1/bank-account
   */
  update = (data: BankAccountUpdateRequest, params: RequestParams = {}) =>
    this.request<BankAccountResponse, any>({
      path: `/api/admin/v1/bank-account`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags bank-account-controller
   * @name Create
   * @summary Create bank account
   * @request POST:/api/admin/v1/bank-account
   */
  create = (data: BankAccountCreateRequest, params: RequestParams = {}) =>
    this.request<BankAccountResponse, any>({
      path: `/api/admin/v1/bank-account`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags bank-account-controller
   * @name FindById
   * @summary Find bank account by id
   * @request GET:/api/admin/v1/bank-account/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<BankAccountResponse, any>({
      path: `/api/admin/v1/bank-account/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags bank-account-controller
   * @name Delete
   * @summary Delete bank account
   * @request DELETE:/api/admin/v1/bank-account/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/v1/bank-account/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags bank-account-controller
   * @name FindAllCurrencies
   * @summary Fetch all available currencies
   * @request GET:/api/admin/v1/bank-account/currencies
   */
  findAllCurrencies = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/admin/v1/bank-account/currencies`,
      method: "GET",
      ...params,
    });
}
