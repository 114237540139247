/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ProcessCreateRequest, ProcessResponse, ProcessUpdateRequest, ValidationTypeResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ProcessController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Fetch process by its id
   *
   * @tags process-controller
   * @name FindById
   * @request GET:/api/admin/v1/process/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<ProcessResponse, any>({
      path: `/api/admin/v1/process/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * @description Update a process by its id
   *
   * @tags process-controller
   * @name Update
   * @request PUT:/api/admin/v1/process/{id}
   */
  update = (id: number, data: ProcessUpdateRequest, params: RequestParams = {}) =>
    this.request<ProcessResponse, any>({
      path: `/api/admin/v1/process/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Delete a process by its id
   *
   * @tags process-controller
   * @name Delete
   * @request DELETE:/api/admin/v1/process/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/v1/process/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * @description Fetch all processes
   *
   * @tags process-controller
   * @name FindAll
   * @request GET:/api/admin/v1/process
   */
  findAll = (params: RequestParams = {}) =>
    this.request<ProcessResponse[], any>({
      path: `/api/admin/v1/process`,
      method: "GET",
      ...params,
    });
  /**
   * @description Create a process
   *
   * @tags process-controller
   * @name Create
   * @request POST:/api/admin/v1/process
   */
  create = (data: ProcessCreateRequest, params: RequestParams = {}) =>
    this.request<ProcessResponse, any>({
      path: `/api/admin/v1/process`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Fetch all validator types
   *
   * @tags process-controller
   * @name FindAllValidatorTypes
   * @request GET:/api/admin/v1/process/validatortypes
   */
  findAllValidatorTypes = (params: RequestParams = {}) =>
    this.request<ValidationTypeResponse[], any>({
      path: `/api/admin/v1/process/validatortypes`,
      method: "GET",
      ...params,
    });
  /**
   * @description Fetch all step types
   *
   * @tags process-controller
   * @name FindAllStepTypes
   * @request GET:/api/admin/v1/process/steptypes
   */
  findAllStepTypes = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/admin/v1/process/steptypes`,
      method: "GET",
      ...params,
    });
  /**
   * @description Fetch all step executors
   *
   * @tags process-controller
   * @name FindAllStepExecutors
   * @request GET:/api/admin/v1/process/stepexecutors
   */
  findAllStepExecutors = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/admin/v1/process/stepexecutors`,
      method: "GET",
      ...params,
    });
  /**
   * @description Fetch all sign types
   *
   * @tags process-controller
   * @name FindAllSignTypes
   * @request GET:/api/admin/v1/process/signtypes
   */
  findAllSignTypes = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/admin/v1/process/signtypes`,
      method: "GET",
      ...params,
    });
  /**
   * @description Fetch all sign types
   *
   * @tags process-controller
   * @name FindAllNotificationTypes
   * @request GET:/api/admin/v1/process/notificationtypes
   */
  findAllNotificationTypes = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/admin/v1/process/notificationtypes`,
      method: "GET",
      ...params,
    });
  /**
   * @description Fetch all input types
   *
   * @tags process-controller
   * @name FindAllInputTypes
   * @request GET:/api/admin/v1/process/inputtypes
   */
  findAllInputTypes = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/admin/v1/process/inputtypes`,
      method: "GET",
      ...params,
    });
}
