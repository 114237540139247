/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SigningMarkIdResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class SigningController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags signing-controller
   * @name InitiateSigning
   * @request POST:/api/v1/process-execution/signing
   */
  initiateSigning = (
    query: {
      phone?: string;
      personCode?: string;
      /** @format int64 */
      stepExecutionId: number;
      type: "SMART_ID" | "MOBILE_ID";
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v1/process-execution/signing`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags signing-controller
   * @name CheckStatusOfSigning
   * @request GET:/api/v1/process-execution/signing/check
   */
  checkStatusOfSigning = (
    query: {
      /** @format int64 */
      stepExecutionId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<SigningMarkIdResponse, any>({
      path: `/api/v1/process-execution/signing/check`,
      method: "GET",
      query: query,
      ...params,
    });
}
