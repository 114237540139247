import { Field, useFormikContext } from "formik";
import PaAutocompleteInput from "components/field/PaAutocompleteInput";
import PaSelect from "components/field/PaSelect";
import PaTextField from "components/field/PaTextField";
import { InputContent, ValidationTypeResponse } from "api/main/data-contracts";
import PaCheckBox from "components/field/PaCheckBox";
import { useEffect, useState } from "react";
import InputValidation from "models/validation/InputValidation";
import PaDateTimePicker from "components/field/PaDateTimePicker";
import { findValidationValue } from "util/UtilityFunctions";
import { InputLabel, Typography } from "@mui/material";

interface Props {
  stepIndex: number;
  inputGroupIndex: number;
  index: number;
  inputTypes: string[];
  validatorTypes: ValidationTypeResponse[];
  selectOptions: string[];
}

export default function DataInputForm({
  stepIndex,
  inputGroupIndex,
  index,
  inputTypes,
  selectOptions,
  validatorTypes,
}: Props) {
  const formikContext = useFormikContext<any>();
  const [loading, setLoading] = useState<boolean>();

  const inputName = `steps.${stepIndex}.stepInformation.inputContent.${inputGroupIndex}.${index}.`;
  const input =
    formikContext.values.steps[stepIndex].stepInformation.inputContent[inputGroupIndex][index];
  const select: boolean = findValidationValue(
    input.validations as InputValidation[],
    "SELECT",
  );
  const imported = formikContext.values.imported;

  useEffect(() => {
    // If trying to update process, then don't reset on mount
    if (formikContext.dirty && !imported) {
      handleInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.type]);

  async function handleInit() {
    setLoading(true);
    await formikContext.setFieldValue(inputName + "selectOptions", []);
    await formikContext.setFieldValue(
      inputName + "validations",
      validatorTypes.map(
        (validation: ValidationTypeResponse): InputValidation => ({
          type: validation.name,
          value: validation.defaultValue,
        }),
      ),
      true,
    );
    setTimeout(() => setLoading(false), 5);
  }

  return (
    <>
      <PaTextField name={inputName + "label"} label="Laukelio pavadinimas" />
      <PaTextField name={inputName + "i18nkey"} label="Laukelio i18nkey" />
      <PaTextField name={inputName + "helperText"} label="Helper text" />
      <PaSelect
        name={inputName + "type"}
        label="Laukelio tipas"
        options={
          inputTypes.map((inputType) => ({
            value: inputType,
            label: inputType,
          })) ?? []
        }
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <InputLabel htmlFor={inputName + "options"}>
          Laukelio parinktys
        </InputLabel>
        <Field
          name={inputName + "options"}
          value={input?.options ?? []}
          component={PaAutocompleteInput}
        />
      </div>
      {select && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel htmlFor={inputName + "selectOptions"}>
            Pasirenkamojo laukelio parinktys
          </InputLabel>
          <Field
            name={inputName + "selectOptions"}
            value={input?.selectOptions ?? []}
            options={selectOptions}
            component={PaAutocompleteInput}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          minHeight: "200px", // This somewhat fixes ugly rendering
        }}
      >
        <Typography variant={"h4"}>Laukelio apribojimai</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            paddingLeft: "5px",
          }}
        >
          {!loading &&
            input.type !== "" &&
            validatorTypes.map(
              (
                validatorType: ValidationTypeResponse,
                validationIndex: number,
              ) => (
                <AutoGeneratedField
                  key={validationIndex}
                  name={`${inputName}validations.${validationIndex}.value`}
                  validationMetaInfo={validatorType}
                />
              ),
            )}
        </div>
      </div>
    </>
  );
}

interface AutoGeneratedFieldProps {
  name: string;
  validationMetaInfo: ValidationTypeResponse;
}

function AutoGeneratedField({
  name,
  validationMetaInfo,
}: AutoGeneratedFieldProps) {
  switch (validationMetaInfo.inputClass) {
    case "String":
      return (
        <PaTextField
          name={name}
          label={validationMetaInfo.name ?? "Text input"}
        />
      );
    case "Integer":
      return (
        <PaTextField
          name={name}
          label={validationMetaInfo.name ?? "Number input"}
        />
      );
    case "Boolean":
      return (
        <PaCheckBox name={name} text={validationMetaInfo.name ?? "Checkbox"} />
      );
    case "LocalDateTime":
      return (
        <PaDateTimePicker
          name={name}
          label={validationMetaInfo.name ?? "Date input"}
        />
      );
    default:
      return (
        <p style={{ margin: 0 }}>
          ERROR: {validationMetaInfo.inputClass} not found for{" "}
          {validationMetaInfo.name}
        </p>
      );
  }
}

