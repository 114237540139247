import Pdf from "components/pdf/Pdf";
import { useState } from "react";
import {
  ProcessExecutionResponse,
  StepExecutionResponse,
} from "api/main/data-contracts";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import "./Sign.css";
import VerySmartMarkId from "pages/VerySmartMarkId";
import { InfoComponent } from "../InfoComponent";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface Props {
  process: ProcessExecutionResponse;
  step: StepExecutionResponse;
  next: () => void;
  previous: () => void;
  execute: (obj?: any) => void;
  refresh: () => Promise<void>;
  canGoNext: boolean;
  canGoBack: boolean;
  canExecute: boolean;
}

export default function SignExecution({ process, step, next, execute, refresh, previous, canGoBack, canGoNext, canExecute }: Props) {

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {(step.status === "ACTIVE" || step.status === "FINISHED") && <Pdf stepExecutionId={step.id} onSuccessGeneration={() => setSuccess(true)} />}
      {step.status === "NOT_ACTIVE" && <InfoComponent IconComponent={AccessTimeIcon} waiting={true} title={"Prašome atlikti veiksmus esančius prieš tai"} text={"Dokumentas bus sugeneruotas, kai atliksite visus prašymo veiksmus"} />}
      <VerySmartMarkId open={open}
        type={"PIN2"}
        stepExecutionId={+(step.id || 0)}
        isConsent={step.step?.stepInformation?.signContent?.signType === "CONSENT"}
        refresh={refresh}
        handleSuccess={() => { setOpen(false); execute({}) }}
        handleClose={() => setOpen(false)}
      />

      <Box style={{ display: "flex", marginTop: "auto", gap: "1rem" }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{ padding: "0.5rem 1rem" }}
          disabled={!canGoBack}
          onClick={() => previous()}
        >
          Ankstesnis
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
          onClick={() => next()}
          disabled={!canGoNext}>
          Kitas
        </Button>

        {!step.executionInfo?.signingInfo?.signed && process.status !== "CANCELLED" && canExecute && step.status === "ACTIVE" && <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setOpen(true)}
          style={{ padding: "0.5rem 1rem" }}
          disabled={!success}>
          Pasirašyti dokumentą
        </Button>}
      </Box>
    </div>
  );
}
