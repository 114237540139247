/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { EmailResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class EmailAdminController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags email-admin-controller
   * @name FindAll
   * @summary Find all emails filtered by email to, status and email type. Returns sorted by date sent.
   * @request GET:/api/admin/v1/email/filtered
   */
  findAll = (
    query?: {
      emailTo?: string;
      status?: "PREPARED" | "SUCCEED" | "FAILED";
      type?: "MAGIC_LOGIN";
    },
    params: RequestParams = {},
  ) =>
    this.request<EmailResponse[], any>({
      path: `/api/admin/v1/email/filtered`,
      method: "GET",
      query: query,
      ...params,
    });
}
