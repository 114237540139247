/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { File, FileResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FilePublicController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Saves file
   *
   * @tags file-public-controller
   * @name Save
   * @request POST:/api/v1/filestorage
   */
  save = (
    data: {
      /** @format binary */
      uploadedFile: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<FileResponse, any>({
      path: `/api/v1/filestorage`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags file-public-controller
   * @name Download
   * @summary Downloads file by execution id id
   * @request GET:/api/v1/filestorage/download/execution/{stepExecutionId}
   */
  download = (stepExecutionId: number, params: RequestParams = {}) =>
    this.request<File, any>({
      path: `/api/v1/filestorage/download/execution/${stepExecutionId}`,
      method: "GET",
      ...params,
    });
}
