/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LoginRequest, MagicLoginRequest, RegistrationRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AuthController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Register user according to request
   *
   * @tags auth-controller
   * @name Register
   * @request POST:/api/public/v1/auth/register
   */
  register = (data: RegistrationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/public/v1/auth/register`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Log-in user by email and password
   *
   * @tags auth-controller
   * @name LoginWithPassword
   * @request POST:/api/public/v1/auth/login/password
   */
  loginWithPassword = (data: LoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/public/v1/auth/login/password`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Log-in user by magic link
   *
   * @tags auth-controller
   * @name LoginWithMagicLink
   * @request POST:/api/public/v1/auth/login/magic
   */
  loginWithMagicLink = (data: MagicLoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/public/v1/auth/login/magic`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Log-in user by google id token
   *
   * @tags auth-controller
   * @name LoginWithGoogle
   * @request POST:/api/public/v1/auth/login/google
   */
  loginWithGoogle = (data: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/public/v1/auth/login/google`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Log-in user by facebook access token
   *
   * @tags auth-controller
   * @name LoginWithFacebook
   * @request POST:/api/public/v1/auth/login/facebook
   */
  loginWithFacebook = (
    query: {
      accessToken: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/public/v1/auth/login/facebook`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * @description Log-off from the system and remove cookies
   *
   * @tags auth-controller
   * @name Logoff
   * @request GET:/api/public/v1/auth/logoff
   */
  logoff = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/public/v1/auth/logoff`,
      method: "GET",
      ...params,
    });
}
