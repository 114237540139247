import { FormControlLabel, Switch } from "@mui/material";
import { FastField, Field, useField } from "formik";

interface Props {
  name: string;
  label: string;
  fast?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export default function PaSwitch(props: Props) {
  const { name, label, fast = false, required, disabled } = props;

  const [value] = useField(name);

  return (
    <div>
      <FormControlLabel
        required={required}
        control={
          fast ? (
            <FastField
              id={name}
              component={Switch}
              checked={value.value}
              onChange={value.onChange}
              disabled={disabled}
            />
          ) : (
            <Field
              id={name}
              component={Switch}
              checked={value.value}
              onChange={value.onChange}
              disabled={disabled}
            />
          )
        }
        label={label}
      />
    </div>
  );
}
