/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { File, FileResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FileController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Fetches all file metadata
   *
   * @tags file-controller
   * @name FindAll
   * @request GET:/api/admin/v1/filestorage
   */
  findAll = (params: RequestParams = {}) =>
    this.request<FileResponse[], any>({
      path: `/api/admin/v1/filestorage`,
      method: "GET",
      ...params,
    });
  /**
   * @description Saves file
   *
   * @tags file-controller
   * @name Save
   * @request POST:/api/admin/v1/filestorage
   */
  save = (
    query: {
      type: "MANUAL" | "GENERATED" | "SIGNED" | "USER_INPUTTED";
    },
    data: {
      /** @format binary */
      uploadedFile: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<FileResponse, any>({
      path: `/api/admin/v1/filestorage`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description Fetches file by its id
   *
   * @tags file-controller
   * @name FindById
   * @request GET:/api/admin/v1/filestorage/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<FileResponse, any>({
      path: `/api/admin/v1/filestorage/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags file-controller
   * @name DownloadId
   * @summary Downloads file by its file id
   * @request GET:/api/admin/v1/filestorage/download/{fileId}
   */
  downloadId = (fileId: number, params: RequestParams = {}) =>
    this.request<File, any>({
      path: `/api/admin/v1/filestorage/download/${fileId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags file-controller
   * @name Delete
   * @summary Deletes file by its file id
   * @request DELETE:/api/admin/v1/filestorage/delete/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/v1/filestorage/delete/${id}`,
      method: "DELETE",
      ...params,
    });
}
