import { useRef, useState, useEffect } from "react";
import { AccountResponse } from "api/auth/data-contracts";
import { AccountTableRow, AccountTableHeadRow } from "./AccountListTableRow";
import { CustomTable } from "@recodin/fe-components";
import { createApi } from "util/ApiCreator";
import { AccountController } from "api/auth/AccountController";

const AccountList = () => {
  const api = useRef(createApi("account") as AccountController);
  const [accounts, setAccounts] = useState<AccountResponse[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setLoading(true);
    let res = await api.current.findAll();
    setAccounts(res);
    setLoading(false);
  };

  return (
    <div>
      <CustomTable
        data={accounts ?? []}
        isLoading={loading}
        headerRow={AccountTableHeadRow}
        contentRow={(props: { row: AccountResponse }) => (
          <AccountTableRow row={props.row} />
        )}
        disableFiltering
      />
    </div>
  );
};

export default AccountList;
