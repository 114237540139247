import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProcessExecutionResponse,
  ProcessResponse,
  StepExecutionResponse,
} from "api/main/data-contracts";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { createApi } from "util/ApiCreator";
import { ProcessExecutionPublicController } from "api/main/ProcessExecutionPublicController";
import { useAppSelector } from "redux/hooks";
import { hasRole } from "util/UtilityFunctions";
import { RoleNames } from "models/auth/RoleNames";
import { ProcessExecutionAdminController } from "api/main/ProcessExecutionAdminController";
import { ProcessController } from "api/main/ProcessController";
import { ProcessPublicController } from "api/main/ProcessPublicController";

const ProcessExecutionList = () => {
  const me = useAppSelector((s) => s.me.info);

  const executionAdminApi = useRef(createApi("processexecutionadmin") as ProcessExecutionAdminController);
  const executionApi = useRef(createApi("processexecution") as ProcessExecutionPublicController);
  const processApi = useRef(createApi("processpublic") as ProcessPublicController);

  const navigate = useNavigate();
  const [processes, setProcesses] = useState<ProcessResponse[]>();
  const [executions, setExecutions] = useState<ProcessExecutionResponse[]>();
  const [adminExecutions, setAdminExecutions] = useState<ProcessExecutionResponse[]>();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getData() {
    setProcesses(await processApi.current.findAll());
    setExecutions(await executionApi.current.findAllByUser());

    if (hasRole(RoleNames.ADMIN, me?.roles)) {
      setAdminExecutions(await executionAdminApi.current.findAllForAction());
    }
  }

  const createNewExecution = async (process: ProcessResponse) => {
    if (!process?.id || !process?.active) return;
    const dto = await executionApi.current.create({
      processDefinitionId: process.id,
    });
    navigate(`/process/execution/${dto.id}/0`);
  };

  function handleRowClick(row: any) {
    const index = row?.stepExecutions
      ?.map((step: any, index: any) =>
        step.status === "ACTIVE" ? index : undefined,
      )
      ?.find((value: any) => Boolean(value));

    navigate(`/process/execution/${row?.id}/${index ?? 0}`);
  }

  return (
    <>
      <div style={{ height: "auto" }}>
        {
          // !executions || executions?.length === 0 &&
          <>
            <h1> Užpildykite prašymą norint prisijungti prie grupės ieškinio </h1>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650, overflow: "auto"}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width={"40%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Bendra informacija
                    </TableCell>
                    <TableCell
                      width={"40%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Žingsniai
                    </TableCell>
                    <TableCell
                      width={"10%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Būsena
                    </TableCell>
                    <TableCell
                      width={"10%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Veiksmai
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processes?.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        transition: "0.4s",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => createNewExecution(row)}
                    >
                      <TableCell width={"45%"} align={"left"}>
                        {row?.name}
                      </TableCell>
                      <TableCell width={"45%"} align={"left"}>
                        <Box>
                          Iš viso <b>{row?.steps?.length}</b> žingsniai norint
                          pateikti ieškinį
                        </Box>
                      </TableCell>
                      <TableCell
                        width={"10%"}
                      // style={{ display: "flex", flexDirection: "column", gap: "5px" }}
                      >
                        <Chip
                          label={row?.active ? "Aktyvus" : "Išjungtas"}
                          variant={"filled"}
                          color={row?.active ? "success" : "info"}
                        />
                      </TableCell>
                      <TableCell width={"10%"} align="left">
                        <IconButton
                          aria-label="view"
                          color="primary"
                          style={{ display: "flex", alignSelf: "center" }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }

        <Typography variant={"subtitle"} style={{ marginTop: "1rem" }}>
          Pasirinkite grupės ieškinį ir spauskite ant lentelės eilutės
        </Typography>

        {executions && executions.length > 0 && (
          <>
            <h1> Jūsų užpildytas prašymas dėl prisijungimo prie grupės ieškinio</h1>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width={"40%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Bendra informacija
                    </TableCell>
                    <TableCell
                      width={"40%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Etapai
                    </TableCell>
                    <TableCell
                      width={"10%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Būsena
                    </TableCell>
                    <TableCell
                      width={"10%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Veiksmai
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {executions.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        transition: "0.4s",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(row)}
                    >
                      <TableCell width={"40%"} align="left">
                        {row?.process?.name}
                        <br />
                        {row?.accountInfoResponse?.email}
                      </TableCell>
                      <TableCell width={"40%"} align="left">
                        {row?.stepExecutions
                          ?.filter(
                            (step: StepExecutionResponse) =>
                              step.status === "ACTIVE",
                          )
                          .map((step: StepExecutionResponse, i: number) => (
                            <div key={i}>{step?.name}</div>
                          ))}
                      </TableCell>

                      <TableCell
                        width={"10%"}
                        //  style={{ display: "flex", flexDirection: "column", gap: "5px" }}
                        align="left"
                      >
                        {row?.status === "CANCELLED" && (
                          <Chip
                            label={"Ieškinys atmestas"}
                            variant="filled"
                            color={"error"}
                          />
                        )}
                        {row?.status === "IN_PROGRESS" && (
                          <Chip
                            label={row.myTurn ? "Jūsų eilė" : "Laukiama"}
                            variant="filled"
                            color={row.myTurn ? "warning" : "info"}
                          />
                        )}
                        {row?.status === "FINISHED" && (
                          <Chip
                            label={"Pabaigta"}
                            variant={"filled"}
                            color={"success"}
                          />
                        )}
                      </TableCell>

                      <TableCell width={"10%"} align="left">
                        <IconButton
                          aria-label="view"
                          color="primary"
                          style={{ display: "flex", alignSelf: "center" }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {adminExecutions && adminExecutions.length > 0 && (
          <>
            <h1 style={{color: "red"}}> Jums, kaip Vartotojų aljansui, reikia padaryti veiksmus šiuose prašymuose</h1>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width={"40%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Bendra informacija
                    </TableCell>
                    <TableCell
                      width={"40%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Etapai
                    </TableCell>
                    <TableCell
                      width={"10%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Būsena
                    </TableCell>
                    <TableCell
                      width={"10%"}
                      align="left"
                      style={{ fontWeight: 700 }}
                    >
                      Veiksmai
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminExecutions?.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        transition: "0.4s",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(row)}
                    >
                      <TableCell width={"40%"} align="left">
                        {row?.process?.name}
                        <br />
                        {row?.accountInfoResponse?.email}
                      </TableCell>
                      <TableCell width={"40%"} align="left">
                        {row?.stepExecutions
                          ?.filter(
                            (step: StepExecutionResponse) =>
                              step.status === "ACTIVE",
                          )
                          .map((step: StepExecutionResponse, i: number) => (
                            <div key={i}>{step?.name}</div>
                          ))}
                      </TableCell>

                      <TableCell
                        width={"10%"}
                        //  style={{ display: "flex", flexDirection: "column", gap: "5px" }}
                        align="left"
                      >
                        {row?.status === "CANCELLED" && (
                          <Chip
                            label={"Ieškinys atmestas"}
                            variant="filled"
                            color={"error"}
                          />
                        )}
                        {row?.status === "IN_PROGRESS" && (
                          <Chip
                            label={row.myTurn ? "Jūsų eilė" : "Laukiama"}
                            variant="filled"
                            color={row.myTurn ? "warning" : "info"}
                          />
                        )}
                        {row?.status === "FINISHED" && (
                          <Chip
                            label={"Pabaigta"}
                            variant={"filled"}
                            color={"success"}
                          />
                        )}
                      </TableCell>

                      <TableCell width={"10%"} align="left">
                        <IconButton
                          aria-label="view"
                          color="primary"
                          style={{ display: "flex", alignSelf: "center" }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </>
  );
};

export default ProcessExecutionList;
