
import 'pdfjs-dist/build/pdf.worker.entry';
import { Box, Typography } from '@mui/material';
import { bool } from 'yup';
import { useState } from 'react';
import CustomSpinner from 'components/display/CustomSpinner';

interface PdfProps {
  stepExecutionId?: number;
  onSuccessGeneration: (data: any) => void;
}

export default function Pdf({ stepExecutionId, onSuccessGeneration }: PdfProps) {

  const [loadingas, setLoading] = useState<boolean>(true);

  return <>
    {loadingas && <Box>
      <Typography variant="h1" align="center">
        Dokumentas kraunasi...
      </Typography>
      <CustomSpinner
        label={"Prašome palaukti..."}
      />
    </Box>}
    <Box style={{ marginBottom: "1rem", height: "100%", display: loadingas ? "none" : "block" }}>
      <iframe src={`/api/v1/filestorage/download/execution/${stepExecutionId}`} width="100%" height="100%" onLoad={() => { onSuccessGeneration(true); setLoading(false); }}>
        <p>Your browser does not support iframes.</p>
      </iframe>
    </Box>
  </>
}
