import { Box, Container, Typography, styled } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';

interface Props {
  title: string;
  text?: string;
  waiting?: boolean; // new prop to determine the state
  IconComponent?: typeof BlockIcon; // new prop to pass the icon component
  children?: any;
}

export function InfoComponent({
  title,
  text,
  waiting = false,
  IconComponent = BlockIcon,
  children
}: Props) {
  return (
    <StyledContainer>
      <StyledIcon as={IconComponent} color={waiting ? "primary" : "error"} />
      <Typography variant="h1" gutterBottom style={{ textAlign: "center" }}>
        {title}
      </Typography>
      {text && <Typography variant="body" style={{ textAlign: "center" }}>
        {text}
      </Typography>}
      {children && <Box>
        {children}
      </Box>}

    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledIcon = styled("div")`
  font-size: 5em;
  margin-bottom: 20px;

  & svg {
    font-size: inherit;
    color: currentColor;
  }
`;
