import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CommonConstants from "constants/CommonConstants";

export interface CommonState {
  outerWidth?: number;
  innerWidth?: number;
  // do not show side menu for certain path names or user roles
  sideMenuIsHidden?: boolean;
  // open/close is for mobile
  sideMenuIsOpen?: boolean;
  // permanent is changed according to inner width
  sideMenuIsPermanent?: boolean;
}

const initialState: CommonState = {
  outerWidth: window.outerWidth,
  innerWidth: window.innerWidth,
  sideMenuIsHidden: true,
  sideMenuIsOpen: true,
  sideMenuIsPermanent:
    window.innerWidth >= CommonConstants.minPermanentSideMenuWidth,
};

const commonSlice = createSlice({
  name: "commonInfo",
  initialState: initialState,
  reducers: {
    updateWidth: (state: CommonState, action: PayloadAction<CommonState>) => {
      const { outerWidth, innerWidth } = action.payload;
      state.outerWidth = outerWidth;
      state.innerWidth = innerWidth;
      state.sideMenuIsPermanent =
        innerWidth !== undefined &&
        innerWidth >= CommonConstants.minPermanentSideMenuWidth;
    },
    updateSideMenuFlags: (
      state: CommonState,
      action: PayloadAction<CommonState>,
    ) => {
      if (state.sideMenuIsHidden !== action.payload.sideMenuIsHidden) {
        state.sideMenuIsHidden = action.payload.sideMenuIsHidden;
      }
      if (
        state.sideMenuIsOpen !== action.payload.sideMenuIsOpen &&
        !state.sideMenuIsPermanent
      ) {
        state.sideMenuIsOpen = action.payload.sideMenuIsOpen;
      }
    },
  },
});

export const { updateWidth, updateSideMenuFlags } = commonSlice.actions;
export default commonSlice.reducer;
