import { Chip, TableCell } from "@mui/material";
import {
  CustomTableHeadRow,
  CustomTableRow,
  TextCell,
} from "@recodin/fe-components";
import { useNavigate } from "react-router-dom";
import { processExecutionAdminData } from "./ProcessExecutionAdminList";
import { ProcessExecutionResponse, StepExecutionResponse } from "api/main/data-contracts";
import { useAppSelector } from "redux/hooks";
import { hasRole } from "util/UtilityFunctions";
import { RoleNames } from "models/auth/RoleNames";

export function ProcessExecutionAdminTableHeadRow() {
  return (
    <CustomTableHeadRow
      cells={[
        {
          label: "Vartotojas",
          width: "50%",
          align: "left",
        },
        {
          label: "Proceso būsena",
          width: "25%",
          align: "left",
        },

        {
          label: "Būsena",
          width: "25%",
          align: "left",
        },
      ]}
    />
  );
}

interface ProcessExecutionAdminTableRowProps {
  row: processExecutionAdminData;
  onRowClick?: (row: ProcessExecutionResponse) => void;
}

function getLastDate(process: processExecutionAdminData): number {
  const processSteps = process.stepExecutions;

  if (!processSteps) {
    return 0;
  }
  const largestNumber = Math.max(
    ...processSteps.map((step) => +(step.executionInfo?.time ?? 0),
    ));
  return largestNumber * 1000;
}


export function ProcessExecutionAdminTableRow({ row }: ProcessExecutionAdminTableRowProps) {
  const navigate = useNavigate();
  const me = useAppSelector((s) => s.me.info);

  const myTurn = row.myTurn;

  function handleRowClick() {
    navigate(`/process/execution/${row.id}`);
  }
  function getDate(row: processExecutionAdminData): string {
    const lastDate = getLastDate(row);
    if (lastDate === 0) return "Neaktyvuotas";
    const date = new Date(getLastDate(row));

    const dateString: string =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      "\n" +
      date.getHours() +
      ":" +
      date.getMinutes();
    return dateString;
  }

  let activeStep = row?.stepExecutions?.find((step: StepExecutionResponse) => step.status === "ACTIVE");
  let stepCount = row?.stepExecutions?.length || 0;

  let activeStepIndex = stepCount;
  if (activeStep) {
    let index = row.stepExecutions?.indexOf(activeStep);
    if (index && index >= 0) {
      activeStepIndex = index;
    }
  }

  return (
    <CustomTableRow onClick={handleRowClick}>
      <TableCell width={"50%"} align={"left"}>
        <h3 style={{ padding: 0, margin: 0 }}>{row?.process?.name}</h3>
        {row?.accountInfoResponse?.email && <div>El. paštas: <b>{row?.accountInfoResponse?.email}</b></div>}
        {row?.accountInfoResponse?.firstName && <div>Vardas: <b>{row?.accountInfoResponse?.firstName}</b></div>}
        {row?.accountInfoResponse?.lastName && <div>Pavardė:<b> {row?.accountInfoResponse?.lastName}</b></div>}
      </TableCell>

      <TableCell
        width={"25%"}
        align={"left"}
        style={{ display: "flex", flexDirection: "column", gap: "5px", alignContent: "flex-start" }}
      >
       {translateToLithuanian(row.status)}
      </TableCell>

      <TableCell
        width={"25%"}
        align={"left"}
        style={{ display: "flex", flexDirection: "column", gap: "5px", alignContent: "flex-start" }}
      >
       {activeStep && <>{activeStep?.name} {activeStepIndex + 1}/{stepCount + 1}</>} 
       
        {row?.status !== "FINISHED" && (
          <Chip
            label={myTurn ? "Vartotojų aljanso eilė" : "Vartotojo eilė"}
            variant="filled"
            color={myTurn ? "warning" : "info"}
          />
        )}
        {row?.status === "FINISHED" && (
          <Chip label={"Pabaigta"} variant={"filled"} color={"success"} />
        )}
      </TableCell>
    </CustomTableRow>
  );
}

export function translateToLithuanian(value?: string) {
  switch(value) {
      case 'IN_PROGRESS':
          return 'Aplikacijos procesas vykdomas';
      case 'FINISHED':
          return 'Baigta';
      case 'CANCELLED':
          return 'Atmestas';
      case 'DEAL_DONE':
          return 'Pinigai grąžinti';
      default:
          return 'Nežinomas';
  }
}
