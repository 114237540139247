import { useEffect, useRef, useState } from "react";
import { CustomTable } from "@recodin/fe-components";
import {
  EmailTemplateTableHeadRow,
  EmailTemplateTableRow,
} from "./EmailTemplateTableRow";
import { createProps } from "./EmailTemplateDialogProps";
import {
  EmailTemplateCreateRequest,
  EmailTemplateResponse,
  EmailTemplateUpdateRequest,
} from "api/main/data-contracts";
import { createApi } from "util/ApiCreator";
import { EmailTemplateAdminController } from "api/main/EmailTemplateAdminController";

export default function EmailTemplatePage() {
  const api = useRef(createApi("emailtemplate") as EmailTemplateAdminController)
  
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplateResponse[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    handleDataFetch();
  }, []);

  async function handleDataFetch() {
    setLoading(true);
    setEmailTemplates(await api.current.findAll());
    setLoading(false);
  }
  async function handleCreateUpdate(data: EmailTemplateResponse) {
    if (data?.id) {
      await api.current.update(data.id, data as EmailTemplateUpdateRequest);
    } else {
      await api.current.create(data as EmailTemplateCreateRequest);
    }
    await handleDataFetch();
  }
  async function handleDelete(data: EmailTemplateResponse) {
    if (data?.id) {
      await api.current.delete(data?.id);
      await handleDataFetch();
    }
  }
  return (
    <CustomTable
      data={emailTemplates}
      isLoading={loading}
      headerRow={EmailTemplateTableHeadRow}
      contentRow={(props: { row: EmailTemplateResponse }) => (
        <EmailTemplateTableRow
          row={props.row}
          handleDelete={handleDelete}
          handleCreateUpdate={handleCreateUpdate}
        />
      )}
      createProps={createProps(handleCreateUpdate)}
      disableSearching
      disableFiltering
    />
  );
}
