import {
  DeleteDialogProps,
  EditDialogProps,
  CreateDialogProps,
} from "@recodin/fe-components";
import EmailTemplateEditForm from "./EmailTemplateEditForm";
import { EmailTemplateResponse } from "api/main/data-contracts";

export const editDialogProps = (
  handleCreateUpdate: (emailTemplate: EmailTemplateResponse) => void,
  row?: EmailTemplateResponse,
): EditDialogProps => {
  return {
    headerText: row
      ? `"${row.subject}" redagavimas`
      : "Naujo laiško šablono sukūrimas",
    contentTextFor: "laiško šabloną",
    EditForm: (
      <EmailTemplateEditForm
        emailTemplate={row}
        handleEdit={handleCreateUpdate}
      />
    ),
  };
};

export const createProps = (
  handleCreateUpdate: (emailTemplate: EmailTemplateResponse) => void,
  row?: EmailTemplateResponse,
): CreateDialogProps => ({
  ...editDialogProps(handleCreateUpdate, row),
  createButtonLabel: "Naujas laiškų šablonas",
});

export const deleteDialogProps = (
  row: EmailTemplateResponse,
): DeleteDialogProps => ({
  labels: {
    title: "Laiškų šablono ištrynimas",
    question: `Ar tikrai norite ištrinti laiškų šabloną temai "${row?.subject}"?`,
    dialogBoldedNameToDelete: `Laiškų šablonas temai "${row?.subject}"`,
    dialogText: " bus visam laikui ištrintas. Jį atkurti galimybių nebus!",
  },
});
