import {
  ProcessExecutionResponse,
  StepExecutionResponse,
} from "api/main/data-contracts";
import { useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import "./Sign.css";
import VerySmartMarkId from "pages/VerySmartMarkId";
import { InfoComponent } from "../InfoComponent";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReduxUserApi from "redux/user/ReduxUserApi";
import { createApi } from "util/ApiCreator";
interface Props {
  process: ProcessExecutionResponse;
  step: StepExecutionResponse;
  execute: (obj: object) => Promise<void>;
  refresh: () => Promise<void>;
  next: () => void;
  previous: () => void;
  canGoNext: boolean;
  canGoBack: boolean;
  canExecute: boolean;
}

export default function IdentificationExecution(props: Props) {
  const api = useRef(createApi("account"));

  const [open, setOpen] = useState(false);

  const identificationStatus = (status: StepExecutionResponse["status"]) => (!props.step.executionInfo?.identificationInfo?.identified && props.step.status === "ACTIVE" && status === "ACTIVE") || props.step.status === status;

  return <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    }}
  >
    {identificationStatus("ACTIVE") && <InfoComponent
      waiting={true}
      IconComponent={AccessTimeIcon}
      title="Prašome patvirtinti tapatybę"
      text="Tapatybę galite patvirtinti paspaudę mygtuką apačioje dešinėje"
    />}

    {identificationStatus("NOT_ACTIVE") && <InfoComponent
      IconComponent={AccessTimeIcon}
      waiting={true}
      title="Prašome atlikti veiksmus esančius prieš tai"
      text="Atlikę praeitus žingsnius, galėsite patvirtinti tapatybę ir tęsti toliau"
    />}

    {identificationStatus("FINISHED") && <InfoComponent
      waiting={true}
      IconComponent={DoneAllIcon}
      title="Sėkmingai patvirtinote savo tapatybę"
      text=""
    />}

    <VerySmartMarkId
      open={open}
      type={"PIN1"}
      stepExecutionId={+(props.step.id || 0)}
      refresh={props.refresh}
      handleSuccess={() => { setOpen(false); props.execute({});     ReduxUserApi.getAndSetMe(api);    }}
      handleClose={() => setOpen(false)} />

    <Box style={{ display: "flex", marginTop: "auto", gap: "1rem" }}>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        style={{ padding: "0.5rem 1rem" }}
        disabled={!props.canGoBack}
        onClick={() => props.previous()}
      >
        Ankstesnis
      </Button>

      <Button
        variant="outlined"
        color="primary"
        size="large"
        style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
        onClick={() => props.next()}
        disabled={!props.canGoNext}
      >
        Kitas
      </Button>

      {identificationStatus("ACTIVE") && props.canExecute && props.process.status !== "CANCELLED" && <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setOpen(true)}
        style={{ padding: "0.5rem 1rem" }}
      >
        Patvirtinti tapatybę
      </Button>}

    </Box>
  </div>
}