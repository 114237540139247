
import { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Container, Grid, Box, Divider } from '@mui/material';
import { createApi } from 'util/ApiCreator';
import { MessageResponse } from 'api/main/data-contracts';
import { MessageAdminController } from 'api/main/MessageAdminController';
import { MessagePublicController } from 'api/main/MessagePublicController';

const MessagePage = () => {
    const api = useRef(createApi("messagespublic") as MessagePublicController);

    const [news, setNews] = useState<MessageResponse[]>([]);
    useEffect(() => {
        get();
    }, []);

    const get = async () => {
        setNews(await api.current.getAll());
    }

    return <>
        <h1> Pranešimai gauti iš Vartotojų aljanso</h1>

        <Container maxWidth={false} style={{ padding: 0 }}>
            <Grid container spacing={3}>
                {news.map((newsItem, index) => (
                    <Grid item xs={12} key={index}>
                        <Card elevation={3}>
                            <CardContent>
                                <Typography variant="h1" component="div">
                                    {newsItem.subject}
                                </Typography>
                                <Box mt={2} mb={2}>
                                    <Divider />
                                </Box>
                                <Typography variant="body" component="div" dangerouslySetInnerHTML={{ __html: newsItem.content || '' }} />
                                <Box mt={2}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        Paskelbta: {newsItem.dateCreated}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}

            </Grid>
        </Container>

        {(!news || news.length < 1) && <Typography variant="h3" style={{ marginTop: "2rem" }}>
            Pranešimų nėra
        </Typography>}
    </>;
}

export default MessagePage;
