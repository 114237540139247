import {
  CustomTableHeadRow,
  CustomTableRow,
  TextCell,
} from "@recodin/fe-components";
import { ProcessResponse, StepResponse } from "api/main/data-contracts";
import { Chip, TableCell } from "@mui/material";

export function ProcessTableHeadRow() {
  return (
    <CustomTableHeadRow
      cells={[
        {
          label: "Bendra informacija",
          width: "45%",
          align: "left",
        },
        {
          label: "Etapai",
          width: "45%",
          align: "left",
        },
        {
          label: "Būsena",
          width: "10%",
        },
      ]}
    />
  );
}

interface ProcessTableRowProps {
  row: ProcessResponse;
  onRowClick?: () => void;
}
export function ProcessTableRow({ row, onRowClick }: ProcessTableRowProps) {
  return (
    <CustomTableRow onClick={onRowClick}>
      <TextCell
        width={"45%"}
        align={"left"}
        label={row?.name}
        // subLabel={[`Raktas: ${row?.i18nkey}`, `Versija: ${row?.version}`]}
      />
      <TextCell
        width={"45%"}
        align={"left"}
        label={row?.steps?.map(
          (step: StepResponse, index: number) =>
            `Etapas nr ${index + 1}: ${step?.name}`,
        )}
      />
      <TableCell
        width={"10%"}
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <Chip
          label={row?.active ? "Aktyvus" : "Išjungtas"}
          variant={"filled"}
          color={row?.active ? "success" : "info"}
        />
      </TableCell>
    </CustomTableRow>
  );
}
