/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { KevinInitiateResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PaymentController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags payment-controller
   * @name Webhook
   * @summary webhook. Not implemented yet
   * @request POST:/api/admin/v1/payment
   */
  webhook = (data: KevinInitiateResponse, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/admin/v1/payment`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-controller
   * @name InitializePayment
   * @summary Initialize a payment
   * @request POST:/api/admin/v1/payment/{processExecutionId}/{stepExecutionId}
   */
  initializePayment = (processExecutionId: number, stepExecutionId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/admin/v1/payment/${processExecutionId}/${stepExecutionId}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-controller
   * @name GetPaymentStatus
   * @summary Fetch a payment status by step id
   * @request GET:/api/admin/v1/payment/status/{stepExecutionId}/{paymentId}
   */
  getPaymentStatus = (stepExecutionId: number, paymentId: string, params: RequestParams = {}) =>
    this.request<"started" | "pending" | "completed" | "failed", any>({
      path: `/api/admin/v1/payment/status/${stepExecutionId}/${paymentId}`,
      method: "GET",
      ...params,
    });
}
