import {
  CustomTableHeadRow,
  CustomTableRow,
  TextCell,
} from "@recodin/fe-components";
import { FileResponse } from "api/main/data-contracts";
import { IconButton, TableCell, useTheme } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { findFileType } from "./FileTypeTranslation";

export function FileTableHeadRow() {
  return (
    <CustomTableHeadRow
      cells={[
        {
          label: "Pavadinimas",
          width: "40%",
          align: "left",
        },
        {
          label: "Vieta",
          width: "40%",
          align: "left",
        },
        {
          label: "Tipas",
          width: "10%",
          align: "left",
        },
        {
          label: "Veiksmai",
          width: "10%",
        },
      ]}
    />
  );
}

interface FileTableRowProps {
  row: FileResponse;
  handleDownload: (file: FileResponse) => void;
}
export function FileTableRow({ row, handleDownload }: FileTableRowProps) {
  const theme = useTheme();
  const [clicked, setClicked] = useState<boolean>(false);
  return (
    <CustomTableRow>
      <TextCell width={"40%"} align={"left"} label={`${row?.name}`} />
      <TextCell width={"40%"} align={"left"} label={row?.location} />
      <TextCell
        width={"10%"}
        align={"left"}
        label={findFileType(row?.type)?.label}
      />
      <TableCell width={"10%"}>
        <IconButton
          style={{
            color: clicked
              ? theme.palette.error.light
              : theme.palette.primary.main,
          }}
          onClick={(event: any) => {
            event.stopPropagation();
            if (!clicked) {
              setClicked(true);
              handleDownload(row);
              setTimeout(() => setClicked(false), 200);
            }
          }}
        >
          <DownloadIcon />
        </IconButton>
      </TableCell>
    </CustomTableRow>
  );
}
