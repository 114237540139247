import { useEffect, useRef, useState } from "react";
import { FileResponse } from "api/main/data-contracts";
import { CustomTable } from "@recodin/fe-components";
import { FileTableHeadRow, FileTableRow } from "./FileListTableRow";
import CommonConstants from "constants/CommonConstants";
import { saveAs } from "file-saver";
import axios from "axios";
import { getCreateProps } from "./FileListDialogProps";
import { createApi } from "util/ApiCreator";
import { FileController } from "api/main/FileController";
import { handleDownload } from "pages/processexecution/admin/ProcessExecutionAdminPage";

const FileList = () => {
  const api = useRef(createApi("filestorage") as FileController);
  const [files, setFiles] = useState<FileResponse[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getData() {
    setLoading(true);
    setFiles(await api.current.findAll());
    setLoading(false);
  }

  async function handleSave(selectedFile: File) {
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await api.current.save(
        { type: "MANUAL" },
        { uploadedFile: selectedFile },
      );
      await getData();
    } catch (error) {
      console.error("Error uploading file", error);
    }
  }

  return (
    <div>
      <CustomTable
        data={files ?? []}
        isLoading={loading}
        headerRow={FileTableHeadRow}
        contentRow={(props: { row: FileResponse }) => (
          <FileTableRow row={props.row} handleDownload={() => handleDownload(props.row.id)} />
        )}
        createProps={getCreateProps(handleSave)}
        disableFiltering
      />
    </div>
  );
};

export default FileList;
