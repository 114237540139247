import "./Field.css";
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FastField, Field, useField } from "formik";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

interface Props {
  name: string;
  label: string;
  fast?: boolean;
  type?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  hideError?: boolean;
  disabled?: boolean;
  labelButtonAction?: () => void;
  labelButtonLabel?: string;
  showInstead?: JSX.Element;
  rows?: number;
}

const PaTextField = ({
  name,
  label,
  type,
  fast = false,
  autoFocus,
  required,
  fullWidth,
  hideError,
  disabled,
  labelButtonAction,
  labelButtonLabel,
  showInstead,
  rows,
}: Props) => {
  const theme = useTheme();
  const [, meta] = useField(name);
  return (
    <div className={"paTextField-container"} style={{ marginBottom: "1rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        {labelButtonLabel && labelButtonAction && (
          <Button variant={"contained"} onClick={labelButtonAction}>
            {labelButtonLabel}
          </Button>
        )}
      </div>
      {Boolean(showInstead) && showInstead}
      {!showInstead && (
        <>
          {fast && (
            <FastField
              as={TextField}
              name={name}
              type={type}
              autoFocus={autoFocus}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              multiline={Boolean(rows)}
              rows={rows}
            />
          )}
          {!fast && (
            <Field
              as={TextField}
              name={name}
              type={type}
              autoFocus={autoFocus}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              multiline={Boolean(rows)}
              rows={rows}
            />
          )}
          {!hideError && meta.touched && Boolean(meta.error) && (
            <Box className="paTextField-error-container">
              <ErrorRoundedIcon className="paTextField-error-icon" />
              <Typography
                variant="subtitle"
                color={theme.palette.error.main}
                align="left"
              >
                {meta.error}
              </Typography>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default PaTextField;
