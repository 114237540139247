import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProcessResponse } from "api/main/data-contracts";
import { CustomTable } from "@recodin/fe-components";
import { ProcessTableHeadRow, ProcessTableRow } from "./ProcessListTableRow";
import { getCreateProps } from "./ProcessListDialogProps";
import { createApi } from "util/ApiCreator";

export default function ProcessList() {
  const api = useRef(createApi("process"));
  const navigate = useNavigate();
  const [processes, setProcesses] = useState<ProcessResponse[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getData() {
    setLoading(true);
    setProcesses(await api.current.findAll());
    setLoading(false);
  }

  function goToNewProcessCreation() {
    navigate("/process/new");
  }

  return (
    <div>
      <CustomTable
        data={processes ?? []}
        isLoading={loading}
        headerRow={ProcessTableHeadRow}
        contentRow={(props: { row: ProcessResponse }) => (
          <ProcessTableRow
            row={props.row}
            onRowClick={() => navigate(`/process/${props.row?.id}`)}
          />
        )}
        createProps={getCreateProps(goToNewProcessCreation)}
        disableFiltering
      />
    </div>
  );
}
