import { useEffect, useRef, useState } from "react";
import { CustomTable } from "@recodin/fe-components";
import { EmailTableHeadRow, EmailTableRow } from "./EmailTableRow";
import { EmailResponse } from "api/main/data-contracts";
import { createApi } from "util/ApiCreator";
import { EmailAdminController } from "api/main/EmailAdminController";

export default function EmailPage() {
  const emailApi = useRef(createApi("email") as EmailAdminController)
  const [emails, setEmails] = useState<EmailResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    handleDataFetch();
  }, []);

  async function handleDataFetch() {
    // search?: SearchRequest,
    // filter?: FilterRequest
    setLoading(true);
    setEmails(
      await emailApi.current.findAll({
        // status: filter?.status,
      }),
    );
    setLoading(false);
  }

  return (
    <div>
      <CustomTable
        data={emails}
        isLoading={loading}
        headerRow={EmailTableHeadRow}
        contentRow={EmailTableRow}
        handleSearchAndFilter={handleDataFetch}
        // filterFormProps={{
        // 	selectItems: {
        // 		statuses: allEmailStatus,
        // 		types: allEmailTypes,
        // 	},
        // 	selectItemsTranslation: {
        // 		status: (val: string) => findEmailStatus(val)?.label ?? "",
        // 		type: (val: string) => translateEmailType(val, true),
        // 	},
        // }}
      />
    </div>
  );
}
