import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

const information = [
  {
    "question": "Kas gali prisijungti prie šio grupės ieškinio?",
    "answer": `Grupės nariu gali tapti tik vartotojas – t. y. fizinis asmuo, perkantis prekes ar paslaugas asmeniniais tikslais, nesusijusiais su verslu, prekyba, amatu ar profesija. Šiuo atveju nukentėjusiais vartotojais laikomi asmenys, kurie įsigijo vairavimo kursus iš nurodytų vairavimo mokyklų asmeniniais tikslais. Jei asmuo vairavimo kursus įsigijo darbo arba verslo tikslais (pavyzdžiui, darbdavys apmokėjo kursus siekiant įgyti darbui būtiną kategoriją), jo pažeistų teisių Vartotojų aljansas ginti negali. Vartotojas turi būti įsigijęs vairavimo kursus vienoje iš šių vairavimo mokyklų: <p>Vartotojas turi būti įsigijęs vairavimo kursus vienoje iš šių vairavimo mokyklų:</p>
        <ol>
            <li>Žukausko personalinis vairuotojų ruošimo centras „VRC“</li>
            <li>UAB Mokymo centras „Adpilis“</li>
            <li>UAB „Automotoket“</li>
            <li>UAB „Autoplaza“</li>
            <li>UAB „Daugva ir ko</li>
            <li>UAB „Dorkanas</li>
            <li>UAB „Dovanėlė Jums“</li>
            <li>UAB „J. Rinkevičiaus vairavimo mokykla</li>
            <li>UAB "JTMC"</li>
            <li>Jono Jasučio įmonė</li>
            <li>UAB „Kelio iliuzija“</li>
            <li>UAB „LDV Unio“</li>
            <li>UAB „Sostinės vairavimo mokykla“</li>
            <li>VšĮ „Vairoseta“</li>
            <li>Biudžetinė įstaiga Vilniaus saugaus miesto centras</li>
            <li>UAB „Visagalis“</li>
            <li>UAB „ARV-Auto“</li>
            <li>UAB „Auto ABC“</li>
            <li>VšĮ „Auto ABCDE“</li>
            <li>UAB „Kauno Naujamiesčio darbo rinkos mokymo centras“</li>
            <li>V. Žukausko įmonė</li>
            <li>UAB „Rigveda“</li>
            <li>Gailiaus firma „Arvaira“</li>
            <li>IĮ Jurbarko saugaus eismo centras</li>
            <li>Dariaus Mikulio įmonė</li>
            <li>UAB „Tikslo linija“</li>
        </ol>
        Minėtos vairavimo mokyklos dalyvavo karteliniame susitarime skirtingais laikotarpiais, bet ne anksčiau nei 2016 m. liepos mėn. ir ne vėliau nei 2018 m. gruodžio 20 d.`
  },
  {
    "question": "Kokius įrodymus (dokumentus) turiu turėti, jei noriu prisijungti prie grupės ieškinio?",
    "answer": "Vartotojas turi galėti pateikti įrodymus, kad nurodytu laikotarpiu įsigijo vairavimo kursus iš nurodytų mokyklų (pavyzdžiui, sutarties kopiją, kvitą, sąskaitą ar pan.)."
  },
  {
    "question": "Iki kada galima pateikti prašymą prisijungti prie grupės ieškinio?",
    "answer": `Vartotojai, patyrę žalą dėl vairavimo mokyklų įkainių derinimo, prie grupės gali prisijungti iki Vartotojų aljanso nustatyto termino. Terminas skelbiamas <a href=https://vairavimokartelis.lt">www.vairavimokartelis.lt</a>`
  },
  {
    "question": "Nespėjau pateikti prašymo tapti grupės nariu iki nustatyto termino, ar galiu prisijungti vėliau?",
    "answer": "Taip, priėmęs ieškinį teismas nustatys nuo 60 iki 90 dienų terminą grupei papildyti. Kad nepraleistumėte termino, kviečiame užpildyti sistemoje esančią paraišką."
  },
  {
    "question": "Kokio žalos atlyginimo galiu tikėtis?",
    "answer": "Planuojame siekti, kad kiekvienam vartotojui, kuris dėl vairavimo mokyklų susitarimo permokėjo už vairavimo kursus, būtų atlyginta patirta turtinė žala, t.y. skirtumas tarp to, ką sumokėjote, ir kursų kainos, jei susitarimo nebūtų buvę."
  },
  {
    "question": "Kokias išlaidas, susijusias su šiuo grupės ieškiniu, turėsiu padengti?",
    "answer": "Byloje bus reiškiamas turtinio pobūdžio reikalavimas (dėl turtinės žalos atlyginimo). Šio žyminio mokesčio dydis paaiškės tik proceso teisme metu (pirmoje instancijoje jis gali siekti 3% nuo vartotojo reiškiamo turtinio reikalavimo sumos). Planuojama, kad Vartotojų aljansas gaus finansavimą šioms išlaidoms ir grupės nariai neturės iš anksto mokėti jokių mokesčių. Jeigu Grupės ieškinio byla bus sėkminga teisme, grupės nariai Vartotojų aljansui turės sumokėti Sėkmės mokestį, kuris yra 10 (dešimt) procentų nuo grupės nario individualaus reikalavimo. Atkreipiame dėmesį, kad pralaimėjimo atveju kitos pusės teismo išlaidas turi padengti pralaimėjusi šalis, t.y. išlaidos būtų padalintos visiems grupės nariams proporcingai jų reikalavimo dydžiui. Tačiau mūsų teisininkų vertinimu pralaimėjimo tikimybė labai nedidelė."
  },
  {
    "question": "Kokius asmens duomenis turėsiu nurodyti, ar bus užtikrintas jų saugumas?",
    "answer": "Prisijungę prie šios sistemos turėsite pateikti savo asmens ir kitus duomenis (vardas, pavardė, asmens kodas, gimimo data, telefono numeris, el. pašto adresas, gyvenamosios vietos adresas, banko sąskaitos numeris, kiti duomenys, patvirtinantys faktines aplinkybes dėl patirtos turtinės žalos), kurie yra būtini sudarant Atstovavimo susitarimą ir kitus dokumentus, kurių prireiks grupės ieškinio procese. Jūsų asmens duomenų tvarkymo tvarka nurodyta Privatumo pranešime."
  },
  {
    "question": "Kiek laiko užtruks visas procesas?",
    "answer": "Grupės ieškinys yra pakankamai sudėtingas procesas, kuris vyksta 4 stadijomis:<ol><li>Pasirengimas (renkama grupė, pasirašomi atstovavimo dokumentai);</li><li>Teikiama pretenzija, vedamos derybos;</li><li>Grupės ieškinio nagrinėjimas teisme;</li><li>Rezultatai (žalos atlyginimas, galimi apeliacinis, kasacinis procesai).</li></ol> Proceso trukmę sunku prognozuoti, tačiau jis gali užtrukti ir keletą metų."
  },
  {
    "question": "Kas yra Vartotojų aljansas, ar jis gali teikti grupės ieškinį?",
    "answer": `Vartotojų aljansas – tai 2012 m. įkurta asociacija (ankstesnis pavadinimas Lietuvos vartotojų organizacijų aljansas), jungianti daugiau nei 10 vartotojų teises įvairiose srityse ginančias organizacijas. <br><br>Aljansas dalyvauja rengiant ir tobulinant vartotojams aktualius teisės aktus, bendradarbiauja su kitomis nevyriausybinėmis organizacijomis, valstybinėmis institucijomis, verslo ir akademinėmis bendruomenėmis, atlieka tyrimus, rengia mokymus, aktyviai konsultuoja vartotojus, vykdo informacinės sklaidos projektus. Aljansas yra Lietuvos atstovai Europos vartotojų organizacijoje (angl. The European Consumer Organisation, BEUC), dalyvauja įvairių Europos ir nacionalinio lygmens patariamųjų struktūrų veiklose.<br><br> Organizacijos patirtis ir aktyvus dalyvavimas teisėkūroje prisidėjo prie 2020 m. Civilinio proceso kodekso pakeitimų, palengvinusių vartotojų asociacijų galimybes teikti grupės ieškinius. Vartotojų aljansas yra įtrauktas į <a href="https://www.vvtat.lt/nuorodos/igaliotosios-vartotoju-asociacijos/699"> įgaliotųjų vartotojų asociacijų sąrašą</a>, todėl pagal Vartotojų teisių apsaugos įstatymą grupės ieškinio bylose gali atstovauti ir asmenims, kurie nėra organizacijos nariai (131 str. 1 d. (2). <br><br>Daugiau informacijos apie Aljanso veiklą rasite <a href="https://www.vartotojualjansas.lt">www.vartotojualjansas.lt</a>`
  },
  {
    "question": "Ar prisijungti prie grupės ieškinio gali tik Vartotojų aljanso nariai?",
    "answer": "Ne, jūs neturite būti Vartotojo aljanso nariu. Prie šio grupės ieškinio gali prisijungti kiekvienas fizinis asmuo, kuris nukentėjo dėl vairavimo mokyklų susitarimo kelti įkainius.<br><br> Vartotojų aljansas yra įgaliotoji vartotojų asociacija, tai reiškia, kad grupės ieškinio bylose organizacija gali atstovauti asmenims, kurie nėra organizacijos nariai."
  },
  {
    "question": "Ar galiu papildyti pateiktus dokumentus?",
    "answer": `Prašome iš anksto pasiruošti reikiamus dokumentus ir informaciją pateikti labai tiksliai, nes pakeisti patvirtintų dokumentų negalima. Grupės ieškinio bylose dalyvauja nemažas skaičius nukentėjusių asmenų, tvarkomos informacijos srautas taip pat didelis, o visi norime, kad procesai vyktų sparčiau, todėl prašome vadovautis šiame portale nurodytomis instrukcijomis. Tik tuo atveju, jei visų būtinų duomenų ar dokumentų nepateikėte dėl rimtų priežasčių, susisiekite el. paštu <a href="mailto:vairavimokartelis@vartotojualjansas.lt">vairavimokartelis@vartotojualjansas.lt</a>`
  },
  {
    "question": "Kokią žalą reikėjo patirti, kad galima būtų prisijungti prie grupės ieškinio?",
    "answer": "Turtinė žala galėjo būti patirta permokant už vairavimo kursus. <br><br> Pavyzdžiui, vienoje Vilniuje esančioje vairavimo mokykloje B kategorijos kursai iki 2016-04-22 kainavo 347 eurus, o nuo 2016-07-01 – 499 eurus.  Jei lankėte vairavimo kursus šioje mokykloje po  2016-07-01, mūsų siūloma reikalaujama suma (žala) būtų 152 eurai."
  },
  {
    "question": "Neturiu sutarties su vairavimo mokykla / neturiu mokėjimo patvirtinimo? Ką tokiu atveju daryti?",
    "answer": `Jei neturite sutarties su vairavimo mokykla, rekomenduojame raštu kreiptis į atitinkamą vairavimo mokyklą su prašymu išduoti jūsų sutarties ir mokėjimo dokumentų kopiją arba kitaip patvirtinti, kada ir kokius kursus lankėte. <br> <br>Kreipimosi į vairavimo mokyklą pavyzdys siekiant gauti sutartį ar mokėjimo dokumentą: <br><br>“Laba diena, 20[...] m. Jūsų mokykloje lankiau vairavimo kursus. Prašome atsiųsti mano vairavimo kursų sutarties ir (ar) mokėjimo už vairavimo kursus dokumento kopiją arba raštu nurodyti, kada ir kurios kategorijos kursus lankiau ir kiek už juos sumokėjau.”`
  },


  {
    "question": "Kaip galima prisijungti prie ieškinio?",
    "answer": `Šiuo metu yra rengiamas IT įrankis, kuris leis užpildyti informaciją ir įkelti dokumentus į sistemą siekiant prisijungti prie grupės ieškinio. Kviečiame sekti informaciją <a href="https://www.vairavimokartelis.lt">www.vairavimokartelis.lt</a> bei Vartotojų aljanso sukurtoje Facebook grupėje <a href="https://www.facebook.com/groups/942473780444494">„Vairavimo kartelis“</a>, kur bus pranešta, kai galėsite registruotis sistemoje.`
  }
,  
  {
    "question": "Kada ketinama pateikti ieškinį?",
    "answer": "Tiksli ieškinio pateikimo data nėra žinoma, tačiau orientacinis laikotarpis yra 2023 metų pabaiga - 2024 metų pradžia."
  }

]



// FAQDialog Component
const FAQDialog = ({ open, handleClose }: any) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        id="customized-dialog-title"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>Dažniausiai užduodami klausimai</Box>
        <Box onClick={() => handleClose()}>
          <CloseIcon style={{ cursor: "pointer" }} />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {information.map((item, index) => (
          <Accordion key={index} style={{ marginBottom: "10px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ backgroundColor: "color(srgb 0.6585 0.8171 0.8663)" }}
            >
              <h4
                style={{ lineHeight: "1.6" }}
                dangerouslySetInnerHTML={{ __html: item.question }}
              ></h4>
            </AccordionSummary>
            <AccordionDetails style={{ lineHeight: "1.6" }}>
              <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default FAQDialog;
